import type { FormLabelProps as MuiFormLabelProps } from "@material-ui/core";
import {
  FormLabel as MuiFormLabel,
  Typography,
  withStyles,
} from "@material-ui/core";

import { SizeToFormLabelVariant } from "../MuiTypography";

const FormLabelStyled = withStyles((theme) => ({
  root: {
    color: theme.palette.memoraGrey[100],
    "&$error": {
      color: theme.palette.red[55],
    },
    "&$focused": {
      color: theme.palette.memoraGrey[100],
    },

    "& :first-child": {
      display: "inline-block",
    },
  },
  error: {},
  focused: {},
}))(MuiFormLabel);

export type FormLabelProps = MuiFormLabelProps & {
  maxWidth?: number | string;
  size?: "medium" | "large";
};

export const FormLabel = ({
  children,
  maxWidth,
  size = "medium",
  ...muiFormLabelProps
}: FormLabelProps) => (
  <FormLabelStyled {...muiFormLabelProps} style={{ maxWidth }}>
    <Typography variant={SizeToFormLabelVariant[size]}>{children}</Typography>
  </FormLabelStyled>
);
FormLabel.displayName = "FormLabel";
