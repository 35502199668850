import { useEffect } from "react";

import { flushClientLogs } from "../logging/logger";

const checkVisibility = () => {
  if (document.visibilityState === "hidden") {
    flushClientLogs();
  }
};

export const useFlushClientLogs = () => {
  useEffect(() => {
    document.addEventListener("visibilitychange", checkVisibility);
    return () => {
      document.removeEventListener("visibilitychange", checkVisibility);
    };
  }, []);
};
