import { useEffect } from "react";

import { useToast } from "../infra/toasts";

export const useHandleWindowEvent = () => {
  const toast = useToast();

  useEffect(() => {
    const handleErrorRejection = (event: PromiseRejectionEvent) => {
      const error = event.reason;

      // Globally handling any unhandled axios exceptions thrown
      if (error.isAxiosError) {
        toast.error(
          error.response?.data?.message ||
            error.message ||
            "Error fulfilling request"
        );
      }
    };

    window.addEventListener("unhandledrejection", handleErrorRejection);

    return () => {
      window.removeEventListener("unhandledrejection", handleErrorRejection);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
