import { useEffect, useRef } from "react";

/**
 * Allows access of the latest value in a ref
 * without having to cause rerenders
 */
export function useLatest<T>(value: T) {
  const valueRef = useRef<T>(value);
  useEffect(() => {
    valueRef.current = value;
  }, [value]);
  return valueRef;
}
