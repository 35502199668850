import { makeStyles, MenuItem, MenuList } from "@material-ui/core";
import type { DropdownOption } from "@pillpal/api-types";
import classNames from "classnames";

import { Body } from "../Body";
import { useAutoCompleteStyles } from "./sharedStyles";

interface NewOptionItemProps {
  value: string;
  addNewOption: () => void;
  getOptionProps: ({
    option,
    index,
  }: {
    option: DropdownOption<string>;
    index: number;
  }) => {};
  listboxProps: {};
}
export const NewOptionItem = ({
  value,
  addNewOption,
  getOptionProps,
  listboxProps,
}: NewOptionItemProps) => {
  const sharedStyles = useAutoCompleteStyles();
  const styles = useStyles();

  if (!value) {
    return null;
  }

  const optionProps = getOptionProps({
    option: { label: value, value },
    index: 0, // Since we can only have a single new options created at a time, we can set index as 0 as it will always be the first option
  });

  return (
    <MenuList {...listboxProps}>
      <MenuItem
        {...optionProps}
        className={classNames(sharedStyles.menuItem, styles.wrapper)}
        onClick={addNewOption}
      >
        <Body size="large" className={sharedStyles.title}>
          {`Create New Option "${value}"`}
        </Body>
      </MenuItem>
    </MenuList>
  );
};

const useStyles = makeStyles(() => ({
  wrapper: {
    margin: "8px 0",
  },
}));
