import type { FormControlProps as MuiFormControlProps } from "@material-ui/core";
import { FormControl as MuiFormControl } from "@material-ui/core";
import classNames from "classnames";

import type { FormHelperTextProps } from "./FormHelperText";
import { FormHelperText } from "./FormHelperText";
import type { FormLabelProps } from "./FormLabel";
import { FormLabel } from "./FormLabel";

type FormFieldProps = MuiFormControlProps & {
  // Need React.ReactElement type to support strings with hyperlinks
  helperText?: string | React.ReactElement;

  // overrides helper text if present
  errorText?: string | React.ReactElement;

  helperTextSize?: FormHelperTextProps["size"];
  label?: string;
  labelMaxWidth?: number | string;
  labelSize?: FormLabelProps["size"];
  success?: boolean;
  labelJSX?: React.ReactNode;
  hasLayout?: boolean;
};

export const FormField = ({
  children,
  helperText,
  helperTextSize = "medium",
  errorText,
  label,
  labelMaxWidth,
  labelSize = "medium",
  success,
  labelJSX,
  hasLayout,
  className,
  ...muiFormControlProps
}: FormFieldProps) => {
  const { error } = muiFormControlProps;
  const isError = !!errorText || error;

  const helperTextToShow = errorText || helperText;

  return (
    <MuiFormControl
      {...muiFormControlProps}
      error={isError}
      className={classNames(className, {
        "formLayout-item": hasLayout,
      })}
    >
      {label && (
        <FormLabel
          className={classNames({
            "formLayout-label": hasLayout,
          })}
          maxWidth={labelMaxWidth}
          size={labelSize}
        >
          {label}
        </FormLabel>
      )}
      {labelJSX && (
        <div
          className={classNames({
            "formLayout-label": hasLayout,
          })}
        >
          {labelJSX}
        </div>
      )}
      {children}
      {helperTextToShow && (
        <FormHelperText error={isError} success={success} size={helperTextSize}>
          {helperTextToShow}
        </FormHelperText>
      )}
    </MuiFormControl>
  );
};
FormField.displayName = "FormField";
