import { debounce } from "lodash";
import type { Dispatch, SetStateAction } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

export const DEFAULT_DEBOUNCE_TIMEOUT = 300; // Unit in ms
/**
 * Allows for quick local changes, pushing updates externally in a throttled fashion
 * @param state result from useState
 * @param throttledMs how often you want the latest value
 * @returns the "throttled" value
 */
export function useDebounceSet<T>(
  useStateInstance: [T, Dispatch<SetStateAction<T>>],
  throttledMs: number
): [T, (nextValue: T) => void] {
  const [externalState, setExternalState] = useStateInstance;
  const [internalState, setInternalState] = useState<T>(externalState);
  const isDebouncing = useRef(false);

  // debounced version of the external setter
  const setExternalStateDebounced = useMemo(
    () =>
      debounce((nextValue: T) => {
        setExternalState(nextValue);
        isDebouncing.current = false;
      }, throttledMs),
    [setExternalState, throttledMs]
  );

  // keep internal up to date with external
  useEffect(() => {
    // only accept external state changes that happen when we're
    // not debouncing. Otherwise, we can get the external state
    // updating internal state to "old" values that are not yet
    // flushed through the debounce yet
    if (!isDebouncing.current) {
      setInternalState(externalState);
    }
  }, [externalState]);

  // setter that sets both external and internal
  const setState = useCallback(
    (nextValue: T) => {
      isDebouncing.current = true;
      setExternalStateDebounced(nextValue);
      setInternalState(nextValue);
    },
    [setExternalStateDebounced, setInternalState]
  );

  return [internalState, setState];
}

/**
 * Debounces a value to delay its updates, returning the debounced value.
 * Ref - // https://usehooks.com/useDebounce/
 *
 * @param value - The value to debounce.
 * @param delay - Optional. The delay in milliseconds before the debounced value updates. Defaults to a predefined constant (DEFAULT_DEBOUNCE_TIMEOUT) if not specified.
 * @param initial - Optional. The initial value for the debounced value. If not provided, it initially matches the input value.
 * @returns The debounced value that updates after the specified delay.
 */

export function useDebounceValue<T>(value: T, delay?: number, initial?: T) {
  const debounceDelay = delay ?? DEFAULT_DEBOUNCE_TIMEOUT;
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(
    initial !== undefined ? initial : value
  );
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, debounceDelay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, debounceDelay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}
