import "proxy-polyfill"; // LD uses Proxy which isn't supported in IE11

import type { LDMultiKindContext } from "launchdarkly-react-client-sdk";
import {
  asyncWithLDProvider,
  useFlags as useFlagsCore,
  useLDClient,
} from "launchdarkly-react-client-sdk";
import { useMemo } from "react";

import { getQueryFeatureFlags } from "../helpers/featureFlagsHelpers";
import { clientLogger } from "../logging/logger";
import { userObservable } from "../observables/userObservable";
import { LD_CLIENT_ID } from "./config";

// This and related code will be deprecated shortly.
// needed to imperatively fetch flags, doesn't support
// live updates
// eslint-disable-next-line @typescript-eslint/naming-convention
let UNSAFE_ldClient: ReturnType<typeof useLDClient>;

export async function getFeatureFlagsProvider() {
  const Provider = await asyncWithLDProvider({
    clientSideID: LD_CLIENT_ID,
    context: { kind: "user", key: "user", anonymous: true },
  });

  // Janky workaround for now. We could instantiate an ldClient and pass it into
  // asyncWithLDProvider, but asyncWithLDProvider gives a lot of defaults that
  // we want
  const StealClientComponent: React.FC = () => {
    const ldClient = useLDClient();
    UNSAFE_ldClient = ldClient;
    return null;
  };

  const WrappedProvider: React.FC = ({ children }) => (
    <Provider>
      <StealClientComponent />
      {children}
    </Provider>
  );

  return WrappedProvider;
}

/**
 * Hook which returns the given flag value
 * Note this will cause re-renders when any flag changes for the current environment
 * @param name camel cased version of the flag name from Launch Darkly
 * @param defaultValue default value to return if launch darkly can't be reached. Typically false or undefined
 * @returns
 */
export function useFlag(name: string, defaultValue?: boolean): boolean {
  const flags = useFlagsCore();
  const override = useFlagQueryOverride(name);
  if (override !== undefined) {
    return override;
  }
  if (flags[name] !== undefined) {
    return flags[name];
  }
  return !!defaultValue;
}

export function useNumericFlag(name: string, defaultValue: number): number {
  const flags = useFlagsCore();
  if (flags[name] !== undefined) {
    return flags[name];
  }
  return defaultValue;
}

/**
 * Gets the query parameter override
 * for a given feature flag
 */
function useFlagQueryOverride(name: string) {
  return useMemo(() => {
    const queryFlags = getQueryFeatureFlags();
    return queryFlags.get(name);
  }, [name]);
}

/**
 * Backwards compatible imperative flag API which doesn't support live updates.
 * Don't use this please. Is used temporarily to prevent undue effort in the
 * initial implementation. Hooks should always be used to fetch flags.
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
export function UNSAFE_getFlag(name: string) {
  if (!UNSAFE_ldClient) {
    throw new Error("Feature Flag Provider not intialized");
  }
  const override = getQueryFeatureFlags().get(name);
  if (override !== undefined) {
    return override;
  }
  return UNSAFE_ldClient.allFlags()[name];
}

userObservable.subscribe(
  async ({ userId, userEmail, userIsAdmin, providerId, providerName }) => {
    if (UNSAFE_ldClient) {
      const context: LDMultiKindContext = {
        kind: "multi",
        user: {
          key: "user",
          anonymous: true,
        },
      };
      if (userId) {
        context.user = {
          key: userId,
          name: userEmail,
          isAdmin: userIsAdmin || false,
        };
      }
      if (providerId) {
        context.provider = {
          key: providerId,
          name: providerName,
        };
      }
      try {
        await UNSAFE_ldClient.identify(context);
        clientLogger.info("updated launch darkly context", context);
      } catch (error) {
        clientLogger.info("Error pushing context data to launch darkly", {
          err: error,
        });
      }
    }
  }
);
