import { validate as uuidValidate } from "uuid";

/** Return path all lowercase, without trailing slash, with IDs replaced by `:id`. */
export const normalizedPathSelector = (pathName: string) =>
  normalizePath(pathName);
const objectIdRegex = /^[\da-f]{24}$/i;

/**
 * Given a path, return an array of path segments without trailing slash, IDs replaced, and all lowercase.
 *
 * @example
 * ```
 * // returns 'dashboard/patient/:id'
 * redactPath('/DASHBOARD/PATIENT/60218e48d8f3d850bd23b5aa')
 * ```
 */
const normalizePath = (path: string): string =>
  `/${path
    .split("/")
    .reduce((accum, cur) => {
      if (cur) {
        if (objectIdRegex.test(cur) || uuidValidate(cur)) {
          accum.push(":id");
        } else {
          accum.push(cur.toLowerCase());
        }
      }
      return accum;
    }, [] as string[])
    .join("/")}`;
