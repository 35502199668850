import type { DropdownOptions } from "@pillpal/api-types";

export type AutoCompleteFieldValue =
  | undefined
  | string
  | string[]
  | DropdownOptions<string>;

/**
 * Parses options to an array of strings for submitting the value to form.
 */
export const autoCompleteParser =
  (isMultiSelect: boolean) => (values?: DropdownOptions<string>) => {
    if (isMultiSelect) {
      const parsedValues = values?.map(({ value }) => value) || [];

      return parsedValues;
    }

    return values?.[0]?.value || undefined;
  };

/**
 * Formats the initial values in form of string or array of strings to valid options.
 */
export const autoCompleteFormatter = (
  value: AutoCompleteFieldValue,
  options: DropdownOptions<string>
): DropdownOptions<string> => {
  if (typeof value === "string") {
    const selectedOption = options?.filter((option) => option.value === value);

    // Return option from the list if it exists.
    if (selectedOption?.length > 0) {
      return selectedOption;
    }

    // Returns the value as a new option if it doesn't exist in the list.
    if (value) {
      return [{ label: value, value }];
    }

    return [];
  }

  if (Array.isArray(value) && value.length && typeof value[0] === "string") {
    return (value as string[]).map((val) => {
      const selectedOption = options.find((option) => option.value === val);

      // We need to support Creatable feature in AutoComplete.
      return selectedOption || { label: val, value: val };
    });
  }

  return (value as DropdownOptions<string>) || [];
};
