import type { ButtonProps } from "components/system/Button";
import { Button } from "components/system/Button";
import type { ICON_DEF } from "components/system/tokens/icons";
import { forwardRef } from "react";

export type IconSizes = keyof typeof ICON_DEF;

export type IconButtonProps = ButtonProps;

export const IconButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant = "tertiary", // teritiary is default for IconButton
      ...rest
    },
    ref
  ) => (
    <Button
      ref={ref}
      className={`iconButton ${className}`}
      variant={variant}
      {...rest}
    />
  )
);
IconButton.displayName = "IconButton";
