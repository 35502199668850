import { useEffect } from "react";
import { create } from "zustand";

type DebugState = {
  showOutline: boolean;
  setShowOutline(val: boolean): void;
};

export const useDebugState = create<DebugState>()((set) => ({
  showOutline: false,
  setShowOutline(val) {
    set({
      showOutline: val,
    });
  },
}));

export const useCaptureDebugState = () => {
  const { search } = window.location;

  const setShowOutline = useDebugState((s) => s.setShowOutline);

  useEffect(() => {
    try {
      const urlValue = JSON.parse(
        new URLSearchParams(search).get("debug.showNewThemeOutline") || "false"
      );

      if (urlValue && typeof urlValue === "boolean") {
        setShowOutline(urlValue);
      }
      // eslint-disable-next-line no-empty
    } catch {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
