import { useCallback, useEffect, useRef, useState } from "react";

/**
 * Hook that returns whether the user session is currently active. Checks for tab being active and
 * if mouse/keyboard/touch activity has happened recently.
 */
export function useActiveStatus(props: { activityMs: number }) {
  /** Ref to the current activity timeout handler; changes do not cause re-render. */
  const timeoutHandleRef = useRef<number>();

  // changes to activity get reported to props.onChange - we hold a ref to it
  // so that we don't rebuild any of our callbacks if the prop changes
  const [isActive, setIsActive] = useState(true);

  /** Cached timeout callback, since this is always constant. */
  const timeoutCb = useCallback(() => {
    setIsActive(false);
  }, []);

  // the function really doing the work
  // attaches window callbacks dependent on activityMs which can change, and timeoutCb which is cached
  // on change of activityMs or unmount of the component, remove all listeners and clear the timeout handler
  useEffect(() => {
    const visListener = () => {
      setIsActive(
        document.visibilityState === "visible" && document.hasFocus()
      );
    };

    const movementListener = () => {
      setIsActive(true);
      window.clearTimeout(timeoutHandleRef.current);
      timeoutHandleRef.current = window.setTimeout(timeoutCb, props.activityMs);
    };

    document.addEventListener("visibilitychange", visListener);
    window.addEventListener("blur", visListener);
    window.addEventListener("focus", visListener);

    document.addEventListener("keydown", movementListener);
    document.addEventListener("mousemove", movementListener);
    document.addEventListener("touchstart", movementListener);

    return () => {
      document.removeEventListener("visibilitychange", visListener);
      window.removeEventListener("blur", visListener);
      window.removeEventListener("focus", visListener);

      document.removeEventListener("keydown", movementListener);
      document.removeEventListener("mousemove", movementListener);
      document.removeEventListener("touchstart", movementListener);
      window.clearTimeout(timeoutHandleRef.current);
      timeoutHandleRef.current = -1;
    };
  }, [props.activityMs, timeoutCb]);

  return isActive;
}
