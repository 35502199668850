import type { useDisclosure } from "hooks/useDisclosure";
import type { ActivityInfo } from "infra/activityTracking/state/useActivity";
import { useCallback, useContext } from "react";
import * as React from "react";

import { useIsNewTheme, withNewTheme } from "../theme";

type Disclosure = ReturnType<typeof useDisclosure>;

type DialogHostContext<TCloseParams> = Disclosure & {
  /**
   * Pass afterClose function to be called after the dialog is closed.
   * Consumers must do this vs calling onClose then any after functions, as
   * onClose will typically destroy them
   */
  onCloseWithCleanup: (afterClose?: () => void) => void;
  onClose: () => void;
  onProvideCloseData: (data: TCloseParams) => void;
  publishActivity: (info: ActivityInfo) => void;
};

type AllowedDialogExtension = Record<string, any> & {
  [K in keyof DialogHostContext<any>]?: never;
};

/**
 * Props for dialog components to consume
 */
export type DialogProps<
  T extends AllowedDialogExtension,
  TCloseParams,
> = DialogHostContext<TCloseParams> & T;

/**
 * Interface for dialogs to implement. Extendable for custom params while
 * including a set of defaults
 */
export type DialogDefinition<
  TLaunchParams extends AllowedDialogExtension,
  TCloseParams = void,
> = React.FC<DialogProps<TLaunchParams, TCloseParams>>;

export type UseDialogOpts = {
  useNewTheme?: boolean;
};

export type LaunchDialogAction<
  TLaunchProps extends AllowedDialogExtension,
  TCloseParams,
> = (
  dialog: DialogDefinition<TLaunchProps, TCloseParams>,
  launchProps: TLaunchProps,
  opts?: UseDialogOpts
) => Promise<TCloseParams>;

export type DialogConsumerContextData<
  TLaunchProps extends AllowedDialogExtension,
  TCloseParams,
> = {
  launchDialog: LaunchDialogAction<TLaunchProps, TCloseParams>;
  isOpen: boolean;
};

export const DialogConsumerContext = React.createContext<
  DialogConsumerContextData<any, any>
>({
  launchDialog: () => {
    throw new Error("No dialog host found");
  },
  isOpen: false,
});

/**
 * Returns context for launching a given dialog type
 */
export function useDialog<T extends DialogDefinition<any, any>>(
  dialogDefinition: T,
  opts?: UseDialogOpts
) {
  type LaunchProps = T extends DialogDefinition<infer R, any> ? R : never;
  type CloseParams =
    T extends DialogDefinition<LaunchProps, infer R> ? R : never;

  const { launchDialog, isOpen } = useContext<
    DialogConsumerContextData<LaunchProps, CloseParams>
  >(DialogConsumerContext);
  const isNewTheme = useIsNewTheme();
  const processedDialogDefinition = isNewTheme
    ? withNewTheme(dialogDefinition, { noBackground: true })
    : dialogDefinition;

  const launch = useCallback(
    (props: LaunchProps) =>
      launchDialog(processedDialogDefinition, props, opts),
    [launchDialog, processedDialogDefinition, opts]
  );

  return {
    launch,
    isOpen,
  };
}

export function useIsDialogOpen() {
  const { isOpen } = useContext(DialogConsumerContext);
  return isOpen;
}
