import type { IUserState } from "@pillpal/api-types";
import { QueriesObserver } from "@tanstack/react-query";
import { useEffect } from "react";

import type { ProviderDetailType } from "../containers/auth/helpers";
import { AuthorizationQueryKey } from "../containers/auth/hooks/useAuthorizedUser";
import { CurrentProviderDetailsQueryKey } from "../containers/auth/hooks/useLoadPanelData";
import { Observable } from "../helpers/observable";
import { useOptionalCurrentUser } from "../hooks/useCurrentUser";
import { queryClient } from "../queryClient";

type UserArgs = {
  userId: string;
  userEmail: string;
  userIsAdmin: boolean;
  providerId: string;
  providerName: string;
};

export const userObservable = new Observable<UserArgs>();

const patchQueryClientToUserObservable = () => {
  const observer = new QueriesObserver(queryClient, [
    { queryKey: [AuthorizationQueryKey], enabled: false },
    { queryKey: [CurrentProviderDetailsQueryKey], enabled: false },
  ]);
  observer.subscribe((queries) => {
    const [authQuery, panelQuery] = queries;
    if (authQuery?.status === "success" && panelQuery?.status === "success") {
      const authData = authQuery?.data as IUserState | undefined;
      const panelData = panelQuery?.data as ProviderDetailType | undefined;
      if (authData && panelData?.provider) {
        userObservable.notify({
          userId: authData.id,
          userEmail: authData.email,
          userIsAdmin: authData.isAdmin,
          providerId: panelData.provider._id,
          providerName: panelData.provider.name,
        });
      }
    }
  });
};
patchQueryClientToUserObservable();

export function useUpdateUserProviderContext({
  providerId,
  providerName,
}: {
  providerId: string | undefined;
  providerName: string | undefined;
}) {
  const user = useOptionalCurrentUser();

  useEffect(() => {
    if (providerId && providerName) {
      userObservable.notify({
        userId: user?.id || "",
        userEmail: user?.email || "",
        userIsAdmin: !!user?.isAdmin,
        providerId,
        providerName,
      });
    }
  }, [user, providerId, providerName]);
}
