import { Box, ClickAwayListener, Divider, Popper } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { useAutocomplete } from "@material-ui/lab";
import type { DropdownOption, DropdownOptions } from "@pillpal/api-types";
import classNames from "classnames";
import { useDisclosure } from "hooks/useDisclosure";
import type {
  ChangeEvent,
  ChangeEventHandler,
  KeyboardEventHandler,
  RefObject,
  UIEventHandler,
} from "react";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";

import { Button } from "../Button";
import { Chip } from "../Chip";
import { TextInputSingleLine } from "../textInput/TextInputSingleLine";
import { NewOptionItem } from "./NewOptionItem";
import { useAutoCompleteStyles } from "./sharedStyles";
import { VirtualizedMenu } from "./VirtualizedMenu";

const DEFAULT_WIDTH = "200px";

export interface AutoCompleteProps {
  value: DropdownOptions<string>;
  onChange: (values: DropdownOptions<string>) => void;
  options: DropdownOptions<string>;
  isMulti?: boolean;
  isLoading?: boolean;
  placeHolder?: string;
  disabled?: boolean;
  searchInput?: string;
  error?: boolean;
  fetchMore?: () => void;
  isSubtitleDate?: boolean;
  onSearchChange?: (searchInput: string) => void;
  preventDefaultAutocomplete?: boolean;
  fullWidth?: boolean;
  minWidth?: number;
  groupBy?: (option: DropdownOption<string>) => string;
  id?: string;
  allowNewOptions?: boolean;
}
export const AutoComplete = ({
  value,
  onChange,
  options,
  searchInput,
  error = false,
  disabled = false,
  placeHolder = "Enter text",
  onSearchChange = () => {},
  fetchMore = () => {},
  isMulti = false,
  isSubtitleDate = false,
  isLoading = false,
  preventDefaultAutocomplete = false,
  fullWidth = false,
  minWidth,
  groupBy,
  id,
  allowNewOptions = false,
}: AutoCompleteProps) => {
  const styles = useAutoCompleteStyles();
  const anchorEl = useRef<HTMLDivElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [newOption, setNewOption] = useState<string>("");

  // Override the auto-complete hook to enable server side search functionality.
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    inputValue: defaultSearchInput,
  } = useAutocomplete({
    id: id ?? "auto-complete",
    multiple: isMulti,
    open: isOpen,
    inputValue: searchInput,
    options,
    getOptionLabel: (option) => option.label,
    groupBy,
  });

  const optionIsSelected = useCallback(
    (option: DropdownOption<string>) =>
      !!value.find((item) => item.value === option.value),
    [value]
  );

  const inputProps = getInputProps() as {
    ref: RefObject<HTMLInputElement>;
    onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  };

  const hasSelectedOptions = useMemo(() => value.length > 0, [value.length]);

  /**
   * Reset the search input on close.
   */
  const handleClose = useCallback(() => {
    if (searchInput !== "") {
      onSearchChange("");
    }
    onClose();
  }, [onClose, onSearchChange, searchInput]);

  // Reset the search input
  const resetSearchInput = useCallback(() => {
    inputProps.onChange({
      target: { value: "" },
    } as ChangeEvent<HTMLInputElement>);
    onSearchChange("");
  }, [inputProps, onSearchChange]);

  /**
   * Update selections logic based on multiple or single selection
   */
  const updateSelection = useCallback(
    (option: DropdownOption<string>) => {
      // Reset the search input on selection
      resetSearchInput();

      if (optionIsSelected(option)) {
        const filteredOptions = value.filter(
          (item) => item.value !== option.value
        );
        onChange([...filteredOptions]);
      } else if (isMulti) {
        onChange([...value, option]);
      } else {
        onChange([option]);
      }

      if (!isMulti) {
        handleClose();
      }
    },
    [optionIsSelected, handleClose, isMulti, onChange, resetSearchInput, value]
  );

  /**
   * Resets the selected value/s.
   */
  const resetSelection = useCallback(() => {
    onChange([]);
  }, [onChange]);

  /**
   * Adds new options to selected value/s.
   */
  const addNewOption = useCallback(() => {
    const trimmedValue = newOption.trim();

    updateSelection({ label: trimmedValue, value: trimmedValue });
  }, [newOption, updateSelection]);

  /**
   * Control search input when we need server side search functionality.
   */
  const handleSearchInputChange: ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = useCallback(
    (e) => {
      if (!isOpen) {
        onOpen();
      }

      inputProps.onChange(e);
      onSearchChange(e.target.value);
    },
    [inputProps, isOpen, onOpen, onSearchChange]
  );

  /**
   * Handles lazy loading if fetchMore has been provided.
   */
  const handleScroll: UIEventHandler<HTMLUListElement> = useCallback(
    (event) => {
      // Prevent fetching if there is no fetchMore callback or if it is already fetching.
      if (!fetchMore || isLoading) {
        return;
      }

      const {
        currentTarget: { scrollHeight, scrollTop, clientHeight },
      } = event;

      /**
       * Offset Value:
       * Determines when to fetch more data as the user approaches the bottom of the scrollable area.
       * A higher value triggers fetching data sooner, while a lower value waits until the user is closer to the bottom.
       * Minimum value of 5 is recommended to avoid data not fetched bug on zoomed in screens.
       */
      const offset = 5;
      if (scrollTop + clientHeight >= scrollHeight - offset) {
        fetchMore();
      }
    },
    [fetchMore, isLoading]
  );

  /**
   * When server side search is enabled we do not rely on the internal filters and instead just pass in the options passed from the parent.
   */
  const filteredOptions = preventDefaultAutocomplete ? options : groupedOptions;

  /**
   * Handles the logic for adding new options when enabled.
   */
  useEffect(() => {
    if (!allowNewOptions) {
      return;
    }

    if (
      filteredOptions.find(
        ({ value: optionValue }) =>
          optionValue === searchInput || optionValue === defaultSearchInput
      )
    ) {
      setNewOption("");
    } else {
      setNewOption(searchInput || defaultSearchInput || "");
    }
  }, [allowNewOptions, defaultSearchInput, filteredOptions, searchInput]);

  /**
   * Handles the logic for adding new options when user presses entered.
   */
  const handleSearchInputKeyDown: KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = useCallback(
    (e) => {
      if (e.key !== "Enter" || !allowNewOptions || newOption === "") {
        return;
      }

      addNewOption();
    },
    [addNewOption, allowNewOptions, newOption]
  );

  const hideEmptyOptions =
    allowNewOptions && !!newOption && filteredOptions.length === 0;

  return (
    <>
      <div
        ref={anchorEl}
        className={classNames({ [styles.fullWidth]: fullWidth })}
      >
        <Box
          {...getRootProps()}
          className={classNames(styles.inputWrapper, {
            [styles.inputWrapperFocused]: isOpen,
            [styles.error]: !!error,
            [styles.disabled]: disabled,
          })}
          display="flex"
          flexDirection="column"
          sx={{ minWidth }}
        >
          <Box
            className={classNames(styles.autoCompleteWrapper, {
              [styles.multiSelectWrapper]: isMulti,
            })}
          >
            <Box
              className={classNames({
                [styles.singleSelectWrapper]: !isMulti,
                [styles.tagsWrapper]: isMulti,
              })}
              // Prevent menu from opening in single select option when we press on clear button.
              onClick={!isMulti ? () => {} : onOpen}
            >
              {hasSelectedOptions && (
                <>
                  {/* Renders single selected option when the auto complete is single select */}
                  {!isMulti &&
                    value.map((item) => (
                      <Fragment key={`${item.value}-${uuidv4()}`}>
                        <Button
                          disabled={disabled}
                          onFocusVisible={() => {}}
                          variant="tertiary"
                          onClick={onOpen}
                          className={styles.singleOptionButton}
                        >
                          {item.label}
                        </Button>

                        <Button
                          disabled={disabled}
                          onFocusVisible={() => {}}
                          variant="tertiary"
                          onClick={() => updateSelection(item)}
                          className={styles.singleOptionDeleteButton}
                        >
                          <CloseOutlined />
                        </Button>
                      </Fragment>
                    ))}
                  {/* Renders tags for multiple selections */}
                  {isMulti &&
                    value.map((item) => (
                      <Chip
                        className={styles.chip}
                        key={`${item.value}-${uuidv4()}`}
                        label={item.label}
                        disabled={disabled}
                        onDelete={() => updateSelection(item)}
                      />
                    ))}
                </>
              )}
              {/* Custom input which is hidden for single selection mode if any option is selected */}
              <TextInputSingleLine
                {...inputProps}
                onClick={onOpen}
                disabled={disabled}
                placeholder={placeHolder}
                fullWidth
                size="large"
                className={classNames(styles.input, {
                  [styles.hidden]: hasSelectedOptions && !isMulti,
                })}
                onChange={handleSearchInputChange}
                onKeyDown={handleSearchInputKeyDown}
                required={false}
                inputProps={{ "data-testid": "auto-complete-input" }}
              />
            </Box>
            {/* As useAutoComplete hook relies on base input element so we need this input */}
            <input {...inputProps} hidden />
            {isMulti && hasSelectedOptions && (
              <Button
                disabled={disabled}
                onFocusVisible={() => {}}
                variant="tertiary"
                size="small"
                onClick={resetSelection}
                className={styles.singleOptionDeleteButton}
              >
                <CloseOutlined />
              </Button>
            )}
          </Box>
        </Box>
      </div>
      {/* Menu list for autoComplete */}
      {/* We cannot use PopOver as it takes over the focus and does not allow typing or search functionality */}
      {isOpen && (
        <ClickAwayListener onClickAway={handleClose}>
          <Popper
            open
            anchorEl={anchorEl?.current}
            placement="bottom-start"
            className={styles.menuList}
            style={{
              minWidth: anchorEl?.current?.clientWidth || DEFAULT_WIDTH,
              maxWidth: anchorEl?.current?.clientWidth || DEFAULT_WIDTH,
              zIndex: 100005,
            }}
          >
            <NewOptionItem
              value={newOption}
              addNewOption={addNewOption}
              listboxProps={getListboxProps()}
              getOptionProps={getOptionProps}
            />
            {!hideEmptyOptions && <Divider />}
            {/* TODO: IT-1755 */}
            <VirtualizedMenu
              listboxProps={getListboxProps()}
              getOptionProps={getOptionProps}
              options={filteredOptions}
              optionClicked={updateSelection}
              selectedOptions={value}
              isLoading={isLoading}
              isMulti={isMulti}
              isSubtitleDate={isSubtitleDate}
              isGrouped={!!groupBy}
              handleScroll={handleScroll}
              hideEmptyOptions={hideEmptyOptions}
            />
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};
