import type { ErrorResponseBody } from "@pillpal/api-types";
import { useQuery } from "@tanstack/react-query";
import { authenticationAPI as currentProviderDetailsAPI } from "apis/auth";
import type { AxiosError } from "axios";
import { useToast } from "infra/toasts";

import type { ProviderDetailType } from "../helpers";
import processAuthObject from "../helpers";

type ProviderDetailsType = {
  data: ProviderDetailType;
  isLoading: boolean;
  refetch: () => void;
  isRefetching: boolean;
  isSuccess: boolean;
  isError: boolean;
  isRefetchError: boolean;
  isLoadingError: boolean;
};

export type WithPanelDataType = {
  panelData: ProviderDetailsType;
};

export const CurrentProviderDetailsQueryKey = "currentProviderDetails";

// API to fetch provider details and frontend functions
export const useLoadPanelData = (enabled = true) => {
  const toast = useToast();
  return useQuery({
    queryKey: [CurrentProviderDetailsQueryKey],
    queryFn: async () => {
      const providerDetails = await currentProviderDetailsAPI();
      return processAuthObject(providerDetails);
    },
    onError: (error: AxiosError<ErrorResponseBody>) => {
      toast.error(error.message || "Failed to retrieve provider details");
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    staleTime: Number.POSITIVE_INFINITY,
    enabled,
  });
};
