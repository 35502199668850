import type { ComponentPropsWithRef, ComponentType } from "react";
import { lazy, memo, Suspense } from "react";

import { makeHOCDisplayName } from "./hoc";
import { TopLoader } from "./TopLoader";

type ModuleLoader<T> = () => Promise<{ default: T }>;

export default function asyncComponent<T extends ComponentType<any>>(
  factory: ModuleLoader<T>
) {
  const LazyComponent = lazyWithRetry(factory);

  // using React.memo here as a response to an issue in CTT-370, which caused IT-804. Came down to
  // the auth hook causing rerender of whatever is wrapped in the auth hook. This happens because
  // Config in Admin does a "fake login" request. At some point we should clean that up so it doesn't
  // cause oddities, but this works for now
  const LazyComponentWithSuspense = memo((props: ComponentPropsWithRef<T>) => (
    <Suspense fallback={<TopLoader data-testid="fallback" delayMs={1000} />}>
      <LazyComponent {...props} />
    </Suspense>
  ));
  LazyComponentWithSuspense.displayName = makeHOCDisplayName(
    "asyncComponent",
    LazyComponent
  );
  return LazyComponentWithSuspense;
}

const REFRESH_KEY = "has-refreshed";
const getHasRefreshed = () =>
  window.localStorage.getItem(REFRESH_KEY) === "true";
const setHasRefreshed = (val: boolean) => {
  window.localStorage.setItem(REFRESH_KEY, val ? "true" : "false");
};

/**
 * Wrapper around React.lazy to lazy load components, but catches an error the first
 * time and reloads to handle ChunkLoadError cases. Source:
 * https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
 */
function lazyWithRetry<T extends ComponentType<any>>(factory: ModuleLoader<T>) {
  return lazy(async () => {
    try {
      const component = await factory();
      setHasRefreshed(false); // we've successfully loaded something
      return component;
    } catch (error) {
      if (!getHasRefreshed()) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        setHasRefreshed(true);
        window.location.reload();
        return {
          default: EmptyComponent as unknown as T,
        };
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });
}

function EmptyComponent() {
  return null;
}
