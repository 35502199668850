import type { DropdownOptions } from "@pillpal/api-types";
import { autoCompleteFormatter } from "components/FormFactoryV2/fields/AutoComplete/helpers";
import * as React from "react";
import type { FieldRenderProps } from "react-final-form";

import { AutoComplete } from "./AutoComplete";
import { Checkbox } from "./Checkbox";
import { MultiSelect, SingleSelect } from "./selects";
import { Switch } from "./Switch";
import { TextInputMultiLine } from "./textInput/TextInputMultiLine";
import { TextInputSingleLine } from "./textInput/TextInputSingleLine";

export function TextInputMultiLineWrapper<TFieldValue>({
  input: { name, onChange, value, onBlur, ...restInput },
  meta,
  ...rest
}: React.PropsWithChildren<FieldRenderProps<TFieldValue>>) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;
  return (
    <TextInputMultiLine
      {...rest}
      name={name}
      error={showError}
      inputProps={restInput}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
    />
  );
}

export function TextInputSingleLineWrapper<TFieldValue>({
  input: { name, onChange, value, onBlur, ...restInput },
  meta,
  readonly,
  maxLength,
  ...rest
}: React.PropsWithChildren<FieldRenderProps<TFieldValue>>) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <TextInputSingleLine
      {...rest}
      name={name}
      error={showError}
      inputProps={{ ...restInput, maxLength, "aria-label": name }}
      onChange={onChange}
      readOnly={readonly}
      onBlur={onBlur}
      value={value}
    />
  );
}

export function SingleSelectWrapper<TFieldValue>({
  input: { name, onChange, value, onBlur },
  meta,
  options,
  ...rest
}: React.PropsWithChildren<FieldRenderProps<TFieldValue>>) {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  return (
    <SingleSelect
      {...rest}
      name={name}
      error={showError}
      options={options}
      onChange={onChange}
      value={value}
      onClose={() => {
        // Mark the field as touched
        onBlur();
      }}
    />
  );
}

export function CheckboxWrapper<TFieldValue>({
  input: { name, onChange, checked, onBlur },
  meta,
  disabled,
  ...rest
}: React.PropsWithChildren<FieldRenderProps<TFieldValue>>) {
  return (
    <Checkbox
      {...rest}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      checked={checked}
      disabled={disabled}
    />
  );
}

export function SwitchWrapper<TFieldValue>({
  input: { name, onChange, checked },
  meta,
  ...rest
}: React.PropsWithChildren<FieldRenderProps<TFieldValue>>) {
  return (
    <Switch {...rest} name={name} onChange={onChange} checked={!!checked} />
  );
}

export function AutoCompleteWrapper<
  TFieldValue extends DropdownOptions<string>,
>({
  input: { value, onChange, onBlur },
  options,
  meta: { error, touched },
  composeOnChangeFunctions,
  fieldOnChangeFunctionName,
  ...rest
}: React.PropsWithChildren<FieldRenderProps<TFieldValue>>) {
  const values = React.useMemo(
    () => autoCompleteFormatter(value, options),
    [value, options]
  );

  const handleChange = React.useCallback(
    (val: DropdownOptions<string>) => {
      onChange?.(val);
    },
    [onChange]
  );

  // To prevent composing functions everytime we memoize it.
  const onChangeFunc = React.useMemo(
    () =>
      composeOnChangeFunctions && fieldOnChangeFunctionName
        ? composeOnChangeFunctions(handleChange, fieldOnChangeFunctionName)
        : handleChange,
    [composeOnChangeFunctions, fieldOnChangeFunctionName, handleChange]
  );

  const handleSearchChange = React.useCallback(() => {
    // We want the component to be marked as touched when search input changes.
    onBlur();
  }, [onBlur]);

  return (
    <AutoComplete
      value={values}
      onChange={onChangeFunc}
      onSearchChange={handleSearchChange}
      options={options}
      error={!!touched && error}
      {...rest}
    />
  );
}

export function MultiSelectWrapper<TFieldValue extends string[]>({
  input: { value, onChange, onBlur },
  options,
  meta: { error, touched, dirtySinceLastSubmit, submitError },
  "data-testid": testId,
  showFixedLabel,
  ...rest
}: React.PropsWithChildren<FieldRenderProps<TFieldValue>>) {
  const showError =
    ((submitError && !dirtySinceLastSubmit) || error) && touched;

  return (
    <MultiSelect
      value={value}
      options={options}
      onChange={onChange}
      showFixedLabel={showFixedLabel}
      error={showError}
      data-testId={testId}
      onBlur={(event) => onBlur(event)}
      {...rest}
    />
  );
}
