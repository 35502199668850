/* eslint-disable import/no-duplicates */
// Set up for the global app localization pipeline
import type { Locale } from "date-fns";
import enAUDateFn from "date-fns/locale/en-AU";
import enUSDateFn from "date-fns/locale/en-US";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";

import enUS from "./locales/en-US.json";
import paIN from "./locales/pa-IN.json";
import tlPH from "./locales/tl-PH.json";
import zhCH from "./locales/zh-CH.json";
import zhTW from "./locales/zh-TW.json";

/** Object of all supported strings. */
export type LocStrings = (typeof resources)["en-US"]["translation"];

/** Supported languages as ISO639-1 + ISO3166. */
export type Language = keyof typeof resources;

const localeMap = new Map<string, Locale>([
  ["en-US", enUSDateFn],
  ["en-AU", enAUDateFn],
]);

/** Change the language for the app. Strings may not be available immediately. */
export const changeLanguage = (language: Language | undefined) => {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  i18n.changeLanguage(language || "en-US");
};

export const useGetDateFormat = (width: string) => {
  const localeFromMap = useCurrentLocale();
  return localeFromMap?.formatLong?.date({ width });
};

export const useCurrentLocale = () => {
  const { i18n: currentLocale } = useTranslation();
  return localeMap.get(currentLocale.language) || enUSDateFn;
};
export const localeList = [
  {
    label: "en-US",
    value: "en-US",
  },
  {
    label: "pa-IN",
    value: "pa-IN",
  },
  {
    label: "tl-PH",
    value: "tl-PH",
  },
  {
    label: "zh-TW",
    value: "zh-TW",
  },
  {
    label: "en-AU",
    value: "en-AU",
  },
];

// Exported for adding test cases.
export const resources = {
  "en-US": enUS,
  "pa-IN": paIN,
  "tl-PH": tlPH,
  "zh-CH": zhCH,
  "zh-TW": zhTW,
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n.use(initReactI18next).init({
  compatibilityJSON: "v3",
  resources,
  fallbackLng: "en-US",
  lng: "en-US",
  interpolation: {
    escapeValue: false,
  },
  returnObjects: true,
});

export default i18n;
