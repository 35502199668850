import { DialogTitle, makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import { H3 } from "components/system/Headings";
import { IconButton } from "components/system/IconButton";
import { useIsMobile } from "hooks/useIsMobile";
import type { SyntheticEvent } from "react";
import { useCallback } from "react";

import { Drawer } from "./Drawer";

interface IBaseDialogProps {
  isOpen: boolean;
  onClose: (e: SyntheticEvent) => void;
  title: string;
  children: React.ReactNode;
}

export const BaseDrawer = ({
  isOpen,
  title,
  onClose,
  children,
}: IBaseDialogProps) => {
  const isMobile = useIsMobile();
  const classes = useStyles();

  const onCloseButtonClicked = useCallback((e) => onClose(e), [onClose]);

  if (!isOpen) {
    return null;
  }

  return (
    <Drawer open={isOpen} onClose={onClose} variant="temporary" anchor="right">
      <div
        className={classNames(
          classes.drawerRoot,
          isMobile ? classes.mobileDrawerRoot : classes.desktopDrawerRoot
        )}
      >
        <DialogTitle disableTypography className={classes.headerRoot}>
          <>
            <H3>{title}</H3>
            <IconButton
              onClick={onCloseButtonClicked}
              className={classes.closeButton}
              aria-label="close"
              variant="tertiary"
            >
              <CloseIcon />
            </IconButton>
          </>
        </DialogTitle>
        {children}
        <div
          id="portal-container"
          style={{ zIndex: 100005, position: "fixed" }}
        />
      </div>
    </Drawer>
  );
};

const useStyles = makeStyles(() => ({
  headerRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  closeButton: {
    padding: 0,
  },
  drawerRoot: {
    display: "flex",
    flexDirection: "column",
  },
  desktopDrawerRoot: {
    width: "65vw",
    height: "100%",
  },
  mobileDrawerRoot: {
    width: "100vw",
    height: "100%",
  },
}));
