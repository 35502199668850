import type { MHDSVariant } from "./theme/mhdsTypography";
import { useEnsureNewTheme } from "./theme/providers";
import type { TypographyProps } from "./Typography";
import { Typography } from "./Typography";

function createHeadingWithVariant<T extends React.ElementType>(
  variant: MHDSVariant,
  component: T
) {
  const Component: React.FC<Omit<TypographyProps, "variant">> = (props) => {
    useEnsureNewTheme();
    return <Typography {...props} variant={variant} component={component} />;
  };
  Component.displayName = `Heading(${variant}, ${component.toString()})`;
  return Component;
}

/**
 * @deprecated This way of using our DS is deprecated. Use {@link Typography} instead
 * [**» Figma Reference**](https://www.figma.com/file/z1t3BL6N5YMMchVSeUTY6I/MHDS---Components?type=design&node-id=5980%3A11975&mode=design&t=sIq81B09m9Dg1ntP-1)
 */
export const H1 = createHeadingWithVariant("displayEmphasis", "h1");
/**
 * @deprecated This way of using our DS is deprecated. Use {@link Typography} instead
 * [**» Figma Reference**](https://www.figma.com/file/z1t3BL6N5YMMchVSeUTY6I/MHDS---Components?type=design&node-id=5980%3A11975&mode=design&t=sIq81B09m9Dg1ntP-1)
 */
export const H2 = createHeadingWithVariant("titleLarge", "h2");
/**
 * @deprecated This way of using our DS is deprecated. Use {@link Typography} instead
 * [**» Figma Reference**](https://www.figma.com/file/z1t3BL6N5YMMchVSeUTY6I/MHDS---Components?type=design&node-id=5980%3A11975&mode=design&t=sIq81B09m9Dg1ntP-1)
 */
export const H3 = createHeadingWithVariant("titleSmall", "h3");
/**
 * @deprecated This way of using our DS is deprecated. Use {@link Typography} instead
 * [**» Figma Reference**](https://www.figma.com/file/z1t3BL6N5YMMchVSeUTY6I/MHDS---Components?type=design&node-id=5980%3A11975&mode=design&t=sIq81B09m9Dg1ntP-1)
 */
export const H4 = createHeadingWithVariant("emphasisLarge", "h4");
/**
 * @deprecated This way of using our DS is deprecated. Use {@link Typography} instead
 * [**» Figma Reference**](https://www.figma.com/file/z1t3BL6N5YMMchVSeUTY6I/MHDS---Components?type=design&node-id=5980%3A11975&mode=design&t=sIq81B09m9Dg1ntP-1)
 */
export const H5 = createHeadingWithVariant("emphasisMedium", "h5");
/**
 * @deprecated This way of using our DS is deprecated. Use {@link Typography} instead
 * [**» Figma Reference**](https://www.figma.com/file/z1t3BL6N5YMMchVSeUTY6I/MHDS---Components?type=design&node-id=5980%3A11975&mode=design&t=sIq81B09m9Dg1ntP-1)
 */
export const H6 = createHeadingWithVariant("emphasisSmall", "h6");
