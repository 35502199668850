import { useAuthorizedUser } from "containers/auth/hooks/useAuthorizedUser";

export function useCurrentUser() {
  const { user } = useAuthorizedUser();
  if (!user) {
    throw new Error("Expected user to be defined");
  }
  return user;
}

export const useOptionalCurrentUser = () => {
  const { user } = useAuthorizedUser(false);
  return user;
};
