import { makeStyles } from "@material-ui/core";
import {
  AccessTime,
  CheckCircleOutlined,
  InfoOutlined,
  ReportProblemOutlined,
} from "@material-ui/icons";
import type { AlertProps, Color } from "@material-ui/lab";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { Alert } from "./Alert";
import type { ButtonProps, ButtonSize } from "./Button";
import { Button } from "./Button";
import { BaseActionButtons } from "./dialogs/BaseActionButtons";
import { useEnsureNewTheme } from "./theme";
import { Typography } from "./Typography";

export type SemanticMessageButtonProps = ButtonProps & {
  text?: string;
  onClick: () => void;
};

export type SemanticMessageProps = Partial<AlertProps> & {
  severity: Color;
  variant?: AlertProps["variant"];
  buttonSize?: ButtonSize;
  title?: string;
  showIcon?: boolean;
  compact?: boolean;
  isLoading?: boolean;
  icon?: React.ReactNode;
  children: React.ReactNode;
  isDialogFooter?: boolean;
  actionButton?: SemanticMessageButtonProps;
  cancelButton?: SemanticMessageButtonProps;
  fitToContent?: boolean;
  destructive?: boolean;
};

export const SemanticMessage = ({
  icon,
  children,
  severity,
  actionButton,
  cancelButton,
  title,
  showIcon = false,
  isLoading = false,
  compact = false,
  variant = "standard",
  buttonSize = "small",
  isDialogFooter,
  fitToContent,
  destructive,
  ...alertProps
}: SemanticMessageProps) => {
  const { t } = useTranslation("semanticMessage");
  useEnsureNewTheme();
  const hasCancelButton = !!cancelButton;
  const classes = useStyles({ hasCancelButton: hasCancelButton || compact });

  const actionText = actionButton?.text || t("buttons.confirm");
  const cancelText = cancelButton?.text || t("buttons.cancel");

  const useOutlineStyle = severity === "info" && variant === "outlined";

  const baseDialogActions = (
    <BaseActionButtons
      isLoading={isLoading}
      cancelTitle={cancelText}
      submitTitle={actionText}
      onCancel={cancelButton?.onClick}
      onSubmit={actionButton?.onClick}
      submitButtonProps={{ style: { marginLeft: 16 } }}
      destructive={destructive}
    />
  );

  const buttonActions = (
    <div className={classes.buttonGroup}>
      {cancelButton && (
        <Button
          size={buttonSize}
          variant="tertiary"
          onClick={cancelButton.onClick}
        >
          {cancelText}
        </Button>
      )}
      {actionButton && (
        <Button
          size={buttonSize}
          variant="secondary"
          onClick={actionButton.onClick}
          disabled={isLoading}
          loading={isLoading}
          className={classes.actionButton}
        >
          {actionText}
        </Button>
      )}
    </div>
  );

  const alertAction = isDialogFooter ? baseDialogActions : buttonActions;

  return (
    <Alert
      {...alertProps}
      icon={showIcon ? icon : undefined}
      className={classNames(classes.alert, {
        [classes.outlinedInfo]: useOutlineStyle,
        [classes[severity]]: !useOutlineStyle,
        [classes.dialogFooterAlert]: isDialogFooter && !fitToContent,
      })}
      iconMapping={{
        success: <CheckCircleOutlined />,
        // After upgrade to MUI 5 should change the warning icon to WarningAmberOutlined
        warning: <ReportProblemOutlined className={classes.warningIcon} />,
        info: useOutlineStyle ? (
          <AccessTime className={classes.outlinedInfoIcon} />
        ) : (
          <InfoOutlined />
        ),
      }}
      severity={severity}
      variant={variant}
      action={alertAction}
    >
      <div>
        {title && (
          <Typography variant="emphasisMedium" className={classes.messageTitle}>
            {title}
          </Typography>
        )}
        <Typography variant="standardMedium">{children}</Typography>
      </div>
    </Alert>
  );
};

const useStyles = makeStyles((theme) => {
  const borderStyle = {
    display: "flex",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderRadius: 0,
    borderStyle: "solid",
  };
  return {
    dialogFooterAlert: {
      minHeight: 70,
      padding: "0 32px",
      display: "flex",
      alignItems: "center",
    },
    alert: (props: { hasCancelButton: boolean }) => ({
      width: "100%",
      flexWrap: "wrap",
      "& > div:nth-child(2)": {
        width: "85%",
        maxWidth: `${props.hasCancelButton ? 400 : 600}px`,
      },
    }),
    messageTitle: {
      marginRight: theme.spacing(1),
    },
    warningIcon: {
      backgroundColor: theme.palette.orange[10],
      color: theme.palette.orange[50],
    },
    outlinedInfoIcon: {
      color: theme.palette.memoraGrey[100],
    },
    buttonGroup: {
      justifyContent: "space-between",
      "& > *": {
        marginRight: theme.spacing(1),
      },
    },
    actionButton: {
      backgroundColor: theme.palette.memoraGrey[0],
      border: `1px solid ${theme.palette.memoraGrey[28]}`,
      borderRadius: 4,
    },
    error: {
      ...borderStyle,
      color: theme.palette.red[55],
      borderColor: theme.palette.red[24],
      backgroundColor: theme.palette.red[13],
    },
    warning: {
      ...borderStyle,
      color: theme.palette.memoraGrey[100],
      borderColor: theme.palette.orange[20],
      backgroundColor: theme.palette.orange[10],
    },
    info: {
      ...borderStyle,
      color: theme.palette.navy[68],
      borderColor: theme.palette.navy[24],
      backgroundColor: theme.palette.navy[10],
    },
    success: {
      ...borderStyle,
      color: theme.palette.green[55],
      borderColor: theme.palette.green[20],
      backgroundColor: theme.palette.green[10],
    },
    outlinedInfo: {
      ...borderStyle,
      color: theme.palette.memoraGrey[100],
      borderColor: theme.palette.memoraGrey[28],
      backgroundColor: theme.palette.memoraGrey[0],
    },
  };
});
