import {
  getNumberParam,
  getStringParam,
  setNumberParam,
  setStringParam,
} from "helpers/queryParams";
import type { RefObject } from "react";
import { useCallback, useEffect, useRef } from "react";
import type { Filters, SortingRule } from "react-table";

import type { FetchArgs, ITableRef } from "../types";

/**
 * keeps the url parameters in sync with the table, pushes url to the
 * table on initial load
 */
export function useTableQueryParams<T extends object>(
  isLoading: boolean,
  table: RefObject<ITableRef<T>>
) {
  // capture query params on initialization
  const initialParams = useRef({
    page: getNumberParam("page"),
    sortBy: sortByFromString(getStringParam("sortBy")),
    filters: filtersFromString(getStringParam("filters")),
  });

  // we can immediately set these after initial render because the table will
  // know what columns we have
  useEffect(() => {
    if (!table.current) {
      return;
    }
    const { setSortBy, setAllFilters, allColumns } = table.current;
    const { sortBy, filters } = initialParams.current;

    if (sortBy) {
      setSortBy(sortBy.filter((s) => allColumns.some((c) => c.id === s.id)));
      initialParams.current.sortBy = undefined;
    }
    if (filters) {
      setAllFilters(
        filters.filter((f) => allColumns.some((c) => c.id === f.id))
      );
      initialParams.current.filters = undefined;
    }
  }, [table]);

  // we have to wait until after initial load to try and set page or it will always
  // fail because we have no data yet and the table will reject going to any page
  // other than zero
  useEffect(() => {
    if (!table.current || isLoading) {
      return;
    }

    const { page } = initialParams.current;
    const { gotoPage } = table.current;

    if (page) {
      gotoPage(page);
      initialParams.current.page = undefined;
    }
  }, [isLoading, table]);

  const onTableArgsChanged = useCallback(
    (args: Omit<FetchArgs<T>, "filters"> & Partial<FetchArgs<T>>) => {
      setNumberParam("page", args.pageIndex === 0 ? undefined : args.pageIndex);
      setStringParam("sortBy", sortByToString(args.sortBy));
      if (args.filters) {
        setStringParam("filters", filtersToString(args.filters));
      }
    },
    []
  );

  return {
    onTableArgsChanged,
  };
}

// Mappers which help serialize/deserialize the sortBy/filters

export function sortByToString(
  sortBy: SortingRule<object>[]
): string | undefined {
  if (sortBy.length === 0) {
    return undefined;
  }
  return sortBy.map((s) => [s.id, s.desc ? "desc" : "asc"].join(":")).join("|");
}

export function sortByFromString(
  val: string | undefined
): SortingRule<object>[] | undefined {
  if (!val) {
    return undefined;
  }
  return val.split("|").map((s) => {
    const [id, descStr] = s.split(":");

    return {
      id: id as string,
      desc: descStr === "desc",
    };
  });
}

export function filtersToString(filters: Filters<object>): string | undefined {
  if (filters.length === 0) {
    return undefined;
  }
  return filters.map((f) => [f.id, f.value].join(":")).join("|");
}

export function filtersFromString(
  val: string | undefined
): Filters<object> | undefined {
  if (!val) {
    return undefined;
  }
  return val.split("|").map((s) => {
    const [id, value] = s.split(":");
    return {
      id: id as string,
      value,
    };
  });
}
