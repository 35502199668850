import type { DialogActionsProps as MuiDialogActionsProps } from "@material-ui/core";
import {
  alpha,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
  DialogTitle as MuiDialogTitle,
  ListSubheader as MuiListSubheader,
  makeStyles,
} from "@material-ui/core";
import classNames from "classnames";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "../Box";
import type {
  SemanticMessageButtonProps,
  SemanticMessageProps,
} from "../SemanticMessage";
import { SemanticMessage } from "../SemanticMessage";
import { theme, withEnsureNewTheme } from "../theme";
import { BaseActionButtons } from "./BaseActionButtons";

type DialogActionButtonProps = SemanticMessageButtonProps & {
  disabled?: boolean;
};

export type DialogActionsProps = MuiDialogActionsProps & {
  isLoading?: boolean;
  actionButtons: {
    submitButton: DialogActionButtonProps;
    cancelButton: DialogActionButtonProps;
    isSubmitAtStart?: boolean;
  };
  confirmMessage?: {
    severity?: SemanticMessageProps["severity"];
    message?: string | ReactNode;
    actionTitle?: string;
    cancelTitle?: string;
    nonBlockingMessage?: boolean;
    onActionClick?: () => void;
    onCancelClick?: () => void;
  };
};

const DialogActionsCore = ({
  isLoading,
  actionButtons,
  confirmMessage,
  ...rest
}: DialogActionsProps) => {
  const { t } = useTranslation("dialogActions");
  const styles = useStyles();
  let semanticMessage: ReactNode;
  const severityMessage = confirmMessage?.severity || "error";
  const showBlockingConfirmMessage =
    confirmMessage && !confirmMessage.nonBlockingMessage;

  if (confirmMessage) {
    const actionText = confirmMessage.actionTitle || t("buttons.discard");
    const cancelText = confirmMessage.cancelTitle || t("buttons.cancel");
    const message = confirmMessage.message || t("textBody");

    const actionButton = confirmMessage.onActionClick
      ? {
          text: actionText,
          onClick: confirmMessage.onActionClick || (() => {}),
        }
      : undefined;

    const cancelButton = confirmMessage.onCancelClick
      ? {
          text: cancelText,
          onClick: confirmMessage.onCancelClick || (() => {}),
        }
      : undefined;

    semanticMessage = (
      <SemanticMessage
        isLoading={isLoading}
        isDialogFooter
        severity={severityMessage}
        actionButton={actionButton}
        cancelButton={cancelButton}
        buttonSize="large"
        fitToContent={!showBlockingConfirmMessage}
        destructive={severityMessage === "error"}
      >
        {message}
      </SemanticMessage>
    );
  }

  const actionText = actionButtons.submitButton.text || t("buttons.save");
  const cancelText = actionButtons.cancelButton.text || t("buttons.cancel");

  return (
    <Box
      className={classNames(styles.dialogActionsWrapper, {
        [styles.blockdDialogActionsWrapper]: showBlockingConfirmMessage,
      })}
    >
      {showBlockingConfirmMessage && <Box className={styles.blockingBox} />}
      {confirmMessage?.nonBlockingMessage && semanticMessage}
      <MuiDialogActions
        {...rest}
        className={classNames(rest.className, {
          [styles.blockdDialogActions]: showBlockingConfirmMessage,
        })}
      >
        {!showBlockingConfirmMessage && (
          <BaseActionButtons
            submitDisabled={actionButtons.submitButton.disabled}
            submitButtonProps={{ hidden: actionButtons.submitButton.hidden }}
            submitTitle={actionText}
            onSubmit={actionButtons.submitButton.onClick}
            cancelTitle={cancelText}
            onCancel={actionButtons.cancelButton.onClick}
            cancelButtonProps={{ variant: actionButtons.cancelButton.variant }}
            isSubmitAtStart={actionButtons.isSubmitAtStart}
          />
        )}
        {showBlockingConfirmMessage && semanticMessage}
      </MuiDialogActions>
    </Box>
  );
};

export const DialogActionsV2 = withEnsureNewTheme(DialogActionsCore);
/**
 * @deprecated Use DialogActionsV2 instead
 */
export const DialogActions = MuiDialogActions;
export const DialogContent = MuiDialogContent;
export const DialogTitle = MuiDialogTitle;

export const DialogContentText = MuiDialogContentText;
export const ListSubheader = MuiListSubheader;

const useStyles = makeStyles({
  blockdDialogActions: {
    display: "block",
    padding: 0,
  },
  blockdDialogActionsWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  blockingBox: {
    backgroundColor: alpha(theme.palette.grey[100], 0.5),
    position: "absolute",
    width: "100%",
    height: "calc(100% - 72px)",
    top: 0,
    left: 0,
    zIndex: 1,
  },
  dialogActionsWrapper: {
    minHeight: 72,
  },
});
