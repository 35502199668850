import type { ButtonProps as MuiButtonProps } from "@material-ui/core";
import {
  alpha,
  Button as MuiButton,
  CircularProgress,
  withStyles,
} from "@material-ui/core";
import classNames from "classnames";
import { ICON_DEF } from "components/system/tokens/icons";
import { forwardRef } from "react";

import { useEnsureNewTheme } from "./theme/providers";

export type ButtonVariant = "primary" | "secondary" | "tertiary";
export type ButtonSize = "large" | "small";

export type ButtonProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
  emphasis?: boolean;
  destroyer?: boolean;
  loading?: boolean;
} & Omit<MuiButtonProps, "variant" | "size">;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      size,
      emphasis,
      destroyer,
      startIcon: propsStartIcon,
      loading,
      className,
      ...rest
    },
    ref
  ) => {
    useEnsureNewTheme();

    let startIcon = propsStartIcon;
    if (loading && !startIcon) {
      startIcon = <LoadingIcon />;
    }

    const composedProps: MuiButtonProps = {
      ...variantToMuiProps[variant || "secondary"],
      size: sizeToMuiSize[size || "large"],
      disableElevation: true,
      disableRipple: true,
      className: classNames(
        emphasis && !destroyer && "emphasis", // emphasis not allowed when destroyer
        destroyer && "destroyer",
        className
      ),
      startIcon,
      ...rest,
    };
    return <StyledMuiButton ref={ref} {...composedProps} role="button" />;
  }
);
Button.displayName = "Button";

const variantToMuiProps: { [key in ButtonVariant]: MuiButtonProps } = {
  primary: {
    variant: "contained",
    color: "primary",
  },
  secondary: {
    variant: "outlined",
  },
  tertiary: {
    variant: "text",
  },
};
export const sizeToMuiSize: {
  [key in ButtonSize]: "medium" | "small";
} = {
  large: "medium",
  small: "small",
};

const StyledMuiButton = withStyles((theme) => ({
  root: {
    minHeight: 40,
    minWidth: 0,
    padding: "8px 16px",
    whiteSpace: "nowrap",
    "&:disabled": {
      color: theme.palette.memoraGrey[40],
      backgroundColor: theme.palette.memoraGrey[18],
    },
    "&.iconButton": {
      padding: 8,
    },
  },
  containedPrimary: {
    backgroundColor: theme.palette.blue[50],
    "&:hover, &:focus-visible": {
      backgroundColor: theme.palette.blue[60],
    },
    "&.emphasis:not(:disabled)": {
      backgroundColor: theme.palette.blue[50],
      "&:hover, &:focus-visible": {
        backgroundColor: theme.palette.blue[60],
      },
    },
    "&.destroyer:not(:disabled)": {
      backgroundColor: theme.palette.red[50],
      "&:hover, &:focus-visible": {
        backgroundColor: theme.palette.red[60],
      },
    },
    "&:disabled": {
      backgroundColor: theme.palette.memoraGrey[18],
    },
  },
  outlined: {
    "&:hover, &:focus-visible": {
      border: `1px solid ${theme.palette.memoraGrey[55]}`,
      backgroundColor: theme.palette.memoraGrey[12],
    },
    "&:disabled": {
      borderColor: "transparent",
    },
    "&.emphasis:not(:disabled)": {
      color: theme.palette.blue[50],
      border: `1px solid ${theme.palette.blue[41]}`,
      "&:hover, &:focus-visible": {
        color: theme.palette.blue[55],
        border: `1px solid ${theme.palette.blue[50]}`,
        backgroundColor: theme.palette.blue[10],
      },
    },
    "&.destroyer:not(:disabled)": {
      color: theme.palette.red[50],
      border: `1px solid ${theme.palette.red[41]}`,
      "&:hover, &:focus-visible": {
        color: theme.palette.red[55],
        border: `1px solid ${theme.palette.red[50]}`,
        backgroundColor: theme.palette.red[13],
      },
    },
  },
  text: {
    backgroundColor: "transparent",
    "&:disabled": {
      color: theme.palette.memoraGrey[28],
      backgroundColor: "transparent",
    },
    "&:hover, &:focus-visible": {
      color: theme.palette.blue[55],
      backgroundColor: alpha(theme.palette.memoraGrey[28], 0.1),
    },
    "&.emphasis:not(:disabled)": {
      color: theme.palette.blue[50],
      "&:hover, &:focus-visible": {
        color: theme.palette.blue[55],
        backgroundColor: alpha(theme.palette.blue[24], 0.1),
      },
    },
    "&.destroyer:not(:disabled)": {
      color: theme.palette.red[50],
      "&:hover, &:focus-visible": {
        color: theme.palette.red[55],
        backgroundColor: theme.palette.red[13],
      },
    },
  },
  colorInherit: {
    "&$text": {
      borderColor: "transparent",
    },
  },
  startIcon: {
    marginLeft: -2,
    marginRight: 4,
  },
  endIcon: {
    marginLeft: 4,
    marginRight: -2,
  },
  sizeSmall: {
    fontSize: theme.typography.body2.fontSize,
    minHeight: 30,
    padding: "6px 12px",
    "& $label": {
      "& > svg": {
        fontSize: ICON_DEF.large.fontSize,
      },
    },
  },
  label: {
    transition: "color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
}))(MuiButton);

const LoadingIcon = () => <CircularProgress color="inherit" size="1rem" />;
