import { Box, TableCell } from "@material-ui/core";
import type {
  ColumnInstance,
  TableHeaderProps,
  TableInstance,
} from "react-table";

import { useStyles } from "../hooks/useStyles";
import { ResetButtonCell } from "./ResetButtonCell";
import { SortOptions } from "./SortOptions";

interface IHeaderCellProps<T extends object> {
  column: ColumnInstance<T>;
  numSortsApplied: number;
  table: TableInstance<T>;
}

export function HeaderCell<T extends object>(
  props: IHeaderCellProps<T> & TableHeaderProps
) {
  const { column, numSortsApplied, table, ...rest } = props;
  const styles = useStyles();

  return (
    <TableCell
      valign="bottom"
      classes={{
        root: styles.headerCell,
      }}
      {...rest}
    >
      <HeaderCellContents
        column={column}
        numSortsApplied={numSortsApplied}
        table={table}
      />
    </TableCell>
  );
}

function HeaderCellContents<T extends object>(props: IHeaderCellProps<T>) {
  const { column, table } = props;
  const contents = column.render("Header") as JSX.Element;
  if (column.canSort) {
    return (
      <Box display="flex" alignItems="center">
        {contents}
        <SortOptions {...props} />
      </Box>
    );
  }
  if (column.id === "actions") {
    return (
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <ResetButtonCell table={table} />
      </Box>
    );
  }
  return contents;
}
