import { useLoadPanelData } from "containers/auth/hooks/useLoadPanelData";
import { hocify } from "helpers/hoc";

export function useCurrentProvider() {
  const { data: providerData } = useLoadPanelData();
  if (!providerData?.provider) {
    throw new Error("Expected provider to be defined");
  }

  return providerData?.provider;
}

export const withCurrentProvider = hocify(
  useCurrentProvider,
  "currentProvider"
);

export function useOptionalCurrentProvider() {
  const { data: providerData } = useLoadPanelData(false);
  return providerData?.provider;
}
