import { TimerOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { memo } from "react";

import { Body, withNewTheme } from "../components/system";

const SessionTimeout = withNewTheme(() => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <TimerOutlined className={classes.icon} />
      <Body>
        Your session has timed out. Please re-initiate the application.
      </Body>
    </div>
  );
});

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
  },
  icon: {
    height: "80px",
    width: "80px",
    marginBottom: "8px",
    color: "#77777D",
  },
}));

export default memo(SessionTimeout);
