import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import * as Sentry from "@sentry/react";
import { APP_CRASH } from "logging/constants";
import { clientLogger } from "logging/logger";
import * as React from "react";
import { useCallback } from "react";

import { USE_SENTRY } from "../config";
import { completeScenario, failScenario, startScenario } from "./scenarioApi";

interface IErrorBoundaryProps {
  /** Name of the telemetry event to log on error catch. */
  crashTelemetryKey?: string;
  /** React component that renders on error catch. If unspecified, this component throws the error instead. */
  fallbackComponent?: React.ComponentType<{ error?: Error }>;
  /** Name of the component. On error catch, this is logged. */
  componentName?: string;
}

const WrappedSentryErrorBoundary: React.FC<IErrorBoundaryProps> = (props) => {
  const {
    children,
    fallbackComponent: FallbackComponent,
    crashTelemetryKey,
    componentName,
  } = props;

  const onError = useCallback(
    (e: Error, componentStack: string) => {
      try {
        clientLogger.error(crashTelemetryKey || APP_CRASH, {
          err: e,
          componentTrace: componentStack,
          url: window.location.href,
          componentName,
          caughtInBoundary: true,
        });
      } catch {
        // failed to log error
      }
    },
    [componentName, crashTelemetryKey]
  );

  return (
    <SentryErrorBoundary
      showDialog={false}
      onError={onError}
      fallback={
        FallbackComponent
          ? // eslint-disable-next-line react/no-unstable-nested-components
            ({ error }) => <FallbackComponent error={error} />
          : undefined
      }
      beforeCapture={(scope) => {
        if (componentName) {
          scope.setTag("errorBoundaryName", componentName);
        }
        if (crashTelemetryKey) {
          scope.setTag("crashTelemetryKey", crashTelemetryKey);
        }
      }}
    >
      {children}
    </SentryErrorBoundary>
  );
};

interface IErrorBoundaryState {
  error: undefined | Error;
}

/** Class that logs caught errors and can render a fallback UI within this container's parent. */
class LegacyErrorBoundary extends React.PureComponent<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { error: undefined };
  }

  public static getDerivedStateFromError(e: any) {
    return { error: e };
  }

  public componentDidCatch(e: Error, errorInfo: React.ErrorInfo) {
    try {
      const { crashTelemetryKey, componentName } = this.props;
      clientLogger.error(crashTelemetryKey || APP_CRASH, {
        err: e,
        componentTrace: errorInfo,
        url: window.location.href,
        componentName,
      });
    } catch {
      // failed to log error
    }
  }

  public render() {
    const { error } = this.state;
    const { children } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    if (error && this.props.fallbackComponent) {
      return <this.props.fallbackComponent error={error} />;
    }

    return children;
  }
}

/**
 * Provides an error boundary that reports to logger and to sentry, if enabled
 */
export const ErrorBoundary = USE_SENTRY
  ? WrappedSentryErrorBoundary
  : LegacyErrorBoundary;

type WithProfilerComponent = Parameters<typeof Sentry.withProfiler>[0];

/**
 * Wraps a component with a profiler which sends traces of renders to Sentry
 */
export function withProfiler(Component: WithProfilerComponent) {
  return USE_SENTRY ? Sentry.withProfiler(Component) : Component;
}

/**
 * Scenario tracking hookified
 */
export const useScenario = (eventName: string) => {
  const scenarioId = React.useRef<string | null>();

  const start = useCallback(() => {
    scenarioId.current = startScenario(eventName);
  }, [eventName]);

  const complete = useCallback(() => {
    if (!scenarioId.current) {
      return;
    }
    completeScenario(scenarioId.current);
    scenarioId.current = null;
  }, []);

  const fail = useCallback(() => {
    if (!scenarioId.current) {
      return;
    }
    failScenario(scenarioId.current);
    scenarioId.current = null;
  }, []);

  return React.useMemo(
    () => ({
      start,
      complete,
      fail,
    }),
    [start, complete, fail]
  );
};
