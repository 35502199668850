import "./localization";

import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BrowserRouter as Router } from "react-router-dom";

import { AppRouter } from "./AppRouter";
import asyncComponent from "./helpers/asyncFunction";
import { ActivityTracker } from "./infra/activityTracking/ActivityTracker";
import { RootThemeProvider } from "./infra/theming/RootThemeProvider";
import { ToastProvider } from "./infra/toasts";
import * as Tracing from "./infra/tracing";
import LocalizationProvider from "./localizationProvider";
import { queryClient } from "./queryClient";

const ErrorPage = asyncComponent(() => import("./components/util/ErrorPage"));

const App = () => (
  <Router>
    <RootThemeProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <LocalizationProvider>
          <Tracing.ErrorBoundary
            componentName="App"
            fallbackComponent={ErrorPage}
          >
            <ToastProvider>
              <AppRouter />
              <ActivityTracker />
            </ToastProvider>
          </Tracing.ErrorBoundary>
        </LocalizationProvider>
      </QueryClientProvider>
    </RootThemeProvider>
  </Router>
);

export default Tracing.withProfiler(App);
