import { TableCell } from "@material-ui/core";
import classNames from "classnames";
import type { Cell, TableCellProps } from "react-table";

import { useStyles } from "../hooks/useStyles";
import type { ITableProps } from "../types";

interface IBodyCellProps<T extends object> {
  cell: Cell<T, any>;
  fixedLayout?: boolean;
  columnCellProps?: ReturnType<Required<ITableProps<T>>["getColumnCellProps"]>;
  enableHeaders?: boolean;
}

export function BodyCell<T extends object>(
  props: IBodyCellProps<T> & TableCellProps
) {
  const {
    cell,
    columnCellProps,
    fixedLayout,
    enableHeaders = false,
    ...rest
  } = props;
  const styles = useStyles();

  const additionalCellProps = {
    ...rest,
    ...columnCellProps,
  };

  const finalCellProps = fixedLayout
    ? { ...additionalCellProps, width: cell.column.width }
    : { ...additionalCellProps };

  return (
    <TableCell
      classes={{
        root: styles.cell,
      }}
      {...finalCellProps}
    >
      {enableHeaders && (
        <div className={classNames(styles.headerCell, "header-in-cell")}>
          {cell.column.render("Header")}
        </div>
      )}
      <div {...columnCellProps}>{cell.render("Cell")}</div>
    </TableCell>
  );
}
