import type { CollapseProps as MuiCollapseProps } from "@material-ui/core";
import { Collapse as MuiCollapse } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

import { useEnsureNewTheme } from "./theme";

const CollapseStyled = withStyles({})(MuiCollapse);

export function Collapse({ children, ...rest }: MuiCollapseProps) {
  useEnsureNewTheme();
  return <CollapseStyled {...rest}>{children}</CollapseStyled>;
}
