import { ListItem, ListItemIcon, withStyles } from "@material-ui/core";
import { Body } from "components/system/Body";

import { CoreMenuItem } from "../MenuItem";
import { useNestedMenuContext } from "./context";
import type { TerminalMenuItemProps } from "./types";
/**
 * injected styling onto the Body for the menu item
 */
const StyledBody = withStyles(() => ({
  root: {
    paddingLeft: "8px",
    paddingRight: "16px",
  },
}))(Body);

/**
 * injected styling onto the list item icon
 */
const StyledListItemIcon = withStyles(() => ({
  root: {
    marginRight: "0px",
  },
}))(ListItemIcon);

/**
 * Standard Menu Item to be used in Menus
 */
export const TerminalMenuItem = ({
  title: itemTitle,
  onClick,
  selected,
  icon: itemIcon,
  rightIcon,
  leftIcon,
  size: sizeProp,
  color,
  ...rest
}: TerminalMenuItemProps) => {
  const { size } = useNestedMenuContext(sizeProp);
  return (
    <CoreMenuItem
      selected={selected}
      key={itemTitle}
      onClick={onClick}
      {...rest}
      role="menuitem"
    >
      <ListItem dense disableGutters style={{ color }}>
        {itemIcon && <StyledListItemIcon>{itemIcon}</StyledListItemIcon>}
        <StyledListItemIcon>{leftIcon}</StyledListItemIcon>
        <StyledBody size={size === "large" ? "medium" : "small"}>
          {itemTitle}
        </StyledBody>
        <StyledListItemIcon>{rightIcon}</StyledListItemIcon>
      </ListItem>
    </CoreMenuItem>
  );
};
