import type { MenuProps } from "@material-ui/core";
import type { ClassNameMap } from "@material-ui/styles";

import type { OptionObjectType, OptionType } from "./types";

export const getMenuProps = (
  isOptionEqualsToValue: boolean,
  totalOptions: number,
  classNames: ClassNameMap<string>
): Partial<MenuProps> => {
  const isAllSelected = isOptionEqualsToValue && totalOptions > 0;
  return {
    className: isAllSelected ? classNames.allSelected : classNames.showMenu,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },

    transformOrigin: {
      vertical: -5,
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };
};

export const getLabel = (options: OptionType[], val: string) => {
  if (
    Array.isArray(options) &&
    options?.length &&
    typeof options[0] !== "string"
  ) {
    const label = (options as OptionObjectType[]).filter(
      (option) => option?._id === val
    )[0];
    return label?.name || "";
  }
  return val;
};
