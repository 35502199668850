import type { MenuItemProps } from "../menus/types";
import type { MenuItemProvider } from "./types";

type GetItemPropsHook<TRequiredData = {}> = (
  data: TRequiredData
) => MenuItemProps | undefined;

/**
 * Creates a menu item that goes to a given location when clicked
 * @param useHook Hook which takes required data and returns information about the menu item. Returns undefined if unauthorized
 */
export function createHookItem<TRequiredData>(
  useHook: GetItemPropsHook<TRequiredData>
) {
  const Component: MenuItemProvider<TRequiredData> = ({ data, children }) => {
    const result = useHook(data);
    return <>{children(result)}</>;
  };
  Component.displayName = useHook.name;
  return Component;
}
