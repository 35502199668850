import type {
  RadioProps as MuiRadioProps,
  SvgIconProps,
} from "@material-ui/core";
import {
  createStyles,
  FormControlLabel,
  makeStyles,
  Radio as RadioMui,
  SvgIcon,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Body } from "components/system/Body";

import type { Size as BodySize } from "./MuiTypography";
import { useEnsureNewTheme } from "./theme/providers";

const FormControlLabelStyled = withStyles((theme) => ({
  root: {
    marginLeft: 0,
    marginRight: 0,
  },
  label: {
    "&$disabled": {
      color: theme.palette.memoraGrey[40],
    },
    paddingLeft: 4,
    lineHeight: "20px",
  },
  disabled: {},
}))(FormControlLabel);

const RadioButtonStyled = withStyles((theme) => ({
  root: {
    background: "none",
    border: 0,
    margin: "0 6px 0 0",
    padding: 0,
    "&$disabled": {
      background: "none",
    },
    "&:hover, &.Mui-focusVisible": {
      background: "none",
      color: theme.palette.blue[41],
    },
  },
  disabled: {},
}))(RadioMui);

const SvgCircle = () => (
  <circle
    cx="9"
    cy="9"
    r="8.5"
    fill="transparent"
    stroke="currentColor"
    strokeWidth="1"
  />
);

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      width: 18,
      height: 18,
    },
    "input:checked ~ &": {
      color: theme.palette.blue[41],
    },
    "input:disabled ~ &": {
      color: theme.palette.memoraGrey[28],
    },
  })
);

const UnselectedIcon = (props: SvgIconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon {...props} className={classes.icon} viewBox="0 0 18 18">
      <SvgCircle />
    </SvgIcon>
  );
};

const SelectedIcon = (props: SvgIconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon {...props} className={classes.icon} viewBox="0 0 18 18">
      <SvgCircle />
      <circle cx="9" cy="9" r="5" fill="currentColor" />
    </SvgIcon>
  );
};

export type RadioProps = {
  label?: string;
  labelSize?: BodySize;
} & Omit<MuiRadioProps, "variant" | "size">;

export function Radio({
  className,
  label,
  labelSize,
  value,
  ...rest
}: RadioProps) {
  useEnsureNewTheme();
  if (label) {
    return (
      <FormControlLabelStyled
        className={className}
        value={value}
        control={
          <RadioButtonStyled
            {...rest}
            color="primary"
            checkedIcon={<SelectedIcon />}
            icon={<UnselectedIcon />}
          />
        }
        label={<Body size={labelSize || "medium"}>{label}</Body>}
      />
    );
  }

  return (
    <RadioButtonStyled
      {...rest}
      value={value}
      className={className}
      color="primary"
      checkedIcon={<SelectedIcon />}
      icon={<UnselectedIcon />}
    />
  );
}
Radio.displayName = "Radio";
