import type { PaperProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import type { TextFieldProps } from "@material-ui/core/TextField";
import TextField from "@material-ui/core/TextField";
import type { FilterOptionsState } from "@material-ui/lab";
import type {
  AutocompleteChangeReason,
  AutocompleteGetTagProps,
  AutocompleteProps,
} from "@material-ui/lab/Autocomplete";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import type { ChangeEvent } from "react";
import { useCallback, useMemo } from "react";

import { Chip } from "../Chip";
import { useEnsureNewTheme } from "../theme/providers";
import { MultiAutoCompletePaper } from "./MultiAutocompletePaper";
import type { Option } from "./SingleSelect";

const useStyles = makeStyles((theme) => ({
  option: {
    ...theme.typography.labelMedium,
    padding: 0,
    "&:active": {
      color: theme.palette.memoraGrey[0],
    },
  },
  customOption: {
    padding: theme.spacing(1, 2),
  },
}));

const filter = createFilterOptions<Option>({
  matchFrom: "start",
  stringify: (option) => option.label,
});

type AllMultiAutocompleteProps = AutocompleteProps<Option, true, true, false>;
type AllowedMultiAutocompleteProps = Pick<
  AllMultiAutocompleteProps,
  "options" | "value" | "limitTags" | "disabled" | "onBlur" | "onFocus"
>;
type OurProps = {
  label?: string;
  selectAllLabel?: string;
  noOptionsText?: string;
  allOptionsSelectedText?: string;
  showFixedLabel?: boolean;
  infoLabel?: string;
  color?: "primary" | "secondary";
  variant?: "outlined" | "filled" | "standard";
  placeholder?: string;
  onChange(newValue: Array<Option>): void;
};

type MultiSelectProps = AllowedMultiAutocompleteProps & OurProps;

export const MultiAutoComplete = ({
  options,
  label,
  selectAllLabel = "Select all",
  noOptionsText = "No options",
  allOptionsSelectedText = "All options selected",
  infoLabel = "",
  showFixedLabel = false,
  color = "primary",
  variant = "outlined",
  value,
  onChange,
  placeholder,
  ...passthroughProps
}: MultiSelectProps) => {
  const classes = useStyles();
  useEnsureNewTheme();

  const onChangeInternal = useCallback(
    (
      event: ChangeEvent<{}>,
      selectedOptionsChange: Option[],
      reason: AutocompleteChangeReason
    ): void => {
      if (reason === "select-option" || reason === "remove-option") {
        if (
          selectedOptionsChange.find(
            (option: Option) => option.value === "select-all"
          )
        ) {
          const result = options.filter(
            (el: Option) => el.value !== "select-all"
          );
          onChange(result);
        } else {
          onChange(selectedOptionsChange);
        }
      }
      if (reason === "clear") {
        onChange([]);
      }
    },
    [onChange, options]
  );

  const renderInput = useCallback(
    (params: TextFieldProps) => (
      <TextField
        label={label}
        variant={variant}
        color={color}
        placeholder={value?.length === 0 ? placeholder : undefined}
        {...params}
      />
    ),
    [label, variant, color, placeholder, value]
  );

  const renderTags = useCallback(
    (tagValue: Array<Option>, getTagProps: AutocompleteGetTagProps) =>
      tagValue.map((option: Option, index: number) => (
        <Chip
          {...getTagProps({ index })}
          label={option.label}
          key={option.label}
        />
      )),
    []
  );

  const renderOption = useCallback(
    (option: Option) => (
      <div className={classes.customOption}>{option.label}</div>
    ),
    [classes]
  );

  const filterOptions = useCallback(
    (foptions: Option[], state: FilterOptionsState<any>): any => {
      const filtered = filter(foptions, state);
      if (filtered && filtered.length > 0) {
        const optionsList = [
          { label: selectAllLabel, value: "select-all" },
          ...filtered,
        ];
        return optionsList;
      }
      return filtered;
    },
    [selectAllLabel]
  );

  // TODO: defining an inline component like this is a no-no, find workaround
  // shoudln't happen super frequently due to props changes
  const PaperComponent = useCallback(
    (props: PaperProps) => (
      <MultiAutoCompletePaper
        infoLabel={infoLabel}
        showFixedLabel={showFixedLabel && options.length > 0}
        {...props}
      />
    ),
    [infoLabel, options.length, showFixedLabel]
  );

  const allOptionsSelected = useMemo(
    () => options.length > 0 && options.length === (value || []).length,
    [options.length, value]
  );

  return (
    <Autocomplete
      // our wrapped helpers
      onChange={onChangeInternal}
      renderInput={renderInput}
      renderTags={renderTags}
      renderOption={renderOption}
      filterOptions={filterOptions}
      getOptionLabel={(option: Option): string => option.label}
      getOptionSelected={(a: Option, b: Option) => a.value === b.value}
      noOptionsText={
        allOptionsSelected ? allOptionsSelectedText : noOptionsText
      }
      PaperComponent={PaperComponent}
      classes={{
        option: classes.option,
      }}
      // defaults not overridable
      fullWidth
      multiple
      selectOnFocus={false}
      disableCloseOnSelect
      filterSelectedOptions
      // pass throughs
      value={value}
      options={options}
      {...passthroughProps}
    />
  );
};

MultiAutoComplete.displayName = "MultiAutoComplete";
