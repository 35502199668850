import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  clearButton: {
    position: "absolute",
    top: 0,
    right: 0,
    "& button": {
      padding: 0,
      margin: 0,
    },
    "&:hover": {
      background: "transparent",
    },
  },
  iconOpen: {
    color: theme.palette.memoraGrey[0],
  },
  addBody: {
    flex: 1,
  },
  addIcon: {
    display: "flex",
    flex: 0,
    marginRight: 2,
  },
  iconOpenEmpty: {
    color: theme.palette.memoraGrey[0],
  },
  formControlLargeRoot: {
    "& .customIconBorder": {
      top: "1px",
      height: "95%",
    },
  },
  menuItem: {
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  muiInputNormal: {
    padding: theme.spacing(1, 2),
  },
  muiInputLabel: {
    ...theme.typography.labelMedium,
    transform: "none !important",
    width: "auto",
    maxWidth: "calc(100% - 37px)",
    padding: "6px 0 0px 8px",
    color: theme.palette.memoraGrey[55],
    whiteSpace: "nowrap",
    overflow: "clip",
    textOverflow: "ellipsis",
  },
  muiInputLabelLarge: {
    ...theme.typography.labelLarge,
    transform: "none !important",
    maxWidth: "calc(100% - 37px)",
    margin: 10,
    color: theme.palette.memoraGrey[55],
  },
  muiInputLarge: {
    padding: theme.spacing(2, 2.5),
  },
  muiInput: {
    padding: theme.spacing(1, 2),
    border: `2px solid ${theme.palette.blue[41]} !important`,
    backgroundColor: `${theme.palette.memoraGrey[0]} !important`,
    "&:hover": {
      outline: `none !important `,
    },
  },
  disabled: {
    border: "none !important",
    outline: "none !important",
    color: `${theme.palette.memoraGrey[28]} !important`,
    "&~ .customIconBorder": {
      border: "none !important",
      background: "none",
    },
  },
  inputLabelFocusedOpen: {
    color: `${theme.palette.memoraGrey[0]} !important`,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  allSelected: {
    display: "none",
    maxHeight: "40vh",
    minHeight: "12.5rem",
    width: 0,
  },
  showMenu: {
    display: "inherit",
    maxHeight: "40vh",
    minHeight: "12.5rem",
    width: 0,
  },
}));
