import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  tableContainer: {
    position: "relative",
  },
  fixedTableContainer: {
    position: "relative",
    tableLayout: "fixed",
  },
  tableHead: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    position: "relative",
  },
  headerCell: {
    paddingLeft: "1rem",
    whiteSpace: "pre",
    height: 44,
  },
  loaderCell: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  noHeaderWrapper: {
    position: "relative",
  },
  noHeaderLoader: {
    position: "absolute",
    width: "99%",
    top: -16,
    padding: 4,
    margin: 4,
  },
  filterRow: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  disableElevation: {
    border: "none",
  },
  cell: {
    paddingLeft: 12,
    "tr:last-child &": {
      borderBottom: 0,
    },
    whiteSpace: "pre",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "&:last-child": {
        borderColor: theme.palette.memoraGrey[55],
      },
    },
    "& .header-in-cell": {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
  },
  virtualizedCell: {
    display: "flex",
    alignItems: "center",
  },
  virtualizedBody: {
    display: "grid",
    position: "relative",
  },
  virtualizedContainer: {
    width: `100%`,
  },
  tableRow: {
    display: "flex",
    position: "absolute",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "unset",
    },
  },
  footer: {
    borderBottom: "none",
  },
}));
