import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "core-js/modules/esnext.string.replace-all";
import "./styles.css";

import { createBrowserHistory } from "history";
import { enableES5 } from "immer";
import ReactDOM from "react-dom";

import App from "./App";
import { getFeatureFlagsProvider } from "./infra/featureFlags";
import { initTracing } from "./infra/tracing";

const history = createBrowserHistory();

// Cerner requires frame-busting
if (document.documentElement.getAttribute("hidden") !== null) {
  import("cerner-smart-embeddable-lib")
    .then(() => {
      window.CernerSmartEmbeddableLib.calcFrameHeight = function () {
        return 835; // magic number that looks acceptable on the cerner mPage
      };
      return undefined;
    })
    .catch((error) => {
      console.log(error);
      return undefined;
    });
}

enableES5();
// eslint-disable-next-line @typescript-eslint/no-floating-promises
(async () => {
  initTracing(history);
  const FlagsProvider = await getFeatureFlagsProvider();

  ReactDOM.render(
    <FlagsProvider>
      <App />
    </FlagsProvider>,
    document.querySelector("#root")
  );
})();
