import { makeStyles } from "@material-ui/core";

export const useAutoCompleteStyles = makeStyles((theme) => ({
  inputWrapper: {
    borderRadius: 4,
    outline: `1px solid ${theme.palette.memoraGrey[18]}`,
    background: theme.palette.memoraGrey[10],
    minHeight: 40,
    boxSizing: "border-box",

    "&:hover": {
      outline: `2px solid ${theme.palette.memoraGrey[28]}`,
    },

    "&:focus-within": {
      background: theme.palette.memoraGrey[0],

      outline: `2px solid ${theme.palette.blue[41]}`,
    },
  },
  error: {
    outline: `2px solid ${theme.palette.red[50]}`,
  },
  disabled: {
    pointerEvents: "none",
  },
  input: {
    flex: 1,
    minWidth: 200,
    // We need to specify important here as MUI adds inline css during runtime
    background: "inherit !important",

    "& fieldset": {
      border: 0,
    },
  },
  inputWrapperFocused: {
    outline: `2px solid ${theme.palette.blue[41]} !important`,
  },
  autoCompleteWrapper: {
    display: "flex",
    borderRadius: 4,
  },
  multiSelectWrapper: {
    justifyContent: "space-between",
    alignItems: "center",
  },
  singleSelectWrapper: {
    display: "flex",
    width: "100%",
  },
  tagsWrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "wrap",
    background: "inherit",
  },
  chip: {
    margin: "8px 0 0 8px",
    "& > span": {
      whiteSpace: "break-spaces",
    },
  },
  menuItem: {
    "&:hover": {
      background: theme.palette.blue[10],
    },
  },
  title: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
  subtitle: {
    overflow: "hidden",
    textAlign: "right",
    display: "inline-table",
    marginLeft: 6,
    textOverflow: "ellipsis",
    color: theme.palette.memoraGrey[55],
  },
  selectedItem: {
    color: theme.palette.blue[55],
  },
  singleOptionButton: {
    width: "100%",
    display: "block",
    textAlign: "left",
    paddingRight: 0,
    maxWidth: "calc(100% - 37px)",
    "& > *": {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "100%",
    },
  },
  singleOptionDeleteButton: {
    width: 40,
    height: 40,
  },
  menuList: {
    borderRadius: 4,
    border: `1px solid ${theme.palette.memoraGrey[18]}`,
    zIndex: 2,
    marginTop: 2,
    maxHeight: 216,
    background: theme.palette.memoraGrey[0],
    overflowY: "hidden",
  },
  checkbox: {
    padding: 0,
    marginRight: 16,
  },
  divider: {
    position: "absolute",
    inset: "0 1em",
  },
  fullWidth: {
    width: "100%",
  },
  groupContainer: {
    padding: "10px 10px 0",
  },
  hidden: {
    display: "none",
  },
}));
