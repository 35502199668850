import type { LocationDescriptor } from "history";
import { trackButton } from "infra/tracing";
import { useCallback, useMemo } from "react";
import { matchPath, useHistory, useLocation } from "react-router";

import type { MenuItemProps } from "../menus/types";
import { createHookItem } from "./createHookItem";

export type LinkItemRenderProps = Pick<
  MenuItemProps,
  "icon" | "title" | "id"
> & {
  to: LocationDescriptor;
  trackingEvent?: string;
  routeMatchOverride?: string;
};

type GetLinkItemHook<TRequiredData> = (
  data: TRequiredData
) => LinkItemRenderProps | undefined;

/**
 * Creates a menu item that goes to a given location when clicked
 * @param useLinkHook Hook which takes required data and returns information about the menu item. Returns undefined if unauthorized
 */
export function createLinkItem<TRequiredData = {}>(
  useLinkHook: GetLinkItemHook<TRequiredData>
) {
  const useItemHook = (data: TRequiredData) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const linkHookProps = useLinkHook(data);

    const onClick = useCallback(() => {
      if (!linkHookProps) return;
      const { to, trackingEvent } = linkHookProps;
      if (trackingEvent) {
        trackButton(trackingEvent);
      }
      history.push(to);
    }, [history, linkHookProps]);

    const selected = useMemo(() => {
      if (!linkHookProps) return false;
      const { to, routeMatchOverride } = linkHookProps;
      const toPath = typeof to === "string" ? to : to.pathname;
      if (!toPath) return false;

      return !!matchPath(pathname, {
        // checks if a custom matching path is passed
        // shows selected if current path matches custom path (if exists) or the to prop
        path: routeMatchOverride ?? toPath,
        exact: true,
      });
    }, [pathname, linkHookProps]);

    if (!linkHookProps) {
      return undefined;
    }

    const { to, trackingEvent, ...rest } = linkHookProps;

    return {
      onClick,
      selected,
      ...rest,
    };
  };

  return createHookItem(useItemHook);
}
