import { CircularProgress, MenuItem, MenuList } from "@material-ui/core";
import type {
  DropdownOption,
  DropdownOptions,
  GroupDropDownOptions,
} from "@pillpal/api-types";
import type { UIEventHandler } from "react";
import { useCallback, useRef } from "react";
import { useVirtual } from "react-virtual";

import { Body } from "../Body";
import { VirtualizedItem } from "./VirtualizedItem";
import { useVirtualStyles } from "./virtualStyles";

interface VirtualizedListProps {
  listboxProps: {};
  getOptionProps: ({
    option,
    index,
  }: {
    option: DropdownOption<string>;
    index: number;
  }) => {};
  options: DropdownOptions<string>;
  selectedOptions: DropdownOptions<string>;
  optionClicked: (option: DropdownOption<string>) => void;
  handleScroll: UIEventHandler<HTMLUListElement>;
  isMulti?: boolean;
  isLoading?: boolean;
  isSubtitleDate?: boolean;
  isGrouped?: boolean;
  largeItems?: boolean;
  hideEmptyOptions?: boolean;
}

function getMenuHeight(itemCount: number, itemSize: number) {
  const PADDING = 16;

  if (itemCount <= 0) {
    return itemSize + PADDING;
  }

  if (itemCount < 5 && itemCount > 0) {
    return itemCount * itemSize + PADDING;
  }

  return 216;
}

const parseOptions = (
  options: DropdownOptions<string> | GroupDropDownOptions<string>,
  isGrouped: boolean
): DropdownOptions<string> => {
  if (!isGrouped) {
    return options as DropdownOptions<string>;
  }
  const parsedOptions: DropdownOptions<string> = [];
  (options as GroupDropDownOptions<string>).forEach((group) => {
    parsedOptions.push(
      { label: group.group, value: group.group, type: "header" },
      ...group.options
    );
  });
  return parsedOptions;
};

export function VirtualizedMenu({
  listboxProps,
  getOptionProps,
  options: virtualOptions,
  selectedOptions,
  optionClicked,
  handleScroll,
  isMulti,
  isLoading,
  isSubtitleDate,
  isGrouped = false,
  largeItems,
  hideEmptyOptions,
}: VirtualizedListProps) {
  const styles = useVirtualStyles();
  const options = parseOptions(virtualOptions, isGrouped);
  const itemCount = options.length;
  const itemSize = largeItems ? 48 : 40;
  const menuHeight = getMenuHeight(itemCount, itemSize);
  const parentRef = useRef<HTMLUListElement>(null);
  const rowVirtualizer = useVirtual({
    size: itemCount,
    parentRef,
    estimateSize: useCallback(() => itemSize, [itemSize]),
    overscan: 5,
  });

  // If there are no options and we allow adding new options, we hide the empty options container.
  if (hideEmptyOptions) {
    return null;
  }

  return (
    <MenuList
      {...listboxProps}
      ref={parentRef}
      className={styles.virtualParent}
      style={{
        height: `${menuHeight}px`,
      }}
      onScroll={handleScroll}
    >
      {options.length > 0 && (
        <div
          className={styles.virtualList}
          style={{
            height: `${rowVirtualizer.totalSize}px`,
          }}
        >
          {rowVirtualizer.virtualItems.map((virtualRow) => (
            <VirtualizedItem
              key={options[virtualRow.index]?.value}
              virtualRow={virtualRow}
              option={options[virtualRow.index]!}
              selectedOptions={selectedOptions}
              isMulti={isMulti}
              isSubtitleDate={isSubtitleDate}
              optionClicked={optionClicked}
              getOptionProps={getOptionProps}
            />
          ))}
        </div>
      )}

      {options.length === 0 && !isLoading && (
        <MenuItem disabled>
          <Body size="large">No Options</Body>
        </MenuItem>
      )}

      {isLoading && (
        <MenuItem disabled>
          <CircularProgress color="inherit" size="1rem" />
          &nbsp;
          <Body size="large">Loading...</Body>
        </MenuItem>
      )}
    </MenuList>
  );
}
