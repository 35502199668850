import { Divider, withStyles } from "@material-ui/core";

import { ParentMenuItem } from "./ParentMenuItem";
import { TerminalMenuItem } from "./TerminalMenuItem";
import type {
  AnyMenuItemProps,
  DividerItemProps,
  ParentMenuItemProps,
} from "./types";

/**
 * Used in menus to decide if we should have a divider
 */
function isDivider(p: AnyMenuItemProps): p is DividerItemProps {
  return "isDivider" in p;
}

/**
 * used in menus to decide if we should have a flyout
 */
function isParent(p: AnyMenuItemProps): p is ParentMenuItemProps {
  return "items" in p && p.items.length > 0;
}
export type MenuItemProps = {
  item: AnyMenuItemProps;
  onItemClicked?: React.MouseEventHandler;
  tabIndex?: number;
};
/**
 * injected styling onto the Divider to increase margin
 */
const StyledDivider = withStyles(() => ({
  root: {
    marginTop: "8px",
    marginBottom: "8px",
  },
}))(Divider);
/**
 * Menu item that decides between displaying a divider, a flyout, or a menu item based on the item object
 */
export const MenuItem = ({ item, onItemClicked, tabIndex }: MenuItemProps) => {
  if (isDivider(item)) {
    return <StyledDivider role="separator" />;
  }
  if (isParent(item)) {
    return <ParentMenuItem {...item} onItemClicked={onItemClicked} />;
  }
  return (
    <TerminalMenuItem
      {...item}
      onClick={(e) => {
        if (item.onClick) item.onClick(e);
        if (onItemClicked) onItemClicked(e);
      }}
      tabIndex={tabIndex}
    />
  );
};
