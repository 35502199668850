import type { AccordionDetailsProps as MuiAccordionDetailsProps } from "@material-ui/core";
import { AccordionDetails as MuiAccordionDetails } from "@material-ui/core";
import withStyles from "@material-ui/styles/withStyles";

import { useEnsureNewTheme } from "./theme/providers";

const StyledAccordionDetails = withStyles(() => ({
  root: {
    padding: "16px 24px",
    borderBottom: "none",
  },
}))(MuiAccordionDetails);

export function AccordionDetails({
  children,
  ...rest
}: MuiAccordionDetailsProps) {
  useEnsureNewTheme();
  const composedProps = {
    ...rest,
  };
  return (
    <StyledAccordionDetails {...composedProps}>
      {children}
    </StyledAccordionDetails>
  );
}

AccordionDetails.displayName = "AccordianDetails";
