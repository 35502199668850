import type { BreadcrumbsProps as MUIBreadcrumbProps } from "@material-ui/core";
import {
  Breadcrumbs as MUIBreadcrumbs,
  createStyles,
  makeStyles,
  withStyles,
} from "@material-ui/core";

import { H4, H5 } from "./Headings";
import type { LinkProps } from "./MuiTypography";
import { Link } from "./MuiTypography";

export type BreadcrumbListProps = Omit<
  MUIBreadcrumbProps,
  "children" | "separator"
> & {
  breadcrumbs: BreadcrumbItemProps;
};
export type BreadcrumbItemProps = Array<
  LinkProps & {
    label: string;
    icon?: React.ReactNode;
    iconPlacement?: "start" | "end";
  }
>;
const UnstyledBreadcrumbs = ({
  breadcrumbs: passedBreadcrumbs,
  ...breadcrumbProps
}: BreadcrumbListProps) => {
  const classes = useStyles();

  if (passedBreadcrumbs.length === 0) {
    return null;
  }

  const breadcrumbs = [...passedBreadcrumbs];
  const end = breadcrumbs.pop()!;

  return (
    <MUIBreadcrumbs
      aria-label="breadcrumb"
      separator={<H4>/</H4>}
      {...breadcrumbProps}
    >
      {breadcrumbs.map(({ label, icon, iconPlacement, ...linkProps }) => (
        <Link
          color="textSecondary"
          variant="bodyMedium"
          key={label}
          {...linkProps}
        >
          <div className={classes.breadcrumbItem}>
            {icon && iconPlacement === "start" && (
              <div className={classes.icon}>{icon}</div>
            )}
            {label}
            {icon && iconPlacement === "end" && <div>{icon}</div>}
          </div>
        </Link>
      ))}
      <H5 color="textPrimary">
        <div className={classes.breadcrumbItem}>
          {end.icon && end.iconPlacement === "start" && (
            <div className={classes.icon}>{end.icon}</div>
          )}
          {end.label}
          {end.icon && end.iconPlacement === "end" && (
            <div className={classes.icon}>{end.icon}</div>
          )}
        </div>
      </H5>
    </MUIBreadcrumbs>
  );
};

export const Breadcrumbs = withStyles((theme) => ({
  root: {
    "& li:nth-last-child(2)": {
      color: theme.palette.memoraGrey["100"],
    },
    "& li > svg": {
      width: "11px",
      height: "17px",
    },
  },
  li: {
    "& .MuiLink-root:hover": {
      color: theme.palette.memoraGrey["100"],
      textDecoration: "underline",
    },
  },
  separator: {
    marginRight: "6px",
  },
}))(UnstyledBreadcrumbs);

const useStyles = makeStyles(() =>
  createStyles({
    breadcrumbItem: {
      display: "flex",
      alignItems: "center",
      whiteSpace: "normal",
      wordBreak: "break-word",
    },
    icon: {
      marginRight: 4,
      marginLeft: 4,
    },
  })
);
