import type { FormControlProps as MUIFormControlProps } from "@material-ui/core";
import {
  FormControl as MUIFormControl,
  InputBase,
  Select,
  withStyles,
} from "@material-ui/core";
import type { SvgIconProps } from "@material-ui/core/SvgIcon";
import SvgIcon from "@material-ui/core/SvgIcon";

const StyledSelectInputBase = withStyles((theme) => ({
  error: {
    border: `1px solid ${theme.palette.red[50]} !important`,
    borderRadius: 4,
    color: `${theme.palette.red[50]} !important`,
    outlineColor: `${theme.palette.red[50]}`,
    outlineWidth: 2,
    outlineStyle: "solid",
  },
  input: {
    ...theme.typography.labelMedium,
    borderRadius: 4,
    backgroundColor: theme.palette.memoraGrey[10],
    borderColor: theme.palette.memoraGrey[18],
    borderWidth: 1,
    borderStyle: "solid",
    color: theme.palette.memoraGrey[100],
    "&:focus": {
      borderRadius: 4,
      outlineColor: `${theme.palette.memoraGrey[28]} !important`,
      backgroundColor: theme.palette.memoraGrey[10],
    },
    "&:hover": {
      outlineColor: `${theme.palette.memoraGrey[28]}`,
      outlineWidth: 2,
      outlineStyle: "solid",
    },
  },
}))(InputBase);

const StyledSelectFormControl = withStyles((theme) => ({
  root: (props: MUIFormControlProps) => ({
    minWidth: 280,

    "&.flex": {
      flex: 1,
      minWidth: "auto",
      maxWidth: "100%",
    },

    "& .customIconBorder": {
      position: "absolute",
      width: props.size === "medium" ? 39 : 31,
      right: 1,
      backgroundColor: theme.palette.memoraGrey[12],
      borderRadius: "0 4px 4px 0",
      overflow: "hidden",
      borderLeft: `1px solid ${theme.palette.memoraGrey[18]}`,
      pointerEvents: "none",
    },

    "& .customIconBorder > svg": {
      right: props.size === "medium" ? 7 : 3,
    },

    "& label": {
      maxWidth: "calc(100% - 37px)",
    },

    "&:hover": {
      "& label": {
        color: theme.palette.memoraGrey[100],
      },
    },
    "&:focus-within label": {
      color: `${theme.palette.memoraGrey[55]} !important`,
    },
    "& label.Mui-error": {
      color: theme.palette.memoraGrey[55],
    },
  }),
}))(MUIFormControl);

const StyledSelect = withStyles((theme) => ({
  root: {
    '&[aria-expanded="true"]': {
      fontSize: 14,
      backgroundColor: theme.palette.navy[68],
      color: theme.palette.memoraGrey[0],
      border: `2px solid ${theme.palette.navy[68]}`,
    },
    '&[aria-expanded="true"] ~ .customIconBorder': {
      border: "none",
      background: "none",
    },
    '&[aria-haspopup="listbox"]': {
      display: "flex",
      gap: 16,
    },
  },
  iconOpen: {},
  disabled: {},
}))(Select);

const CustomSvgIconArrow = (props: SvgIconProps) => (
  <div className="customIconBorder">
    <SvgIcon {...props}>
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </SvgIcon>
  </div>
);

export {
  CustomSvgIconArrow,
  StyledSelect,
  StyledSelectFormControl,
  StyledSelectInputBase,
};
