import type { MenuProps } from "@material-ui/core";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";

import { TooltipIconButton } from "./TooltipIconButton";
import type { BaseMenuImplProps } from "./types";

export type DropdownMenuProps = BaseMenuImplProps & {
  title?: string;
  icon: React.ReactNode;
  MenuProps?: Partial<MenuProps>;
};

export function DropdownMenu({
  items,
  title,
  icon,
  MenuProps: passedMenuProps,
}: DropdownMenuProps) {
  return (
    <PopupState variant="popover">
      {(popupState) => (
        <>
          <TooltipIconButton
            title={title}
            icon={icon}
            {...bindTrigger(popupState)}
          />
          <Menu {...passedMenuProps} {...bindMenu(popupState)}>
            {items.map((item) => {
              const {
                title: itemTitle,
                icon: itemIcon,
                onClick,
                id,
                selected,
                disabled = false,
              } = item;
              return (
                <MenuItem
                  selected={selected}
                  key={itemTitle}
                  onClick={(e) => {
                    onClick(e);
                    popupState.close();
                  }}
                  id={id}
                  disabled={disabled}
                >
                  {itemIcon && <ListItemIcon>{itemIcon}</ListItemIcon>}
                  <ListItemText>{itemTitle}</ListItemText>
                </MenuItem>
              );
            })}
          </Menu>
        </>
      )}
    </PopupState>
  );
}
