import type { ButtonProps } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { useTranslation } from "react-i18next";

import { withNewTheme } from "../theme/providers";
import { BaseActionButtons } from "./BaseActionButtons";
import { BaseDialog } from "./BaseDialog";

export interface IConfirmDialogProps {
  isOpen: boolean;
  confirmText?: string;
  cancelTitle?: string;
  onConfirm(): void;
  onClose(): void;
  title: string;
  content: string;
  destructive?: boolean;
  cancelButtonProps?: Partial<ButtonProps>;
  submitButtonProps?: Partial<ButtonProps>;
  useHtmlContent?: boolean;
  isConfirming?: boolean;
}

export const ConfirmDialog = withNewTheme((props: IConfirmDialogProps) => {
  const {
    title,
    content,
    confirmText,
    cancelTitle = "",
    isOpen,
    onConfirm,
    onClose,
    destructive,
    submitButtonProps,
    cancelButtonProps,
    useHtmlContent = false,
    isConfirming = false,
  } = props;

  const { t } = useTranslation("core");

  const confirmTextDefaulted = confirmText || t("confirmDialog.confirmText");

  return (
    <BaseDialog isOpen={isOpen} onClose={onClose} title={title}>
      <DialogContent>
        <DialogContentText>
          {useHtmlContent ? (
            <Typography
              component="div"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          ) : (
            content
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <BaseActionButtons
          onSubmit={onConfirm}
          submitTitle={confirmTextDefaulted}
          onCancel={onClose}
          destructive={destructive}
          cancelTitle={cancelTitle}
          cancelButtonProps={cancelButtonProps}
          submitButtonProps={submitButtonProps}
          submitDisabled={isConfirming}
          isLoading={isConfirming}
        />
      </DialogActions>
    </BaseDialog>
  );
});
