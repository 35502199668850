import { useMediaQuery, useTheme } from "@material-ui/core";
import type { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { hocify } from "helpers/hoc";

export function useIsMobile() {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("sm"));
}

export function useIsBreakpoint(breakpoint: Breakpoint | number) {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(breakpoint));
}
/**
 * TODO: We can remove this when other class based component doesn't require it.
 */
export const withIsMobile = hocify(useIsMobile, "isMobile");
