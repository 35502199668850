import type { ChipProps as MUIChipProps } from "@material-ui/core";
import { Chip as MUIChip, withStyles } from "@material-ui/core";
import { CloseOutlined } from "@material-ui/icons";
import { Body } from "components/system/Body";

import { useEnsureNewTheme } from "./theme";

export const StyledChip = withStyles((theme) => ({
  root: {
    color: theme.palette.blue["50"],
    backgroundColor: theme.palette.blue["10"],
    border: "1px solid",
    borderColor: theme.palette.blue["15"],
    borderRadius: "6px",
    "&:hover": {
      borderColor: theme.palette.blue["41"],
      color: theme.palette.blue["55"],
      backgroundColor: theme.palette.blue["10"],
    },
  },
  deleteIconSmall: {
    color: theme.palette.blue["50"],
    marginRight: "4px!important",
    width: 12,
    height: 12,
  },
  iconSmall: {
    color: theme.palette.blue["50"],
    marginLeft: "4px!important",
    width: 12,
    height: 12,
  },
}))(MUIChip);
export type ChipProps = Omit<
  MUIChipProps,
  "variant" | "deleteIcon" | "color" | "clickable" | "size"
>;
export const Chip = ({ label, ...props }: ChipProps) => {
  useEnsureNewTheme();
  return (
    <StyledChip
      size="small"
      deleteIcon={
        <CloseOutlined onMouseDown={(event) => event.stopPropagation()} />
      }
      {...props}
      label={<Body size="small">{label}</Body>}
    />
  );
};
