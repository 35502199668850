import { ErrorBoundary } from "infra/tracing";
import { TABLE_COLUMN_CRASH } from "logging/constants";

import { ColumnError } from "./ColumnError";

export function withColumnErrorBoundary<T>(
  Component: React.ComponentType<T>,
  name: string
) {
  const WrappedComponent: React.FC<T> = (props) => (
    <ErrorBoundary
      componentName={name}
      crashTelemetryKey={TABLE_COLUMN_CRASH}
      fallbackComponent={ColumnError}
    >
      <Component {...props} />
    </ErrorBoundary>
  );
  WrappedComponent.displayName = `withColumnErrorBoundary(${name})`;
  return WrappedComponent;
}
