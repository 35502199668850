/* eslint-disable no-param-reassign */
import { useCallback } from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { shallow } from "zustand/shallow";

import { useBeaconSelector } from "./beaconSelectors";

export type ActivityInfo = {
  path: string;
  patientId?: string;
};
type InternalActivityInfo = ActivityInfo & { id: number };

interface ActivityStack {
  id: number;
  stack: InternalActivityInfo[];
  push(info: InternalActivityInfo): void;
  close(id: number): void;
}

const useActivityStack = create<ActivityStack>()(
  immer((set) => ({
    id: 0,
    stack: [],
    push: (info) => {
      set((state) => {
        state.stack.push(info);
        state.id += 1;
      });
    },
    close: (idToDelete) => {
      set((state) => {
        state.stack = state.stack.filter(({ id }) => id !== idToDelete);
      });
    },
  }))
);

// exported for testing
// eslint-disable-next-line @typescript-eslint/naming-convention
export const UNSAFE_useActivityStack = useActivityStack;

/**
 * Call the function returned by this hook to publish an activity as "current",
 * which will cause it to get pushed into to the activity becaon feed. Calling
 * the function returns another function which should be called when the activity
 * has been finished to "unpublish" it.
 */
export const usePublishActivity = () =>
  useCallback((info: ActivityInfo) => {
    const { push, close, id } = useActivityStack.getState();
    push({ ...info, id });
    return () => {
      close(id);
    };
  }, []);

/**
 * Returns the currently active activity. Relies on router state to publish the
 * page that is active and overrides with manually published activity (using
 * usePublishActivity) if it exists.
 */
export const useCurrentActivity = () => {
  const beaconActivity = useBeaconSelector();
  const stackActivity = useActivityStack((state) => {
    if (state.stack.length === 0) {
      return undefined;
    }
    // omit the ID
    const { id, ...rest } = state.stack[state.stack.length - 1]!;
    return rest;
  }, shallow);

  return stackActivity ?? beaconActivity;
};
