import ScopedCssBaseline from "@material-ui/core/ScopedCssBaseline";
import { ThemeProvider } from "@material-ui/styles";
import * as React from "react";

import { useDebugState } from "./debug";
import { theme } from "./theme";

type NewThemeProps = {
  fill?: boolean;
  noBackground?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
  children?: React.ReactNode;
};

export const ScopedNewThemeProvider = ({
  children,
  fill,
  noBackground,
  fullHeight,
  fullWidth,
}: NewThemeProps) => {
  const showOutline = useDebugState((s) => s.showOutline);
  return (
    <ThemeProvider theme={theme}>
      <NewThemeContext.Provider value>
        <ScopedCssBaseline
          style={{
            width: fullWidth ? "100%" : undefined,
            minHeight: fill ? "100%" : undefined,
            height: fullHeight ? "100%" : undefined,
            backgroundColor: noBackground ? "transparent" : undefined,
            ...(showOutline && {
              outline: "solid 2px red",
              outlineOffset: "-1px",
            }),
          }}
        >
          {children}
        </ScopedCssBaseline>
      </NewThemeContext.Provider>
    </ThemeProvider>
  );
};

const NewThemeContext = React.createContext(false);

export function useIsNewTheme() {
  return React.useContext(NewThemeContext);
}

export function useEnsureNewTheme() {
  const isNewTheme = useIsNewTheme();
  React.useEffect(() => {
    if (!isNewTheme) {
      throw new Error(
        "Component expected to be used in the context of the new theme provider. To use this component, you'll need to ensure the page has been migrated to the new design system, otherwise fallback to using MUI components directly"
      );
    }
  }, [isNewTheme]);
}

export function withEnsureNewTheme<T extends {}>(
  ComponentToWrap: React.ComponentType<T>
) {
  const WrappedComponent = React.forwardRef(
    (props: T, ref?: React.ForwardedRef<unknown>) => {
      useEnsureNewTheme();
      return <ComponentToWrap {...props} ref={ref} />;
    }
  );

  const innerDisplayName =
    ComponentToWrap.displayName || ComponentToWrap.name || "Component";
  WrappedComponent.displayName = `withEnsureNewTheme(${innerDisplayName})`;

  return WrappedComponent;
}

/**
 * HOC to new theme provided if necessary
 */
export function withNewTheme<T extends {}>(
  ComponentToWrap: React.ComponentType<T>,
  themeProps?: NewThemeProps
) {
  const WrappedComponent = (props: T) => {
    const isNewTheme = useIsNewTheme();
    if (isNewTheme) {
      return <ComponentToWrap {...props} />;
    }
    return (
      <ScopedNewThemeProvider {...themeProps}>
        <ComponentToWrap {...props} />
      </ScopedNewThemeProvider>
    );
  };

  const innerDisplayName =
    ComponentToWrap.displayName || ComponentToWrap.name || "Component";
  WrappedComponent.displayName = `withNewTheme(${innerDisplayName})`;

  return WrappedComponent;
}
