import { Box, makeStyles } from "@material-ui/core";

export const DrawerContent: React.FC = ({ children }) => {
  const classes = useStyles();
  return (
    <Box className={classes.drawerBody}>
      {children}
      {/* We need this to avoid the AriaDatePicker issues with useFocusVisible. */}
      <div
        id="portal-container"
        style={{ zIndex: 100005, position: "fixed" }}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  drawerBody: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflowY: "scroll",
  },
}));
