/** Prefix for URL query parameters to change a feature flag. */
const featureQueryParamPrefix = `feature.`;

const stringToBool: Record<string, boolean> = {
  true: true,
  false: false,
};

/**
 * given a string like ?feature.flagName1=true&feature.flagName2=false
 * returns a map with flagName1=>true, etc
 * @param searchString query parameter string
 */
export function getQueryFeatureFlags() {
  // environment configured values
  const result = new Map<string, boolean>();
  const query = new URLSearchParams(window.location.search);
  for (const [key, val] of query.entries()) {
    if (key.startsWith(featureQueryParamPrefix)) {
      const flagName = key.replace(featureQueryParamPrefix, "");
      const booleanVal = stringToBool[val];
      if (booleanVal !== undefined) {
        result.set(flagName, booleanVal);
      }
    }
  }

  return result;
}
