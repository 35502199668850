import { createContext, useContext, useMemo } from "react";

import type { MenuSize } from "./types";

const NestedMenuContext = createContext<
  | {
      size?: MenuSize;
    }
  | undefined
>(undefined);

export const NestedMenuContextProvider = ({
  size,
  children,
}: React.PropsWithChildren<{ size?: MenuSize }>) => {
  const data = useMemo(
    () => ({
      size,
    }),
    [size]
  );

  return (
    <NestedMenuContext.Provider value={data}>
      {children}
    </NestedMenuContext.Provider>
  );
};

export const useNestedMenuContext = (fromProp?: MenuSize) => {
  const nestedData = useContext(NestedMenuContext);
  return useMemo(
    () => ({
      isNested: nestedData !== undefined,
      size: nestedData?.size || fromProp,
    }),
    [nestedData, fromProp]
  );
};
