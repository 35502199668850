import { useEffect } from "react";

export const useStoreEmbedKey = () => {
  const { search } = window.location;

  useEffect(() => {
    const embedKey = new URLSearchParams(search).get("embed");

    if (embedKey) {
      setEmbedKey(embedKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

const EMBED_KEY = "embedKey";

const setEmbedKey = (embedKey: string) => {
  sessionStorage.setItem(EMBED_KEY, embedKey);
};

export const getEmbedKey = () => sessionStorage.getItem(EMBED_KEY);
