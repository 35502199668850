import CachedOutlinedIcon from "@material-ui/icons/CachedOutlined";
import { Button } from "components/system/Button";
import { useCallback, useMemo } from "react";
import type { TableInstance } from "react-table";

export type TableResetDeps<T extends object> = Pick<
  TableInstance<T>,
  "state" | "setSortBy" | "setAllFilters" | "gotoPage"
>;

export function useReset<T extends object>(table: TableResetDeps<T>) {
  const { state, setSortBy, setAllFilters, gotoPage } = table;
  const { sortBy, filters, pageIndex } = state;

  const canReset = useMemo(
    () => sortBy.length > 0 || filters.length > 0 || pageIndex !== 0,
    [sortBy, filters, pageIndex]
  );
  const onReset = useCallback(() => {
    setSortBy([]);
    setAllFilters([]);
    gotoPage(0);
  }, [setSortBy, setAllFilters, gotoPage]);

  return useMemo(
    () => ({
      canReset,
      onReset,
    }),
    [canReset, onReset]
  );
}

export interface ResetButtonCellProps<T extends object> {
  table: TableResetDeps<T>;
}

export function ResetButtonCell<T extends object>(
  props: ResetButtonCellProps<T>
) {
  const { table } = props;

  const { canReset, onReset } = useReset(table);

  return (
    <Button
      disabled={!canReset}
      startIcon={<CachedOutlinedIcon />}
      variant="tertiary"
      onClick={onReset}
      size="small"
    >
      Reset
    </Button>
  );
}
