import { Box } from "@material-ui/core";
import { RoleName } from "@pillpal/api-types";
import { Redirect, Route, Switch } from "react-router-dom";

import { EnvDesignationsRoute } from "./components/EnvDesignations";
import { DialogHost, useCaptureDebugState } from "./components/system";
import { useStoreEmbedKey } from "./containers/app/embedMode/useStoreEmbedKey";
import asyncComponent from "./helpers/asyncFunction";
import { isHostSupported } from "./helpers/environment";
import { useFlushClientLogs } from "./hooks/useHandleClientBeacon";
import { useHandleWindowEvent } from "./hooks/useHandleWindowEvent";
import useHeapIntegration from "./hooks/useHeapIntegration";
import {
  RestrictedRoute,
  RestrictedRouteWithProvider,
} from "./infra/auth/RestrictedRoute";
import { useAuthMissingErrorHandling } from "./infra/auth/useAuthMissingErrorHandling";

const DashboardContainer = asyncComponent(
  () => import("./containers/app/DashboardContainer")
);

// Lazy-load FlaggerV2 using asyncComponent. The `.then()` method adapts the named export to a default export,
// aligning with asyncComponent's expectation for a module with a default export. This is necessary because
// FlaggerV2 is exported as a named export from its module, unlike other components that are exported as default.
const FlaggerV2 = asyncComponent(() =>
  import("./containers/app/FlaggerV2").then((module) => ({
    default: module.FlaggerV2,
  }))
);

const UpdatePasswordPage = asyncComponent(
  () => import("./containers/updatepassword")
);
const MFAPage = asyncComponent(() => import("./containers/mfa"));
const ToolsRouter = asyncComponent(
  () => import("./containers/tools/ToolsRouter")
);
const PatientSurveyPage = asyncComponent(
  () => import("./containers/patientSurveyQuestionFlow/SurveyView")
);
const PatientMessagePage = asyncComponent(
  () => import("./containers/patientMessage/PatientMessagePage")
);
const PHSAPCRSignupPage = asyncComponent(
  () => import("./containers/phsa_pcr/Signup")
);
const NotFoundPage = asyncComponent(
  () => import("./components/PageNotFound/PageNotFound")
);

const SmartLaunchFailurePage = asyncComponent(
  () => import("./containers/smart_launch/SmartLaunchFailure")
);

// Top level router. Use RestrictedRoute to ensure user is logged in. Some routes redirect to other routers
export const AppRouter = () => {
  useHandleWindowEvent();
  useAuthMissingErrorHandling();
  useStoreEmbedKey();
  useCaptureDebugState();
  useFlushClientLogs();
  useHeapIntegration();

  return (
    <DialogHost>
      <Box
        display="flex"
        height="100vh"
        overflow="hidden"
        flexDirection="column"
      >
        <Box flex="1 1 auto" overflow="auto">
          <Switch>
            <RestrictedRouteWithProvider
              path="/dashboard/"
              component={DashboardContainer}
            />
            <RestrictedRoute path="/tools/flagger2/" component={FlaggerV2} />
            <RestrictedRouteWithProvider
              path="/update_password/"
              component={UpdatePasswordPage}
            />
            <RestrictedRoute path="/mfa" component={MFAPage} />
            <RestrictedRoute
              path="/tools/"
              component={ToolsRouter}
              requiredRole={RoleName.SUPERADMIN}
            />
            <Route
              path="/patient_survey/:patientId/:surveyId"
              component={PatientSurveyPage}
            />
            <Route
              path="/patients/:patientId/messages/:messageId"
              component={PatientMessagePage}
            />
            {isHostSupported(["sandbox", "test", "ca.secure"]) && (
              <Route
                path="/signup/phsa_pcr/:formtype/:clinic"
                component={PHSAPCRSignupPage}
              />
            )}
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route
              path="/smartLaunchFailure"
              component={SmartLaunchFailurePage}
            />
            <Route component={NotFoundPage} />
          </Switch>
        </Box>
      </Box>
      <Route path="/" component={EnvDesignationsRoute} />
    </DialogHost>
  );
};
