import type { FormHelperTextProps as MuiFormHelperTextProps } from "@material-ui/core";
import {
  FormHelperText as MuiFormHelperText,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import classNames from "classnames";
import { Body } from "components/system/Body";
import { ICON_DEF } from "components/system/tokens/icons";

const FormHelperTextStyled = withStyles((theme) => ({
  root: {
    "&$error": {
      color: theme.palette.red[55],
    },
    margin: 0,
    minWidth: "25%",
  },
  error: {},
}))(MuiFormHelperText);

const BodyStyled = withStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
}))(Body);

const sizeToIconClassname: {
  [key in "medium" | "large"]: "mediumIcon" | "largeIcon";
} = {
  medium: "mediumIcon",
  large: "largeIcon",
};
const useStyles = makeStyles((theme) => ({
  medium: {
    ...theme.typography.standardMedium,
  },
  small: {
    ...theme.typography.standardSmall,
  },
  success: {
    color: theme.palette.green[55],
  },
  mediumIcon: {
    fontSize: ICON_DEF.medium.fontSize,
    marginRight: 4,
  },
  largeIcon: {
    fontSize: ICON_DEF.medium.fontSize,
    marginRight: 6,
  },
}));

export type FormHelperTextProps = MuiFormHelperTextProps & {
  size?: "medium" | "large";
  success?: boolean;
};

export const FormHelperText = ({
  children,
  size = "medium",
  success,
  ...muiHelperTextProps
}: FormHelperTextProps) => {
  const classes = useStyles();

  const useSuccess = success && !muiHelperTextProps.error;

  return (
    <FormHelperTextStyled {...muiHelperTextProps}>
      <BodyStyled
        className={classNames({
          // Want to give error styling priority
          [classes.success]: useSuccess,
        })}
        size={size === "medium" ? "small" : "medium"}
      >
        {useSuccess ? (
          <CheckCircleOutlinedIcon
            className={classNames({
              [classes[sizeToIconClassname[size]]]: true,
            })}
          />
        ) : (
          <InfoOutlinedIcon
            className={classNames({
              [classes[sizeToIconClassname[size]]]: true,
            })}
          />
        )}
        {children}
      </BodyStyled>
    </FormHelperTextStyled>
  );
};
FormHelperText.displayName = "FormHelperText";
