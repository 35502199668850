import { QueryClient } from "@tanstack/react-query";

/** 1 second timer */
export const QUERY_TIMER_VERY_SHORT = 1000;
/** 5 second timer */
export const QUERY_TIMER_SHORT = 5000;
/** 10 second timer */
export const QUERY_TIMER_MEDIUM = 10000;
/** 30 second timer */
export const QUERY_TIMER_LONG = 30000;
/** 60 second timer */
export const QUERY_TIMER_EXTRA_LONG = 60000;
export const QUERY_TIMER_NEVER = Number.POSITIVE_INFINITY;

// Create a client
// exporting temporarily for access within the sagas
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false, // stop retrying on failed queries
      refetchOnWindowFocus: false, // updated to prevent refetch on window focus
      staleTime: QUERY_TIMER_MEDIUM, // default that queries are not considered stale for 1 min
    },
  },
});
