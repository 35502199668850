import type {
  LinkProps as MuiLinkProps,
  TypographyVariant as MuiTypographyVariant,
} from "@material-ui/core";
import { Link as MuiLink, withStyles } from "@material-ui/core";
import classNames from "classnames";
import * as React from "react";
import { useCallback } from "react";
import type { LinkProps as RouterLinkProps } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import type { MHDSVariant } from "./theme/mhdsTypography";
import { useEnsureNewTheme } from "./theme/providers";

type MemoraTypographyVariant =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "bodyLarge"
  | "bodyMedium"
  | "bodySmall"
  | "ctaLarge"
  | "ctaMedium"
  | "ctaSmall";

export type Size = "large" | "medium" | "small";
export type LabelSize = "large" | "medium";

const SizeToBodyVariant: { [key in Size]: MuiTypographyVariant } = {
  large: "body1",
  medium: "body2",
  small: "caption",
};

const SizeToCTAVariant: { [key in Size]: MuiTypographyVariant } = {
  large: "h4",
  medium: "h5",
  small: "h6",
};

export const MemoraToMHDSVariant: {
  [key in MemoraTypographyVariant]: MHDSVariant;
} = {
  h1: "displayEmphasis",
  h2: "titleLarge",
  h3: "titleSmall",
  h4: "emphasisLarge",
  h5: "emphasisMedium",
  h6: "emphasisSmall",
  bodyLarge: "standardLarge",
  bodyMedium: "standardMedium",
  bodySmall: "standardSmall",
  ctaLarge: "emphasisLarge",
  ctaMedium: "emphasisMedium",
  ctaSmall: "emphasisSmall",
};

export const SizeToFormLabelVariant: {
  [key in LabelSize]: MuiTypographyVariant;
} = {
  large: "h5",
  medium: "h6",
};

function getMuiTypographyVariant(
  variant: MemoraTypographyVariant | "inherit"
): MuiTypographyVariant {
  if (variant.startsWith("h")) {
    return variant as MuiTypographyVariant;
  }
  if (variant.startsWith("cta")) {
    const cta = variant
      .slice(3)
      .toLocaleLowerCase() as keyof typeof SizeToCTAVariant;
    return SizeToCTAVariant[cta];
  }
  if (variant.startsWith("body")) {
    const body = variant
      .slice(4)
      .toLocaleLowerCase() as keyof typeof SizeToBodyVariant;
    return SizeToBodyVariant[body];
  }
  return "body1"; // fallback if error;
}

export type LinkProps = Omit<CoreLinkProps, "navigate"> & {
  to?: RouterLinkProps["to"];
  replace?: RouterLinkProps["replace"];
};

export function Link({ to, replace, ...rest }: LinkProps) {
  // if they pass "to" we're going to use the router link to wrap
  // the core link so we get navigation with onclick as expected

  return to ? (
    <RouterLink component={CoreLink} to={to} replace={replace} {...rest} />
  ) : (
    <CoreLink {...rest} />
  );
}

type CoreLinkProps = Omit<MuiLinkProps, "variant"> & {
  variant?: MemoraTypographyVariant | "inherit";
  navigate?(): void; // passed by RouterLink to inner component
};

type ClickHandler = Required<MuiLinkProps>["onClick"];
const LEFT_CLICK_BUTTON = 0;
const TARGET_SELF = "_self";

function isModifiedEvent(event: React.MouseEvent<HTMLElement, MouseEvent>) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

function CoreLink({
  variant = "ctaLarge",
  navigate,
  onClick: propsOnClick,
  ...rest
}: CoreLinkProps) {
  useEnsureNewTheme();

  // modified from LinkAnchor in react-router-dom.
  const handleNavigate = useCallback<ClickHandler>(
    (event) => {
      if (!navigate) {
        return;
      }

      if (
        !event.defaultPrevented && // onClick prevented default
        event.button === LEFT_CLICK_BUTTON && // ignore everything but left clicks
        (!rest.target || rest.target === TARGET_SELF) && // let browser handle "target=_blank" etc.
        !isModifiedEvent(event) // ignore clicks with modifier keys
      ) {
        event.preventDefault();
        navigate();
      }
    },
    [rest.target, navigate]
  );

  const onClick = useCallback<ClickHandler>(
    (event) => {
      if (propsOnClick) {
        try {
          propsOnClick(event);
        } catch (error) {
          event.preventDefault();
          throw error;
        }
      }

      handleNavigate(event);
    },
    [handleNavigate, propsOnClick]
  );

  // map props to MUI core props
  const props: MuiLinkProps = {
    ...rest,
    variant: getMuiTypographyVariant(variant),
    className: classNames({
      inherit: variant === "inherit",
    }),
    onClick,
  };

  return <MuiLinkStyled {...props} />;
}

const MuiLinkStyled = withStyles({
  root: {
    "&.inherit": {
      fontSize: "inherit",
      "&:not(:hover):not(:focus-visible)": {
        textDecoration: "underline",
        color: "inherit",
      },
    },
  },
})(MuiLink);
