import { RoleName } from "@pillpal/api-types";
import { useAuthorizedUser } from "containers/auth/hooks/useAuthorizedUser";
import { useLoadPanelData } from "containers/auth/hooks/useLoadPanelData";
import SessionTimeout from "containers/SessionTimeout";
import { makeHOCDisplayName } from "helpers/hoc";
import { useEffect } from "react";

import { useUserRole } from "./useUserRole";

/**
 * HOC to ensure auth is there before showing it
 */
export function withAuthRequired<T extends JSX.IntrinsicAttributes>(
  ComponentToWrap: React.ComponentType<T>,
  role: Parameters<typeof useUserRole>[0] = RoleName.ANYONE,
  preloadProvider: boolean = false
) {
  const WrappedComponent = (props: T) => {
    const {
      isLoading,
      isError,
      isSuccess,
      hasAccess,
      showTimeout,
      requestAuth,
    } = useAuthorizedUser();
    const authLoaded = (isError || isSuccess) && !isLoading;

    const { data: panelData } = useLoadPanelData(preloadProvider);
    const hasProviderIfRequired = !preloadProvider || panelData;

    useEffect(() => {
      if (!authLoaded) {
        requestAuth();
      }
    }, [authLoaded, requestAuth]);

    const hasRole = useUserRole(role);

    if (showTimeout) {
      return <SessionTimeout />;
    }
    if (hasAccess && hasRole && hasProviderIfRequired) {
      return <ComponentToWrap {...props} />;
    }
    return <div data-testid="placeholder" />;
  };

  WrappedComponent.displayName = makeHOCDisplayName(
    "withAuthRequired",
    ComponentToWrap
  );
  return WrappedComponent;
}
