import { ArrowForwardIosOutlined } from "@material-ui/icons";
import PopupState, { bindHover, bindMenu } from "material-ui-popup-state";

import { MenuWithItems } from "../Menu";
import { TerminalMenuItem } from "./TerminalMenuItem";
import type { ParentMenuItemProps } from "./types";

type ParentMenuItemInternalProps = ParentMenuItemProps & {
  onItemClicked?: React.MouseEventHandler;
};
/**
 * creates a sub menu with the menu item being the on hover trigger to open
 */
export const ParentMenuItem = ({
  items,
  onItemClicked,
  ...rest
}: ParentMenuItemInternalProps) => (
  <PopupState variant="popover">
    {(popupState) => (
      <div {...bindHover(popupState)}>
        <TerminalMenuItem
          rightIcon={
            <ArrowForwardIosOutlined
              fontSize="small"
              style={{ marginLeft: "auto" }}
            />
          }
          {...rest}
        />

        <MenuWithItems
          items={items}
          {...bindMenu(popupState)}
          onItemClicked={(e) => {
            popupState.close();
            if (onItemClicked) onItemClicked(e);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
        />
      </div>
    )}
  </PopupState>
);
