import type { DialogProps } from "@material-ui/core";
import {
  Dialog,
  IconButton as MuiIconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import classNames from "classnames";
import { H3 } from "components/system/Headings";
import { IconButton } from "components/system/IconButton";
import { useCallback } from "react";
import Draggable from "react-draggable";

import { useDebugState } from "../theme/debug";
import { useIsNewTheme } from "../theme/providers";
import { theme } from "../theme/theme";
import { DialogTitle } from "./wrapped";

export interface IBaseDialogProps {
  isOpen: boolean;
  onClose(): void;
  title?: string;
  secondaryTitle?: string;
  dialogProps?: Partial<DialogProps>;
  children: React.ReactNode;
  "data-testid"?: string;
  isDraggable?: boolean;
}

const useDialogStyles = makeStyles(() => ({
  headerRoot: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  secondaryTitle: {
    display: "inline-block",
    color: theme.palette.memoraGrey[55],
    borderLeft: `2px solid ${theme.palette.memoraGrey[28]}`,
    paddingLeft: 14,
    marginLeft: 14,
  },
  closeButton: {
    padding: 0,
  },
  content: {},
  draggable: {
    cursor: "move",
  },
}));

export const BaseDialog = ({
  isOpen,
  title,
  secondaryTitle,
  dialogProps,
  onClose,
  children,
  isDraggable,
  "data-testid": dataTestId,
}: IBaseDialogProps) => {
  const isNewTheme = useIsNewTheme();
  const classes = useDialogStyles();
  const showOutlineInNewTheme = useDebugState((s) => s.showOutline);
  const showOutline = isNewTheme && showOutlineInNewTheme;

  const onCloseButtonClicked = useCallback(() => onClose(), [onClose]);
  const onDialogClose = useCallback<Required<DialogProps>["onClose"]>(
    (_event, reason) => {
      if (reason !== "backdropClick") {
        onClose();
      }
    },
    [onClose]
  );

  if (!isOpen) {
    return null;
  }

  return (
    <DialogWrapper isDraggable={isDraggable}>
      <Dialog
        open={isOpen}
        onClose={onDialogClose}
        hideBackdrop={isDraggable}
        data-testid={dataTestId || "dialog-box"}
        PaperProps={{
          ...dialogProps?.PaperProps,
          style: {
            ...dialogProps?.PaperProps?.style,
            ...(showOutline && {
              outline: "solid 2px red",
              outlineOffset: "-1px",
            }),
          },
        }}
        {...dialogProps}
      >
        {title && (
          <DialogTitle
            disableTypography
            className={classNames(classes.headerRoot, "draggable-handle", {
              [classes.draggable]: isDraggable,
            })}
          >
            {!isNewTheme && (
              <>
                <Typography variant="h3">{title}</Typography>
                <MuiIconButton
                  onClick={onCloseButtonClicked}
                  className={classes.closeButton}
                  aria-label="close"
                >
                  <CloseIcon />
                </MuiIconButton>
              </>
            )}
            {isNewTheme && (
              <>
                <H3>
                  {title}
                  {secondaryTitle && (
                    <div className={classes.secondaryTitle}>
                      {secondaryTitle}
                    </div>
                  )}
                </H3>

                <IconButton
                  onClick={onCloseButtonClicked}
                  className={classes.closeButton}
                  aria-label="close"
                  variant="tertiary"
                >
                  <CloseIcon />
                </IconButton>
              </>
            )}
          </DialogTitle>
        )}
        {children}
        <div
          id="portal-container"
          style={{ zIndex: 100005, position: "fixed" }}
        />
      </Dialog>
    </DialogWrapper>
  );
};

interface IDialogWrapperProps {
  isDraggable?: boolean;
  children: React.ReactNode;
}

const DialogWrapper = ({ isDraggable, children }: IDialogWrapperProps) => {
  if (isDraggable) {
    return <Draggable handle=".draggable-handle">{children}</Draggable>;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
