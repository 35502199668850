import type {
  FormControlLabelProps as MuiFormControlLabelProps,
  SwitchProps as MuiSwitchProps,
} from "@material-ui/core";
import {
  FormControlLabel as MuiFormControlLabel,
  Switch as MuiSwitch,
} from "@material-ui/core";
import type { CSSProperties } from "@material-ui/styles";

import { useEnsureNewTheme } from "./theme/providers";

export type SwitchProps = {
  label?: string;
  labelPlacement?: MuiFormControlLabelProps["labelPlacement"];
  labelStyle?: CSSProperties;
} & MuiSwitchProps;

export const Switch = ({
  label,
  labelPlacement,
  labelStyle,
  ...rest
}: SwitchProps) => {
  useEnsureNewTheme();
  const switchComponent = (
    <MuiSwitch {...rest} inputProps={{ "aria-label": label }} />
  );
  if (!label) {
    return switchComponent;
  }
  return (
    <MuiFormControlLabel
      control={switchComponent}
      label={label}
      labelPlacement={labelPlacement}
      style={labelStyle}
    />
  );
};
