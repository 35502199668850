import type { TypographyProps as MuiTypographyProps } from "@material-ui/core";
import { makeStyles, Typography as MuiTypography } from "@material-ui/core";
import type { Variant } from "@material-ui/core/styles/createTypography";
import classNames from "classnames";

import { useEnsureNewTheme } from "./theme";
import type { MHDSVariant } from "./theme/mhdsTypography";
import { MHDSVariants, MHDSVariantStyles } from "./theme/mhdsTypography";

export type TypographyProps = Omit<MuiTypographyProps, "variant"> & {
  variant: MHDSVariant | Variant;
  component?: React.ElementType;
};

/**
 * At the core of our Memora Health Design System, the `Typography` component is how
 * we define the look and feel of all text rendered within our application.
 *
 * In use, the MHDS `Typography` component is used in place of the MUI `Typography`
 * component as it supports the MHDS specific _variants_ which will adhere to our
 * internal design system. This component should be used instead of applying any
 * specific `font-size` or `font-weight` styles within `sx` properties or `makeStyles`  /
 * `createStyles` stylesheets.
 *
 * For backwards compatibility, this component also supports the following standard
 * MUI _variants_: `h1`, `h2`, `h3`, `h4`, `h5`, `h6`, `subtitle1`, `subtitle2`, `body1`,
 * `body2`, `caption`, `button`, and `overline` variants -  which are all mapped to
 * the new MHDS variants' styling. `TypographyShortcuts` have been put in place to
 * replace existing "shortcut" `H1` - `H6`, `Body`, or `Link` components ensuring backwards
 * compatibility of existing code. If you need more information on **depreciated**
 * MUI components, please see the **MUI** category below.
 *
 * In all cases, the MHDS `Typography` and the theme-based MUI "shortcut" components
 * are restricted to only affect the typefaces and or the look of the font being used
 * but have no affect on the margins or padding that may be needed when rendering the
 * component within a given page layout.
 *
 * [**» Figma Reference**](https://www.figma.com/file/z1t3BL6N5YMMchVSeUTY6I/MHDS---Components?type=design&node-id=5980%3A11975&mode=design&t=sIq81B09m9Dg1ntP-1)
 */
export function Typography({
  className,
  component = "span",
  variant = "standardLarge",
  ...props
}: TypographyProps) {
  useEnsureNewTheme();
  const styles = useStyles();
  const isCustom = MHDSVariants.indexOf(variant) > -1;
  const customClassname: string = isCustom
    ? styles[variant as MHDSVariant]
    : "";

  return (
    <MuiTypography
      className={classNames(className, {
        [customClassname]: customClassname && isCustom,
      })}
      component={component}
      variant={isCustom ? "inherit" : (variant as Variant)}
      {...props}
    />
  );
}

const useStyles = makeStyles(() => ({
  ...MHDSVariantStyles,
}));
