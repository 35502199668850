import type { TabsProps as MuiTabsProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { TabContext, TabList } from "@material-ui/lab";

import type { TabSize } from "./Tab";
import { useEnsureNewTheme } from "./theme/providers";

export interface TabsProps extends Omit<MuiTabsProps, "onChange"> {
  onChange?: (event: React.ChangeEvent<{}>, value: any) => void;
  size?: TabSize;
}

export const Tabs = ({
  children,
  size = "large",
  value,
  onChange,
  TabIndicatorProps,
  ...rest
}: TabsProps) => {
  useEnsureNewTheme();
  const styles = useTabListStyles();

  return (
    <TabContext value={value}>
      <TabList
        aria-label="tabs"
        className={styles[size]}
        TabIndicatorProps={{
          ...TabIndicatorProps,
          style: {
            ...TabIndicatorProps?.style,
            height: size === "small" ? "2px" : "4px",
          },
        }}
        indicatorColor="primary"
        onChange={onChange}
        {...rest}
      >
        {children}
      </TabList>
    </TabContext>
  );
};

const useTabListStyles = makeStyles(() => ({
  large: {
    minHeight: 48,
  },
  medium: {
    minHeight: 36,
  },
  small: {
    minHeight: 24,
  },
}));
