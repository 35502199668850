/* eslint-disable react/jsx-key */ // the props spread have keys
import type { TablePaginationProps } from "@material-ui/core";
import {
  Card,
  TableCell,
  TableContainer,
  TablePagination,
} from "@material-ui/core";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import TableRow from "@material-ui/core/TableRow";
import type { ForwardedRef } from "react";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";

import { BodyCell } from "./components/BodyCell";
import { DefaultFilter } from "./components/DefaultFilter";
import { EmptyStateRow } from "./components/EmptyStateRow";
import { FilterRow } from "./components/FilterRow";
import { HeaderCell } from "./components/HeaderCell";
import { LoadIndicatorRow } from "./components/LoadIndicatorRow";
import { useStyles } from "./hooks/useStyles";
import type { ITableProps, ITableRef } from "./types";

const CoreTable = <T extends object>(
  props: ITableProps<T>,
  ref: ForwardedRef<ITableRef<T>>
) => {
  const {
    columns,
    data,
    count,
    onFetchArgsChanged,
    isLoading,
    localization,
    getRowProps = () => ({}),
    getColumnCellProps,
    getHeaderProps,
    pageSize,
    emptyTableComponent,
    enableServerPaging = true,
    enableFilters = true,
    enableHeaders = true,
    disableMultiSort = false,
    autoResetSortBy = true,
    autoResetFilters = true,
    autoResetPage = true,
    TableWrapper = Card,
    TablePaginationActionsComponent = TablePaginationActions,
    filterId,
    fixedLayout = false,
    handlePageChange: customHandlePageChange = () => {},
  } = props;

  const table = useTable(
    {
      columns,
      defaultColumn: useMemo(
        () => ({
          Filter: DefaultFilter,
        }),
        []
      ),
      data,
      disableMultiSort,
      autoResetSortBy,
      autoResetFilters,
      autoResetPage,
      // paging args
      manualPagination: enableServerPaging,
      initialState: useMemo(() => ({ pageIndex: 0, pageSize }), [pageSize]),
      pageCount: useMemo(() => Math.ceil(count / pageSize), [count, pageSize]), // this isn't exact, we use totalCount below
      // sorting args
      manualSortBy: enableServerPaging,
      // filter args,
      manualFilters: enableServerPaging,
    },
    useFilters,
    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setSortBy,
    setAllFilters,
    allColumns,
    pageCount,
    state: { pageIndex, filters, sortBy },
  } = table;

  useImperativeHandle(
    ref,
    () => ({
      setSortBy,
      setAllFilters,
      allColumns,
      gotoPage,
    }),
    [setSortBy, setAllFilters, allColumns, gotoPage]
  );

  // ref to ignore the following useEffect on initial render
  const firstUpdate = useRef(true);
  // resets all states on filterId change
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setSortBy([]);
    setAllFilters([]);
    gotoPage(0);
  }, [filterId, gotoPage, setAllFilters, setSortBy]);

  useEffect(() => {
    if (onFetchArgsChanged) {
      onFetchArgsChanged({
        pageIndex,
        pageSize,
        filters,
        sortBy,
      });
    }
  }, [onFetchArgsChanged, pageIndex, pageSize, filters, sortBy]);

  // Navigate page to previous page in cases when autoResetPage is disabled.
  // Condition: If last item in a page gets resolve resulting in the item to disappear then navigate the page to a page before it.
  useEffect(() => {
    if (!autoResetPage && pageIndex !== 0 && pageIndex >= pageCount) {
      gotoPage(pageCount - 1);
      customHandlePageChange(pageCount - 1);
    }
  }, [
    autoResetPage,
    count,
    customHandlePageChange,
    enableServerPaging,
    gotoPage,
    pageCount,
    pageIndex,
  ]);

  const handleChangePage: TablePaginationProps["onPageChange"] = useCallback(
    (event, newPage) => {
      gotoPage(newPage);
      customHandlePageChange(newPage);
    },
    [gotoPage, customHandlePageChange]
  );

  const styles = useStyles();

  if (page.length === 0 && emptyTableComponent && !isLoading) {
    return emptyTableComponent;
  }

  return (
    <div className={styles.noHeaderWrapper}>
      {/* We have fixed table layout so cannot use loader as first cell for no column header tables */}
      {!enableHeaders && isLoading && (
        <div className={styles.noHeaderLoader}>
          <LoadIndicatorRow isLoading />
        </div>
      )}
      <TableWrapper>
        <TableContainer>
          <MaUTable
            {...getTableProps()}
            classes={{
              root: fixedLayout
                ? styles.fixedTableContainer
                : styles.tableContainer,
            }}
          >
            {enableHeaders && (
              <TableHead
                classes={{
                  root: styles.tableHead,
                }}
              >
                {headerGroups.map((headerGroup) => (
                  <TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      const headerProps = fixedLayout
                        ? { ...column.getHeaderProps(), width: column.width }
                        : column.getHeaderProps();
                      return (
                        <HeaderCell
                          column={column}
                          numSortsApplied={sortBy.length}
                          {...headerProps}
                          {...getHeaderProps?.(column)}
                          table={table}
                        />
                      );
                    })}
                  </TableRow>
                ))}
                {isLoading && (
                  <TableRow>
                    <TableCell>
                      <LoadIndicatorRow isLoading={isLoading} />
                    </TableCell>
                  </TableRow>
                )}
              </TableHead>
            )}

            <TableBody>
              {enableFilters && <FilterRow table={table} />}
              {page.map((row) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps(getRowProps(row))}>
                    {row.cells.map((cell) => (
                      <BodyCell
                        cell={cell}
                        fixedLayout={fixedLayout}
                        enableHeaders={enableHeaders}
                        columnCellProps={getColumnCellProps?.(cell.column, row)}
                        {...cell.getCellProps()}
                      />
                    ))}
                  </TableRow>
                );
              })}
              {page.length === 0 && (
                <EmptyStateRow
                  colSpan={columns.length}
                  emptyMessage={localization.emptyMessage}
                  table={table}
                  isLoading={isLoading}
                />
              )}
            </TableBody>
          </MaUTable>
        </TableContainer>
      </TableWrapper>
      <TablePagination
        classes={{
          root: styles.footer,
        }}
        ActionsComponent={TablePaginationActionsComponent}
        component="div"
        rowsPerPageOptions={[]}
        count={count}
        rowsPerPage={pageSize}
        page={!count || count <= 0 ? 0 : pageIndex}
        onPageChange={handleChangePage}
      />
    </div>
  );
};

export const Table = forwardRef(CoreTable);
