import { RoleName } from "@pillpal/api-types";
import { useOptionalCurrentUser } from "hooks/useCurrentUser";
import { get } from "lodash";

export function useUserRole(roleName: RoleName): boolean {
  const user = useOptionalCurrentUser();
  const roles = user?.roles;
  if (roleName === RoleName.ANYONE) {
    return true;
  }
  if (!roles) {
    return false;
  }

  return get(roles, roleName, false);
}
