import { getLocalTimeZone } from "@internationalized/date";
import {
  Checkbox,
  Divider as MuiDivider,
  MenuItem,
  withStyles,
} from "@material-ui/core";
import type { DropdownOption, DropdownOptions } from "@pillpal/api-types";
import classNames from "classnames";
import { convertDateToCalenderDateTime } from "containers/actionItems/helpers";
import { useDateFormatter } from "react-aria";
import type { VirtualItem } from "react-virtual";

import { Body } from "../Body";
import { Typography } from "../Typography";
import { useAutoCompleteStyles } from "./sharedStyles";
import { useVirtualStyles } from "./virtualStyles";

interface VirtualizedItemProps {
  virtualRow: VirtualItem;
  option: DropdownOption<string>;
  selectedOptions: DropdownOptions<string>;
  isMulti?: boolean;
  isSubtitleDate?: boolean;
  optionClicked: (option: DropdownOption<string>) => void;
  getOptionProps: ({
    option,
    index,
  }: {
    option: DropdownOption<string>;
    index: number;
  }) => {};
}

const Divider = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.memoraGrey[18],
  },
}))(MuiDivider);

export function VirtualizedItem({
  virtualRow,
  option,
  selectedOptions,
  isMulti,
  isSubtitleDate,
  optionClicked,
  getOptionProps,
}: VirtualizedItemProps) {
  const virtualStyles = useVirtualStyles();
  const styles = useAutoCompleteStyles();
  const formatter = useDateFormatter({ dateStyle: "short" });
  const { index } = virtualRow;
  const optionProps = getOptionProps({ option, index });
  const optionSelected = !!selectedOptions.find(
    (item) => item.value === option.value
  );

  return (
    <MenuItem
      {...optionProps}
      className={classNames(styles.menuItem, virtualStyles.virtualItem, {
        [styles.selectedItem]: !isMulti && optionSelected,
      })}
      key={`${virtualRow.index}`}
      onClick={() => optionClicked(option)}
      style={{
        height: `${virtualRow.size}px`,
        transform: `translateY(${virtualRow.start}px)`,
      }}
      disabled={option.type === "header"}
    >
      {option.type === "header" ? (
        <>
          {index !== 0 && <Divider className={styles.divider} />}
          <Typography variant="emphasisLarge">{option.label}</Typography>
        </>
      ) : (
        <>
          {isMulti && (
            <Checkbox
              {...optionProps}
              checked={optionSelected}
              edge={false}
              className={styles.checkbox}
            />
          )}
          <Body size="large" className={styles.title}>
            {option.label}
          </Body>
          {option.subtitle && (
            <Body size="large" className={styles.subtitle}>
              {isSubtitleDate
                ? formatter
                    .format(
                      convertDateToCalenderDateTime(
                        new Date(option.subtitle)
                      ).toDate(getLocalTimeZone())
                    )
                    .toString()
                : option.subtitle}
            </Body>
          )}
        </>
      )}
    </MenuItem>
  );
}
