/* eslint-disable react/jsx-key */
import { TableCell, TableRow } from "@material-ui/core";
import { useMemo } from "react";
import type { TableInstance } from "react-table";

import { useStyles } from "../hooks/useStyles";

interface IFilterRowProps<T extends object> {
  table: TableInstance<T>;
}

export function FilterRow<T extends object>(props: IFilterRowProps<T>) {
  const { table } = props;
  const { columns, headerGroups } = table;

  const styles = useStyles();

  const someColumnCanFilter = useMemo(
    () => columns.some((c) => c.canFilter),
    [columns]
  );

  if (!someColumnCanFilter) {
    return null;
  }

  return (
    <>
      {headerGroups.map((headerGroup) => (
        <TableRow
          {...headerGroup.getHeaderGroupProps()}
          classes={{ root: styles.filterRow }}
        >
          {headerGroup.headers.map((column) => (
            <TableCell
              classes={{
                root: styles.cell,
              }}
              {...column.getHeaderProps()}
            >
              {column.canFilter ? column.render("Filter") : null}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
}
