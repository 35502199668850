import * as Sentry from "@sentry/react";
import type { RouterHistory } from "@sentry/react/types/reactrouter";
import type { AxiosError } from "axios";
import { getScreenSize, getWindowSize } from "logging/helpers";
import { clientLogger } from "logging/logger";
import { userObservable } from "observables/userObservable";

import {
  COMMIT_SHA,
  SENTRY_DSN,
  SENTRY_ENV,
  SENTRY_TUNNEL_ENDPOINT,
  USE_SENTRY,
} from "../config";
import { AuthMissingError } from "../errors";

/**
 * Initializes tracing
 * @param history Browser history so sentry can instrument page changes
 */
export function initTracing(history: RouterHistory) {
  if (!USE_SENTRY) {
    return;
  }

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    release: COMMIT_SHA,
    tunnel: SENTRY_TUNNEL_ENDPOINT,
    ignoreErrors: [
      "TypeError: Failed to fetch",
      "NetworkError: A network error occurred.",
      "TypeError: NetworkError when attempting to fetch resource.",
      "TypeError: Load failed",
      "ResizeObserver loop limit exceeded",
    ],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    tracesSampleRate: 0.1,
    beforeSend(event, hint) {
      // docs.sentry.io/platforms/javascript/configuration/filtering/#event-hints
      const error = hint?.originalException;

      if (error && (error as AxiosError).isAxiosError) {
        // Do not log any unhandled axios errors in sentry
        return null;
      }

      if (error && error instanceof AuthMissingError) {
        // hackily remove AuthMissingError until we can finally clean it up (in CTT-482)
        return null;
      }
      return event;
    },
  });
}

userObservable.subscribe(({ userEmail, providerId, providerName }) => {
  const isInternalUser = userEmail?.endsWith("@memorahealth.com");

  Sentry.setUser(userEmail ? { email: userEmail } : null);
  Sentry.setTag("internalUser", isInternalUser);
  Sentry.setTag("providerId", providerId);
  Sentry.setTag("providerName", providerName);

  // Snapshot sizes when the user changes, won't be auto updated when these values change
  Sentry.setTag("windowSize", getWindowSize());
  Sentry.setTag("screenSize", getScreenSize());

  clientLogger.info(`changing user`);
});
