import { makeStyles } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { H3 } from "components/system/Headings";
import { IconButton } from "components/system/IconButton";

interface DrawerHeaderProp {
  onClose: () => void;
  title?: string;
  info?: string;
}

/**
 * @deprecated Use DrawerHeaderWithSemanticMessage instead
 */
export const DrawerHeader = ({ onClose, title, info }: DrawerHeaderProp) => {
  const classes = useStyles();
  return (
    <div className={classes.headerContainer}>
      {title && (
        <H3 className={classes.title}>
          {title}
          {info && (
            <>
              <span className={classes.spacer}>|</span>
              <span className={classes.info}>{info}</span>
            </>
          )}
        </H3>
      )}
      <IconButton
        className={classes.closeButton}
        aria-label="close"
        variant="tertiary"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    borderBottom: `1px solid ${theme.palette.memoraGrey[18]}`,
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "10px 24px",
  },
  title: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    alignItems: "center",
  },
  info: {
    color: theme.palette.memoraGrey[55],
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  spacer: {
    color: theme.palette.memoraGrey[28],
    paddingLeft: 8,
    paddingRight: 8,
  },
  closeButton: {
    marginLeft: "auto",
  },
}));

/**
 * This component is the header of the new DrawerWithSemanticMessage. Contains the necessary styles and
 * in order to be plugged to the DrawerWithSemanticMessage.
 *
 * Supports the drawer layout / styles and semantic messages in case of notification / blocking messages
 * displayed within the drawer.
 *
 * We are trying to deprecate the old drawer to enforce consistency and make dev's life easier when implementing
 * a drawer following our guidelines.
 *
 * In order to use this drawer actions please see:
 *
 * 1. BasicDrawerWithSemanticMessage.stories.tsx
 * 2. BasicDrawerWithNotificationSemanticMessage.stories.tsx
 */
export const DrawerHeaderWithSemanticMessage = ({
  onClose,
  title,
  info,
}: DrawerHeaderProp) => {
  const classes = useStylesV2();
  return (
    <div className={classes.headerContainer}>
      {title && (
        <H3 className={classes.title}>
          {title}
          {info && (
            <>
              <span className={classes.spacer}>|</span>
              <span className={classes.info}>{info}</span>
            </>
          )}
        </H3>
      )}
      <IconButton aria-label="close" variant="tertiary" onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};

const useStylesV2 = makeStyles((theme) => ({
  headerContainer: {
    borderBottom: `1px solid ${theme.palette.memoraGrey[18]}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px 0",
  },
  title: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    alignItems: "center",
    paddingLeft: 24,
  },
  info: {
    color: theme.palette.memoraGrey[55],
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  spacer: {
    color: theme.palette.memoraGrey[28],
    paddingLeft: 8,
    paddingRight: 8,
  },
}));
