import { TableCell, TableRow } from "@material-ui/core";
import { H4 } from "components/system/Headings";

interface ISimpleEmptyStateRowProps {
  colSpan: number;
  isLoading?: boolean;
  emptyMessage: React.ReactNode;
}

export function SimpleEmptyStateRow(props: ISimpleEmptyStateRowProps) {
  const { colSpan, isLoading, emptyMessage } = props;

  return (
    <TableRow>
      <TableCell colSpan={colSpan} height="250px" align="center">
        {!isLoading && <H4 color="textSecondary">{emptyMessage}</H4>}
      </TableCell>
    </TableRow>
  );
}
