import { create } from "zustand";

// chat navigation states
export type INavigationState = {
  /** Panel is showing */
  visible: boolean;
  /** Dual pane view active - non mobile */
  showEmail: boolean;
  /** Actively selected patient */
  patientId: string | null;
  /** Comment to scroll to */
  commentId: string | null;
  /** Patients or comments tab */
  tab: string;
  /** Webhook status */
  connected: boolean;
  /** Concern id to scroll to in sms */
  concernId: string | null;
  /** Message id to scroll to in sms */
  messageId: string | null;
  /** Message time to scroll to in sms */
  messageTime: string | null;
  /** Flag to show comments and action items */
  expandAll: boolean;
  /** Open chat panel */
  openChatPanel: () => void;
  /** Close chat panel */
  closeChatPanel: () => void;
  /** Switch to sms view */
  switchToSMS: () => void;
  /** Switch to email view */
  switchToEmail: () => void;
  /** Scroll to specific concern */
  scrollToConcern: (concernId: string | null) => void;
  /** Scroll to specific message */
  scrollToMessage: (messageId: string | null) => void;
  /** Reset scroll state */
  resetScrollState: () => void;
  /** Scroll to specific message by time */
  scrollToMessageByTime: (messageTime: string | null) => void;
  /** Scroll to specific comment */
  scrollToComment: (commentId: string | null) => void;
  /** Set active patient id */
  setChatPatientId: (patientId: string | null) => void;
  /** Set active tab */
  setChatTab: (tab: string) => void;
  /** Expand all messages */
  expandAllMessage: () => void;
  /** Collapse all messages */
  collapseAllMessage: () => void;
};

export const initialNavigationState: INavigationState = Object.freeze({
  visible: false,
  showEmail: false,
  patientId: null,
  tab: "patient",
  connected: true,
  concernId: null,
  messageId: null,
  messageTime: null,
  commentId: null,
  expandAll: true,
  openChatPanel: () => {},
  closeChatPanel: () => {},
  switchToSMS: () => {},
  switchToEmail: () => {},
  scrollToConcern: () => {},
  scrollToMessage: () => {},
  resetScrollState: () => {},
  scrollToMessageByTime: () => {},
  scrollToComment: () => {},
  setChatPatientId: () => {},
  setChatTab: () => {},
  expandAllMessage: () => {},
  collapseAllMessage: () => {},
});

export const useChatNavigationStore = create<INavigationState>((set) => ({
  ...initialNavigationState,
  openChatPanel: () => set(() => ({ visible: true })),
  closeChatPanel: () => set(() => ({ visible: false })),
  switchToSMS: () => set(() => ({ showEmail: false })),
  switchToEmail: () => set(() => ({ showEmail: true })),
  scrollToConcern: (concernId: string | null) => set(() => ({ concernId })),
  scrollToMessage: (messageId: string | null) => set(() => ({ messageId })),
  resetScrollState: () =>
    set(() => ({ concernId: null, messageTime: null, messageId: null })),
  scrollToMessageByTime: (messageTime: string | null) =>
    set(() => ({ messageTime })),
  scrollToComment: (commentId: string | null) => set(() => ({ commentId })),
  setChatPatientId: (patientId: string | null) => set(() => ({ patientId })),
  setChatTab: (tab: string) => set(() => ({ tab })),
  expandAllMessage: () => set(() => ({ expandAll: true })),
  collapseAllMessage: () => set(() => ({ expandAll: false })),
}));
