/**
 * Helpers for getting and setting query parameters of common types. Sets don't cause a
 * page reload
 */

export function getStringParam(name: string): string | undefined;
export function getStringParam(name: string, defaultValue: string): string;
export function getStringParam(
  name: string,
  defaultValue?: string
): string | undefined {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(name);
  if (!value && defaultValue) {
    setStringParam(name, defaultValue);
  }
  return value || defaultValue;
}

export function setStringParam(name: string, value?: string) {
  const urlParams = new URLSearchParams(window.location.search);
  if (value) {
    urlParams.set(name, value);
  } else {
    urlParams.delete(name);
  }
  window.history.replaceState(null, "", `?${urlParams.toString()}`);
}

export function getNumberParam(name: string): number | undefined {
  const stringValue = getStringParam(name);
  if (!stringValue) {
    return undefined;
  }
  return Number.parseInt(stringValue, 10);
}

export function setNumberParam(name: string, value?: number) {
  setStringParam(name, value?.toString());
}

export function getBooleanParam(name: string): boolean {
  const stringValue = getStringParam(name);
  const truthy: string[] = ["true", "True", "1"];
  return stringValue ? truthy.includes(stringValue) : false;
}
