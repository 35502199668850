import type { ButtonProps } from "@material-ui/core";
import * as React from "react";
import { useCallback } from "react";

import { ConfirmDialog } from "../dialogs/ConfirmDialog";
import type { DialogProps } from "./useDialogs";
import { useDialog } from "./useDialogs";

export function useConfirmDialog() {
  const { launch } = useDialog(HostedConfirmDialog);
  return launch;
}

export type WithConfirmProps = {
  confirm: ReturnType<typeof useConfirmDialog>;
};

export type ConfirmResponse = {
  confirmed: boolean;
};

export type ConfirmRequest = {
  title: string;
  content: string;
  confirmText?: string;
  destructive: boolean;
  cancelText?: string;
  cancelButtonProps?: Partial<ButtonProps>;
  submitButtonProps?: Partial<ButtonProps>;
  useHtmlContent?: boolean;
  onBeforeClose?: (data: ConfirmResponse) => void;
};

/**
 * Adapted confirm dialog for Dialog Host built in usage
 *  TODO: add rationale/text field support
 */
function HostedConfirmDialog({
  title,
  content,
  confirmText,
  cancelText = "",
  destructive,
  isOpen,
  onClose,
  onProvideCloseData,
  submitButtonProps,
  cancelButtonProps,
  useHtmlContent = false,
  onBeforeClose,
}: DialogProps<ConfirmRequest, ConfirmResponse>) {
  const [isConfirming, setIsConfirming] = React.useState(false);
  const onCloseInternal = useCallback(async () => {
    const result = { confirmed: false };
    const beforeClosePromise = onBeforeClose?.(result) || Promise.resolve();
    setIsConfirming(true);
    await beforeClosePromise;
    setIsConfirming(false);
    onProvideCloseData(result);
    onClose();
  }, [onProvideCloseData, onBeforeClose, onClose]);

  const onConfirmInternal = useCallback(async () => {
    const result = { confirmed: true };
    const beforeClosePromise = onBeforeClose?.(result) || Promise.resolve();
    setIsConfirming(true);
    await beforeClosePromise;
    setIsConfirming(false);
    onProvideCloseData(result);
    onClose();
  }, [onProvideCloseData, onBeforeClose, onClose]);

  return (
    <ConfirmDialog
      isOpen={isOpen}
      title={title}
      content={content}
      destructive={destructive}
      confirmText={confirmText}
      cancelTitle={cancelText}
      onClose={onCloseInternal}
      onConfirm={onConfirmInternal}
      submitButtonProps={submitButtonProps}
      cancelButtonProps={cancelButtonProps}
      useHtmlContent={useHtmlContent}
      isConfirming={isConfirming}
    />
  );
}
