import type { SvgIconProps } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import type { SelectProps } from "@material-ui/core/Select";
import SvgIcon from "@material-ui/core/SvgIcon";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import type { ClassNameMap } from "@material-ui/styles";
import classNames from "classnames";
import { Body } from "components/system/Body";
import { Chip } from "components/system/Chip";
import { IconButton } from "components/system/IconButton";
import { CoreMenuItem } from "components/system/menus/MenuItem";
import type { Size } from "components/system/MuiTypography";
import { useEnsureNewTheme } from "components/system/theme/providers";
import type { InputSize } from "components/system/tokens/types";
import { isEqual } from "lodash";
import type { ChangeEvent } from "react";
import { useEffect, useMemo, useRef, useState } from "react";

import { MultiAutoCompletePaper } from "../MultiAutocompletePaper";
import {
  CustomSvgIconArrow,
  StyledSelect,
  StyledSelectFormControl,
  StyledSelectInputBase,
} from "../sharedStyles";
import { getLabel, getMenuProps } from "./helpers";
import { useStyles } from "./styles";
import type { OptionType } from "./types";

export type MultiSelectProps = Omit<
  SelectProps,
  "fullWidth" | "size" | "onChange"
> & {
  fullWidth?: boolean;
  value: string[];
  noOptionsText?: string;
  emptyOptionText?: string;
  placeholder?: string;
  options: OptionType[];
  onOptionDelete?: (event: React.MouseEvent, value: string) => void;
  onOptionSelect?: (value: string[]) => void;
  onOptionSelectAll?: (value: string[]) => void;
  onClearAll?: () => void;
  allSelectedText?: string;
  selectAllText?: string;
  showFixedLabel: boolean;
  onChange?: (values: string[]) => void;
  customOptionsCheck?: boolean;
  selectAll?: boolean;
  ["data-testId"]: string;
  size?: InputSize;
};

// this is a stub function to remove drop down icon when any of the option is selected
// as we show endAdornment when any of the option is selected
const CustomSvgIconSelected = (props: SvgIconProps) => (
  <div className="closeIcon">
    <SvgIcon {...props} />
  </div>
);

const valueValidation = (value: string[] | string): string[] => {
  if (typeof value === "string" && !value) {
    return [];
  }
  if (typeof value === "string") {
    return [value];
  }
  return value;
};

const getMUIBodySize = (size: InputSize): Size =>
  size === "normal" ? "medium" : "large";

export const MultiSelect = ({
  options,
  noOptionsText = "No options",
  value = [],
  placeholder,
  error,
  disabled,
  fullWidth = false,
  emptyOptionText,
  onOptionDelete,
  onOptionSelect,
  onOptionSelectAll,
  onClearAll,
  allSelectedText = "All choices selected",
  showFixedLabel,
  selectAllText = "Select all",
  onChange,
  customOptionsCheck = false,
  selectAll = false,
  "data-testId": testId,
  size = "large",
  SelectDisplayProps,
  ...rest
}: MultiSelectProps) => {
  useEnsureNewTheme();
  const SELECT_ALL = "all";
  const classes = useStyles();
  const [values, setValues] = useState<string[]>([]);

  // this handles rerenders with values being updated outside of this component
  useEffect(() => {
    setValues(valueValidation(value));
  }, [value]);

  const hasValues = useMemo(() => values.length > 0, [values]);

  let isOptionEqualsToValue = options.length === values.length;
  if (customOptionsCheck) {
    isOptionEqualsToValue = isEqual(options, values);
  }

  const menuProps = useMemo(
    () => getMenuProps(isOptionEqualsToValue, options.length, classes),
    [isOptionEqualsToValue, options.length, classes]
  );

  const handleDelete = (e: React.MouseEvent, val: string) => {
    const processedValue = values.filter(
      (currentValue) => currentValue !== val
    );
    setValues(processedValue);
    onChange?.(processedValue);
    onOptionDelete?.(e, val);
    if (values.length === 1 && selectRef.current) {
      // To stop label from being focused
      selectRef.current.click();
    }
  };

  const handleOnChange = (e: ChangeEvent<{ value: unknown }>) => {
    const selectedValues = e.target.value as string[];
    let processedSelectedValues = selectedValues;
    if (selectedValues.includes("all")) {
      processedSelectedValues = options.map((option) =>
        typeof option === "string" ? option : option._id
      );
      onOptionSelectAll?.(processedSelectedValues);
    } else {
      onOptionSelect?.(processedSelectedValues);
    }
    setValues(processedSelectedValues);
    onChange?.(processedSelectedValues);
  };

  const handleClearAll = () => {
    setValues([]);
    onChange?.([]);
    onClearAll?.();
  };

  const selectRef = useRef<HTMLSelectElement>(null);
  return (
    <StyledSelectFormControl
      size={size === "normal" ? "small" : "medium"}
      variant="outlined"
      fullWidth={fullWidth}
      disabled={disabled}
      classes={{
        root: classes.formControlLargeRoot,
      }}
      error={error}
      data-testid={testId}
    >
      <InputLabel
        shrink={false}
        classes={{
          root:
            size === "normal"
              ? classes.muiInputLabel
              : classes.muiInputLabelLarge,
          focused: classes.inputLabelFocusedOpen,
          disabled: classes.disabled,
        }}
      >
        <Body size={getMUIBodySize(size)}>
          {values.length === 0 && placeholder ? placeholder : ""}
        </Body>
      </InputLabel>
      <StyledSelect
        ref={selectRef}
        value={values}
        IconComponent={hasValues ? CustomSvgIconSelected : CustomSvgIconArrow}
        classes={{
          iconOpen: classes.iconOpen,
          disabled: classes.disabled,
        }}
        endAdornment={
          hasValues && (
            <IconButton
              onClick={handleClearAll}
              className={classes.clearButton}
            >
              <ClearIcon fontSize="medium" />
            </IconButton>
          )
        }
        multiple
        variant="outlined"
        renderValue={(selected) => (
          <div className={classes.chips}>
            {(selected as string[]).map((val) => (
              <Chip
                key={val}
                label={getLabel(options, val)}
                className={classes.chip}
                onDelete={(e) => handleDelete(e, val)}
              />
            ))}
          </div>
        )}
        input={
          <StyledSelectInputBase
            classes={{
              input: classNames({
                [classes.muiInputNormal]: !values.length && size === "normal",
                [classes.muiInputLarge]: !values.length && size === "large",
                // non-empty styling
                [classes.muiInput]: !!values.length,
              }),
            }}
          />
        }
        MenuProps={{
          ...menuProps,
          MenuListProps: {
            ...menuProps.MenuListProps,
            ...{
              "data-testid": "multi-select-menulist",
            },
          },
        }}
        onChange={handleOnChange}
        SelectDisplayProps={{
          ...SelectDisplayProps,
          ...{
            "data-testid": "multi-select",
          },
        }}
        {...rest}
      >
        {options.length === 0 && (
          <CoreMenuItem disabled>
            <Body size={getMUIBodySize(size)}>{noOptionsText || "None"}</Body>
          </CoreMenuItem>
        )}
        {options.length > 0 && !isOptionEqualsToValue && selectAll && (
          <CoreMenuItem value={SELECT_ALL}>
            <Body size={getMUIBodySize(size)} className={classes.addBody}>
              {selectAllText}
            </Body>
            <div className={classes.addIcon}>
              <AddIcon />
            </div>
          </CoreMenuItem>
        )}
        {renderOptions(options, values, size, classes)}
      </StyledSelect>
      <MultiAutoCompletePaper
        infoLabel={allSelectedText}
        labelType="success"
        showFixedLabel={
          (showFixedLabel && options.length > 0 && isOptionEqualsToValue) ||
          values.includes(SELECT_ALL)
        }
      />
    </StyledSelectFormControl>
  );
};

export const renderOptions = (
  options: OptionType[],
  value: string[],
  size: InputSize,
  classes: ClassNameMap
) =>
  options.map((option) => {
    if (typeof option === "string" && Array.isArray(value)) {
      return (
        !value.includes(option) && (
          <CoreMenuItem key={option} value={option} title={option}>
            <Body size={getMUIBodySize(size)} className={classes.menuItem}>
              {option}
            </Body>
          </CoreMenuItem>
        )
      );
    }
    if (option && typeof option === "object") {
      return (
        !value.filter((val) => val === option._id).length && (
          <CoreMenuItem key={option._id} value={option._id} title={option.name}>
            <Body size={getMUIBodySize(size)} className={classes.menuItem}>
              {option.name}
            </Body>
          </CoreMenuItem>
        )
      );
    }
    return null;
  });

MultiSelect.displayName = "MultiSelect";
