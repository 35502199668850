import { makeStyles } from "@material-ui/core";

export const useVirtualStyles = makeStyles(() => ({
  virtualParent: {
    overflow: "auto",
    boxSizing: "border-box",
  },
  virtualList: {
    width: "100%",
    position: "relative",
  },
  virtualItem: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
}));
