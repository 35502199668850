import type { SizedTypographyProps } from "./Body";
import type { Size } from "./MuiTypography";
import type { MHDSVariant } from "./theme/mhdsTypography";
import { useEnsureNewTheme } from "./theme/providers";
import { Typography } from "./Typography";

const SizeToCTAVariant: { [key in Size]: MHDSVariant } = {
  large: "emphasisLarge",
  medium: "emphasisMedium",
  small: "emphasisSmall",
};

/**
 * @deprecated This way of using our DS is deprecated. Use {@link Typography} instead
 * [**» Figma Reference**](https://www.figma.com/file/z1t3BL6N5YMMchVSeUTY6I/MHDS---Components?type=design&node-id=5980%3A11975&mode=design&t=sIq81B09m9Dg1ntP-1)
 */
export function CallToAction({ size, ...rest }: SizedTypographyProps) {
  useEnsureNewTheme();
  return <Typography variant={SizeToCTAVariant[size || "large"]} {...rest} />;
}
