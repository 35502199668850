import type { ChipProps } from "@material-ui/core";
import { Chip as MUIChip, makeStyles, withStyles } from "@material-ui/core";
import {
  CheckCircleOutlined,
  InfoOutlined,
  ReportProblemOutlined,
} from "@material-ui/icons";
import { Body } from "components/system/Body";
import { ICON_DEF } from "components/system/tokens/icons";

import { useEnsureNewTheme } from "./theme";

export type TagVariants = "warning" | "alarm" | "info" | "success";
export type TagProps = Omit<
  ChipProps,
  | "label"
  | "deleteIcon"
  | "onDelete"
  | "onClick"
  | "color"
  | "variant"
  | "icon"
  | "size"
  | "clickable"
  | "disabled"
> & {
  variant: TagVariants;
  label: string;
  showIcon?: boolean;
};

const StyledChip = withStyles(() => ({
  root: {
    border: "1px solid",
  },
  iconSmall: {
    fontSize: ICON_DEF.small.fontSize,
  },
}))(MUIChip);

export function Tag({ label, variant, showIcon, ...rest }: TagProps) {
  useEnsureNewTheme();
  const styles = useStyles();
  let icon;
  const chipClassName = styles[variant];
  switch (variant) {
    case "warning":
      icon = <ReportProblemOutlined className={styles.warningIcon} />;
      break;
    case "alarm":
      icon = <ReportProblemOutlined className={styles.alarmIcon} />;
      break;
    case "info":
      icon = <InfoOutlined className={styles.infoIcon} />;
      break;
    case "success":
      icon = <CheckCircleOutlined className={styles.infoIcon} />;
      break;
    default:
      break;
  }
  return (
    <StyledChip
      size="small"
      className={chipClassName}
      icon={showIcon ? icon : undefined}
      label={<Body size="small">{label}</Body>}
      {...rest}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  warning: {
    backgroundColor: theme.palette.orange["10"],
    borderColor: theme.palette.orange["28"],
    color: theme.palette.memoraGrey["100"],
  },
  warningIcon: {
    color: theme.palette.orange["50"],
  },
  alarm: {
    backgroundColor: theme.palette.red["13"],
    borderColor: theme.palette.red["24"],
    color: theme.palette.red["55"],
  },
  alarmIcon: {
    color: theme.palette.red["50"],
  },
  info: {
    backgroundColor: theme.palette.navy["10"],
    borderColor: theme.palette.navy["24"],
    color: theme.palette.navy["55"],
  },
  infoIcon: {
    color: theme.palette.navy["55"],
  },
  success: {
    backgroundColor: theme.palette.green["10"],
    borderColor: theme.palette.green["20"],
    color: theme.palette.green["55"],
  },
  successIcon: {
    color: theme.palette.green["41"],
    backgroundColor: theme.palette.green["10"],
  },
}));
