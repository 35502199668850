import { LinearProgress } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";

import { ScopedNewThemeProvider } from "../components/system";

type TopLoaderProps = {
  "data-testid"?: string;
  delayMs?: number;
};

/**
 * Shows a slim indeterminant loader at the top of the screen, with optional delay
 * so that it only shows up for longer loading periods
 */
export function TopLoader({ "data-testid": testid, delayMs }: TopLoaderProps) {
  const timerRef = useRef<number>();
  const [visible, setVisible] = useState(!delayMs);

  useEffect(() => {
    if (delayMs) {
      timerRef.current = window.setTimeout(() => {
        setVisible(true);
      }, delayMs);
      return () => {
        window.clearTimeout(timerRef.current);
      };
    }
    return () => {};
  }, [delayMs]);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        zIndex: 10000,
        opacity: visible ? 1 : 0,
        transition: "all 0.1s linear",
      }}
      data-testid={testid}
    >
      <ScopedNewThemeProvider>
        <LinearProgress style={{ height: "2px" }} />
      </ScopedNewThemeProvider>
    </div>
  );
}
