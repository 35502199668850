import type { ButtonProps as MuiButtonProps } from "@material-ui/core";
import {
  Button as MuiButton,
  makeStyles,
  Tooltip,
  useTheme,
} from "@material-ui/core";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import type { ButtonProps } from "../Button";
import { Button } from "../Button";
import { useIsNewTheme } from "../theme";

export type BaseActionButtonProps = {
  onCancel?: () => void;
  onSubmit?: () => void;
  cancelTitle?: string;
  submitTitle?: string;
  cancelTooltip?: string;
  submitTooltip?: string;
  destructive?: boolean;
  submitDisabled?: boolean;
  cancelButtonProps?: Partial<ButtonProps | MuiButtonProps>;
  submitButtonProps?: Partial<ButtonProps | MuiButtonProps>;
  isLoading?: boolean;
  isSubmitAtStart?: boolean;
};

/**
 * Base buttons for a typical cancel/submit dialog
 */
export function BaseActionButtons(props: BaseActionButtonProps) {
  const {
    onCancel,
    onSubmit,
    cancelTitle,
    submitTitle,
    destructive,
    submitDisabled,
    cancelButtonProps,
    submitButtonProps,
    cancelTooltip,
    submitTooltip,
    isLoading = false,
    isSubmitAtStart = false,
  } = props;
  const { t } = useTranslation("core");
  const isNewTheme = useIsNewTheme();
  const styles = useStyles();

  const SubmitButton = !destructive ? MuiButton : DestructiveButton;

  if (!isNewTheme) {
    return (
      <div
        className={classNames(styles.wrapper, {
          [styles.alignLeft]: isSubmitAtStart,
        })}
        data-testid="base-action-buttons-container"
      >
        {onCancel && (
          <MuiButton
            onClick={onCancel}
            {...(cancelButtonProps as MuiButtonProps)}
          >
            {cancelTitle || t("dialog.cancelTitle")}
          </MuiButton>
        )}
        {onSubmit && (
          <SubmitButton
            onClick={onSubmit}
            color="primary"
            variant="contained"
            disabled={submitDisabled}
            {...(submitButtonProps as MuiButtonProps)}
          >
            {submitTitle || t("dialog.submitTitle")}
          </SubmitButton>
        )}
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.alignLeft]: isSubmitAtStart,
      })}
      data-testid="base-action-buttons-container"
    >
      {onCancel && (
        <Tooltip title={cancelTooltip || ""}>
          <Button onClick={onCancel} {...(cancelButtonProps as ButtonProps)}>
            {cancelTitle || t("dialog.cancelTitle")}
          </Button>
        </Tooltip>
      )}
      {onSubmit && (
        <Tooltip title={submitTooltip || ""}>
          <Button
            onClick={onSubmit}
            variant="primary"
            disabled={submitDisabled}
            destroyer={destructive}
            loading={isLoading}
            {...(submitButtonProps as ButtonProps)}
          >
            {submitTitle || t("dialog.submitTitle")}
          </Button>
        </Tooltip>
      )}
    </div>
  );
}

function DestructiveButton(props: MuiButtonProps) {
  const theme = useTheme();
  return (
    <MuiButton
      {...props}
      style={{
        backgroundColor: theme.palette.error.dark,
      }}
    />
  );
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    gap: 20,
  },
  alignLeft: {
    justifyContent: "flex-start",
    flexDirection: "row-reverse",
  },
}));
