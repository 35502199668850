import type { OutlinedInputProps as MuiOutlinedInputProps } from "@material-ui/core";
import classNames from "classnames";

import { useEnsureNewTheme } from "../theme/providers";
import {
  OutlinedInputStyled,
  useStyles as useSharedStyles,
} from "./sharedStyles";

export type TextInputMultiLineProps = Omit<
  MuiOutlinedInputProps,
  "minRows" | "multiline" | "rows"
> & {
  expandable?: boolean;
  minRows?: number;
  size?: "normal" | "large";
};

export const TextInputMultiLine = ({
  expandable,
  maxRows,
  minRows = 4,
  size = "normal",
  ...passedMuiInputProps
}: TextInputMultiLineProps) => {
  useEnsureNewTheme();

  const classes = useSharedStyles();

  const htmlInputProps = {
    ...passedMuiInputProps.inputProps,
    className: classNames({
      [classes.textArea]: true,
      [classes.expandable]: expandable,
    }),
  };

  const muiInputProps: MuiOutlinedInputProps = {
    ...passedMuiInputProps,
    classes: {
      root: size === "normal" ? classes.muiInputNormal : classes.muiInputLarge,
    },
  };
  if (maxRows) {
    muiInputProps.maxRows = maxRows;
  }

  return (
    <OutlinedInputStyled
      {...muiInputProps}
      minRows={minRows}
      multiline
      inputProps={htmlInputProps}
    />
  );
};
TextInputMultiLine.displayName = "TextInputMultiLine";
