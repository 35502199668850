import { makeStyles } from "@material-ui/core";
import { ExpandLessOutlined, ExpandMoreOutlined } from "@material-ui/icons";
import type { Dispatch, SetStateAction } from "react";

import { CallToAction } from "../CallToAction";

type RenderShowMoreLinkProps = {
  totalItems: number;
  displayedItems: number;
  itemLimit: number;
  setDisplayedItems: Dispatch<SetStateAction<number>>;
};

export const RenderShowMoreLink = (props: RenderShowMoreLinkProps) => {
  const { totalItems, displayedItems, setDisplayedItems, itemLimit } = props;
  const classes = useStyles();

  if (totalItems > displayedItems) {
    return (
      <div className={classes.viewMoreLink}>
        <CallToAction
          size="small"
          onClick={() => setDisplayedItems(totalItems)}
        >
          <>
            show more
            <ExpandMoreOutlined className={classes.viewLink} />
          </>
        </CallToAction>
      </div>
    );
  }
  if (displayedItems > itemLimit) {
    return (
      <div className={classes.viewMoreLink}>
        <CallToAction size="small" onClick={() => setDisplayedItems(itemLimit)}>
          <>
            show less
            <ExpandLessOutlined className={classes.viewLink} />
          </>
        </CallToAction>
      </div>
    );
  }
  return null;
};

const useStyles = makeStyles((theme) => ({
  viewLink: {
    color: theme.palette.blue[50],
    textDecoration: "underline",
    cursor: "pointer",
    verticalAlign: "middle",
  },
  viewMoreLink: {
    textAlign: "right",
    color: theme.palette.blue[50],
  },
}));
