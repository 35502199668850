/** Return whether a host prefix, like `foo.secure`, matches the provided list. */
export const isHostSupported = (supportedHosts: string[]): boolean => {
  const hostPrefix = getHostPrefix();
  return hostPrefix ? supportedHosts.includes(hostPrefix) : false;
};

/** Get the leading portion of the hostname. */
export const getHostPrefix = () => {
  const { hostname } = window.location;
  if (hostname === "localhost") {
    return "sandbox";
  }
  if (hostname.endsWith(".memorahealth.com")) {
    return hostname.replace(".memorahealth.com", "");
  }

  return undefined;
};

export function isProbablyLocalDev() {
  const isPort3000 = window.location.host.endsWith(":3000");
  const isKnownHostedHostName =
    window.location.hostname.endsWith(".memorahealth.com");
  return isPort3000 && !isKnownHostedHostName;
}

export function getLoginRoute() {
  return isProbablyLocalDev() ? "auth.html" : "";
}
