// inspired by https://chakra-ui.com/docs/hooks/use-disclosure

import { useCallback, useMemo, useState } from "react";

export function useDisclosure(defaultValue: boolean = false) {
  const [isOpen, setIsOpen] = useState(defaultValue);

  const onClose = useCallback(() => setIsOpen(false), []);
  const onOpen = useCallback(() => setIsOpen(true), []);
  const onToggle = useCallback(() => setIsOpen((open) => !open), []);

  return useMemo(
    () => ({
      isOpen,
      onOpen,
      onToggle,
      onClose,
    }),
    [isOpen, onOpen, onToggle, onClose]
  );
}
