import type {
  CardBase,
  CardFunctionNames,
  DashboardConfigFrontendFunctionNames,
  EnrollmentFieldsFrontendFunctionNames,
  FrontendFunctionNoFunctionFound,
  FrontendFunctions,
  IDashboardColumn,
  IField,
  IFrontendFunctionNames,
  IFunction,
  ILayoutState,
  IProgramFrontendConfigCards,
  PanelAction,
  PanelResponse,
  ProviderFrontend,
  ProviderProps,
} from "@pillpal/api-types";
import { clientLogger } from "logging/logger";
import type { IProvider } from "types/provider.types";

const DEFAULT_USER_ACTIONS = [
  { name: "editUser", title: "Edit User" },
  { name: "removeUser", title: "Delete User" },
];

const DEFAULT_USER_LAYOUT = [
  {
    id: "firstName",
    accessor: "firstName",
    Header: "First Name",
    sortType: "string",
  },
  {
    id: "lastName",
    accessor: "lastName",
    Header: "Last Name",
    sortType: "string",
  },
  {
    id: "email",
    accessor: "email",
    Header: "Email",
    sortType: "string",
  },
  {
    id: "phone",
    accessor: "phone",
    Header: "Phone",
    sortType: "string",
  },
  {
    id: "careTeams",
    accessor: "careTeams",
    Header: "Care Teams",
    sortType: "string",
  },
  {
    id: "receiveAlerts",
    accessor: "receiveAlerts",
    Header: "Receive Alerts",
    sortType: "boolean",
    disableFilters: true,
  },
];

type SortItem = { order?: number };
type SortObjectsItem<T> = T & SortItem;

// Sorts the cards based on priority
export function sortObjects<T>(
  list: SortObjectsItem<T>[]
): SortObjectsItem<T>[] {
  list.sort((a, b) => {
    const aOrder = a.order || 0;
    const bOrder = b.order || 0;

    // if both have order -999 leave them be
    if (aOrder === -999 && bOrder === -999) {
      return 0;
    }

    // ones with order of -999 go last
    if (bOrder === -999) {
      return -1;
    }

    if (aOrder === -999) {
      return 1;
    }

    // if neither have an order (or its zero) leave them where they are
    if (!aOrder && !bOrder) {
      return 0;
    }

    // ones with an order come earlier
    if (!aOrder && bOrder) {
      return 1;
    }

    // ones without an order go later
    if (aOrder && !bOrder) {
      return -1;
    }

    // both have order, put smaller order first
    return aOrder - bOrder;
  });
  return list;
}

type GetLocationOfFunctionKeys =
  | "patientStats"
  | "addPatientFields"
  | "panelActions";
type GetLocationOfFunctionResponse = "cards" | "enrollmentFields";

export function getLocationOfFunction(
  key: GetLocationOfFunctionKeys
): GetLocationOfFunctionResponse {
  if (key === "patientStats") {
    return "cards";
  }
  if (key === "addPatientFields") {
    return "enrollmentFields";
  }
  throw Error(`Couldn't find function location for key ${key}`);
}

/**
 * Builds a function given the arguments and the program specified from a column.
 * If the functionName is not found within the dashboard config, then function
 * name returned will be "noFunctionFound".
 *
 * @param column
 * @param frontendConfig
 * @param functionName
 * @returns
 */
function buildColumnFunction(
  column: IDashboardColumn,
  frontendConfig: FrontendFunctions,
  functionName: DashboardConfigFrontendFunctionNames = "noFunctionFound"
): IFunction<IFrontendFunctionNames> {
  const args =
    column.args && column.args.length > 0 ? column.args : column.func?.args;

  const func = frontendConfig.dashboardConfig[functionName];

  return {
    programName: func?.programName,
    name: func?.name || "noFunctionFound",
    args: args || [],
  };
}

/**
 * Attaches a function to the column if it exists within a program.
 *
 * We can say a function was specified in the column when the key property is set.
 *
 * A program has a set of functions and the column can also specify a program through
 * the programName property.
 *
 * If the program is not specified or not found when attaching a function to a column
 * then the default program will be used to attach the function.
 *
 * @param frontend
 * @param functions
 * @returns
 */
export function attachFunctionToDashboardColumns(
  frontend: ProviderFrontend,
  functions: Record<string, FrontendFunctions>
): IDashboardColumn[] {
  const pbColumns = Object.values(
    frontend.dashboardConfig.dashboardColumns.programColumns ?? []
  ).flat();
  const materialTableColumns = [
    ...frontend.dashboardConfig.dashboardColumns.data,
    ...pbColumns,
  ];

  for (const column of materialTableColumns) {
    const key = column.key as DashboardConfigFrontendFunctionNames;

    // If the value of our column is calculated via a function
    if (column.key && column.programName) {
      // Assign the func field the function
      // Handles failure case where frontend function doesn't exist by grabbing the noFunctionFound function
      const frontendConfig = functions[column.programName] || functions.default;

      // If we have args on the card, remove the default args and set the args as the card args
      if (frontendConfig) {
        column.func = buildColumnFunction(column, frontendConfig, key);
        // This is a bit weird and hacky. We set the field as the id of the column.
        // When material table wants to render a column, it looks at the field value to find
        // the column data. When we run the function, we add the key value pair on the patient,
        // <Column ID>: <Value generated by function>
        column.field = column._id;
      }
    } else if (column.key && !column.programName) {
      // Handles failure case where frontend function doesn't exist by grabbing the noFunctionFound function
      if (functions.default) {
        column.func = buildColumnFunction(column, functions.default, key);

        column.field = column._id;
      }
    } else {
      column.field = column.name;
    }
  }
  return materialTableColumns;
}

/**
 * Adds enrollmentFieldsFunction to field default configuraiton.
 *
 * Note: We are allowing mutable changes to the field due to legacy behavior.
 *
 * @param programFunctions
 * @param field
 */
function addProgramEnrollmentFieldsFunctionToField(
  field?: IField,
  programFunctions?: FrontendFunctions
): void {
  // If the field is a "Function", get the function name from `functions`
  // if the program does not exist in `functions` then look for the
  // field function within the default `functions`.
  if (field?.default?.type === "Function") {
    const { value } = field.default;
    const enrollmentFieldName = value as EnrollmentFieldsFrontendFunctionNames;
    const enrollmentField =
      programFunctions?.enrollmentFields[enrollmentFieldName];

    // If the function does not exist in `functions`
    // then change the field to be a "Value" and
    // set field default value to undefined.
    if (!enrollmentField) {
      // eslint-disable-next-line no-param-reassign
      field.default.type = "Value";
      // eslint-disable-next-line no-param-reassign
      field.default.value = undefined;
    } else {
      // eslint-disable-next-line no-param-reassign
      field.default.value = enrollmentField.value;
      // eslint-disable-next-line no-param-reassign
      field.default.name = enrollmentField.name;
    }
  }
}

/**
 * Attaches a function to the patient field if it exists within enrollment fields
 * inside the default program.
 *
 * We can say a function was specified in the patient field when the field default
 * type is equals to "Function".
 *
 * Patient field functions are determined by its "default" property configuration,
 * and if the "default type" is "Function" but no function was found for the
 * "default value", then the "default type" will be changed to be "Value" instead
 * of "Function" and the "default value" will be set to "undefined". Otherwise if
 * the function is found within the enrollment fields, the "default type and name"
 * will be set with the "function name and value" found respectively.
 *
 * If the program is not specified or not found when attaching a function to a column
 * then the default program will be used to attach the function.
 *
 * Finally these fields will be sorted according to their sort configuration.
 *
 * @param frontend
 * @param functions
 * @returns
 */
export function attachFunctionToDashboardPatientFields(
  frontend: ProviderFrontend,
  functions: Record<string, FrontendFunctions>
): IField[] {
  // According to this ticket CTT-2177, there might be null or undefined fields,
  // so let's filter out those.
  let truthyFields: IField[] = (
    frontend.dashboardConfig.addPatientFields.data || []
  ).filter((field) => Boolean(field));
  for (const field of truthyFields) {
    addProgramEnrollmentFieldsFunctionToField(field, functions.default);
  }

  truthyFields = sortObjects(truthyFields);
  return truthyFields;
}

type LayoutType = IDashboardColumn | IField | CardBase | PanelAction;

/**
 * Attaches a function to any of the "GetLocationOfFunctionKeys". These keys are
 * meant to be some of the "dashboardConfig" properties (collection of fields / columns).
 *
 * We can say a function was specified if the column / field contains the
 * key property.
 *
 * The default program will be used to get the functions specied within the
 * columns / fields.
 *
 * Right now we just verify functions within the defaul program for:
 * 1. enrollmentFields
 * 2. cards
 *
 * If no functions are found or the functions do not belong to the any of the list
 * above, then the function will be set to default "noFunctionFound"
 *
 * The function args will be the ones within the column / field if the have any,
 * otherwise it will be the default ones from the default program.
 *
 * @param layout
 * @param key
 * @param functions
 * @returns
 */
export function attachFunctionToDashboardConfigCollections(
  layout: LayoutType[],
  key: GetLocationOfFunctionKeys,
  functions: Record<string, FrontendFunctions>
): LayoutType[] {
  const defaultFunc: IFunction<FrontendFunctionNoFunctionFound> = {
    name: "noFunctionFound",
    args: [],
  };

  for (const component of layout) {
    // Only accepting column / fields with key property
    if ("key" in component && component.key) {
      const functionLocation = getLocationOfFunction(key);
      const componentKey = component.key;

      // if the function location for the key is enrollmentFields
      // then set the component.func to the function found.
      //
      // If no function found set the defaultFunc.
      //
      // Also run the same logic for "cards".
      if (componentKey && functionLocation === "enrollmentFields") {
        const enrollmentFieldName =
          componentKey as EnrollmentFieldsFrontendFunctionNames;
        component.func =
          functions.default?.[functionLocation]?.[enrollmentFieldName] ||
          defaultFunc;
      } else if (componentKey && functionLocation === "cards") {
        const cardName = componentKey as CardFunctionNames;
        component.func =
          functions.default?.[functionLocation]?.[cardName] || defaultFunc;
      } else {
        component.func = defaultFunc;
      }

      component.func.args =
        component.args && component.args.length > 0
          ? component.args
          : component.func.args;
    }
  }
  return layout;
}

/**
 * Adds a program card function if the key property was set and the program fuction is found.
 *
 * @param card
 * @param programFunctions
 */
function addProgramCardFunctionToProgramCard(
  card: IProgramFrontendConfigCards,
  programFunctions?: FrontendFunctions
) {
  if (card.key && programFunctions?.cards) {
    // eslint-disable-next-line no-param-reassign
    card.func = programFunctions.cards[card.key];
    if (card.func) {
      // eslint-disable-next-line no-param-reassign
      card.func.args = card.args || [];
    }
  }
}

/**
 * Attaches a function given the program and the cards configuration.
 *
 * Finally sorts the card fields according to the their sort configutation.
 *
 * @param frontend
 * @param functions
 * @returns
 */
export function attachProgramCardFunctionToProgramCard(
  frontend: ProviderFrontend,
  functions: Record<string, FrontendFunctions>
): IProgramFrontendConfigCards[] {
  let cards: IProgramFrontendConfigCards[] = [];
  // If there are programs, loop though all of them
  if (frontend.programs) {
    for (const program of Object.keys(frontend.programs)) {
      if (
        frontend.programs[program]?.cards &&
        Array.isArray(frontend.programs[program]?.cards)
      ) {
        // Loop though all the cards within the program and attach card functions
        for (const programCard of frontend.programs[program]?.cards || []) {
          addProgramCardFunctionToProgramCard(programCard, functions[program]);

          // If the card has children, then also attach functions for it
          if (programCard.children && Array.isArray(programCard.children)) {
            for (const programCardChild of programCard.children) {
              addProgramCardFunctionToProgramCard(
                programCardChild,
                functions[program]
              );
            }
          }

          programCard.program = program;
        }
        cards = cards.concat(frontend.programs[program]?.cards || []);
      }
    }
  }
  cards = sortObjects(cards);
  return cards;
}

/**
 * Attaches a function to the patient field if it exists within enrollment fields
 * inside a program.
 *
 * Similar to attachFunctionToDashboardPatientFields, but in this case will be done for custom programs.
 *
 * @param frontend
 * @param functions
 * @returns
 */
export function attachFunctionToProgramEnrollmentFields(
  frontend: ProviderFrontend,
  functions: Record<string, FrontendFunctions>
): Record<string, ParsePanelLayoutEnrollmentFields> {
  const enrollmentFields: Record<string, ParsePanelLayoutEnrollmentFields> = {};
  if (frontend.programs) {
    for (const program of Object.keys(frontend.programs)) {
      // According to this ticket CTT-2177, there might be null or undefined fields,
      // so let's filter out those.
      let truthyFields = (
        frontend.programs[program]?.enrollmentFields || []
      ).filter((field) => Boolean(field));
      for (const field of truthyFields) {
        const funcProgram = functions[program] || functions.default;
        addProgramEnrollmentFieldsFunctionToField(field, funcProgram);

        field.program = program;
      }
      truthyFields = sortObjects(truthyFields);
      for (const f of truthyFields) {
        if (f.children) f.children = sortObjects(f.children as SortItem[]);
      }
      enrollmentFields[program] = {
        meta: frontend.programs[program]?.meta,
        enrollmentFields: truthyFields,
      };
    }
  }
  return enrollmentFields;
}

/**
 * Builds a list of bulk message options for existing enrollment fields program meta.
 *
 * @param frontend
 * @param functions
 * @returns
 */
export function buildBulkMessageOptions(
  enrollmentFields: Record<string, ParsePanelLayoutEnrollmentFields>,
  availablePrograms: string[]
): { label: string; value: string }[] {
  // Remove this and replace it with a memoizer in the BulkMessage component, this is bad code
  const bulkMessageOptions = [{ label: "All", value: "all" }];
  for (const programName of availablePrograms) {
    if (
      enrollmentFields[programName] &&
      enrollmentFields[programName]?.meta &&
      enrollmentFields[programName]?.meta?.longName
    ) {
      bulkMessageOptions.push({
        label: enrollmentFields[programName]?.meta?.longName,
        value: programName,
      });
    }
  }
  return bulkMessageOptions;
}

type ParsePanelLayoutEnrollmentFields = {
  meta?: Record<string, any>;
  enrollmentFields: IField[];
};

type ParsePanelLayout = {
  editPatientLayout: {
    addPatientFields: IField[];
    enrollmentFields: Record<string, ParsePanelLayoutEnrollmentFields>;
  };
  viewPatientLayout: {
    patientStats: CardBase[];
    cards: IProgramFrontendConfigCards[];
  };
  panelLayout: {
    dashboardColumns: IDashboardColumn[];
    panelActions: PanelAction[];
    statusConfig: ProviderFrontend["dashboardConfig"]["statusConfig"];
    settingsConfig: ProviderFrontend["dashboardConfig"]["settings"];
    supportConfig: ProviderFrontend["dashboardConfig"]["supportModal"];
    bulkMessage: ProviderFrontend["dashboardConfig"]["bulkMessage"];
    concernsSidebar: ProviderFrontend["dashboardConfig"]["concernsSidebar"];
    userLayout: {
      id: string;
      accessor: string;
      Header: string;
      sortType: string;
      disableFilters?: boolean;
    }[];
    userActions: {
      name: string;
      title: string;
    }[];
  };
};

/**
 * Parse configurable UI options, for components like dashboard, view patient,
 * add/edit patient, and bulk message.
 *
 * This will mostly:
 * 1. Attach functions if any.
 * 2. Sort columns / fields as expected.
 */
export function parsePanelLayout(
  provider: ProviderProps,
  functions: Record<string, FrontendFunctions>
): {
  functions: Record<string, FrontendFunctions>;
  provider: ProviderProps;
  layout?: ParsePanelLayout;
} {
  if (!provider || !provider.frontend) {
    return {
      functions,
      provider,
    };
  }

  const { ...rest } = provider;
  const { frontend } = rest;

  // Attach the functions to the fields in dashboardColumns, sort them based on order
  let dashboardColumns = attachFunctionToDashboardColumns(frontend, functions);
  dashboardColumns = sortObjects(dashboardColumns);

  frontend.dashboardConfig.dashboardColumns.data = dashboardColumns;

  // Attach the functions to the fields in addPatientFields, sort them based on order
  let addPatientFields = attachFunctionToDashboardPatientFields(
    frontend,
    functions
  );
  addPatientFields = sortObjects(addPatientFields);

  frontend.dashboardConfig.addPatientFields.data = addPatientFields;

  // Attach the functions to the fields specified in dashboardConfigChildren, sort them based on order
  const dashboardConfigChildren: GetLocationOfFunctionKeys[] = [
    "panelActions",
    "addPatientFields",
    "patientStats",
  ];

  for (const key of dashboardConfigChildren) {
    const config = frontend.dashboardConfig[key];

    if (config) {
      let layout: LayoutType[] = attachFunctionToDashboardConfigCollections(
        config.data,
        key,
        functions
      );

      layout = sortObjects<LayoutType>(layout);

      // Using any to force setting layout to the config
      (frontend.dashboardConfig as any)[key].data = layout;
    }
  }

  // Add the function to the program card and sort by order
  const cards = attachProgramCardFunctionToProgramCard(frontend, functions);

  // Add the function to the enrollment fields and sort by order
  const enrollmentFields = attachFunctionToProgramEnrollmentFields(
    frontend,
    functions
  );

  // Organize the layout into 3 separate objects
  const layout = {
    editPatientLayout: {
      addPatientFields: frontend.dashboardConfig.addPatientFields.data,
      enrollmentFields,
    },
    viewPatientLayout: {
      patientStats: frontend.dashboardConfig.patientStats.data,
      cards,
    },
    panelLayout: {
      dashboardColumns: frontend.dashboardConfig.dashboardColumns.data,
      panelActions: frontend.dashboardConfig.panelActions?.data || [],
      statusConfig: frontend.dashboardConfig.statusConfig,
      settingsConfig: frontend.dashboardConfig.settings,
      supportConfig: frontend.dashboardConfig.supportModal,
      bulkMessage: frontend.dashboardConfig.bulkMessage,
      concernsSidebar: frontend.dashboardConfig.concernsSidebar,
      userLayout: DEFAULT_USER_LAYOUT,
      userActions: DEFAULT_USER_ACTIONS,
    },
  };

  const bulkMessageOptions = buildBulkMessageOptions(
    enrollmentFields,
    rest.availablePrograms
  );
  rest.bulkMessageOptions = bulkMessageOptions;
  rest.frontend = frontend;

  return {
    functions,
    provider: rest,
    layout,
  };
}

interface IFrontendFunctionCard {
  [key: string]: IFunction<IFrontendFunctionNames>;
}

export type ProviderDetailType = {
  provider: IProvider;
  logos: any;
  functions: Record<
    string,
    {
      dashboardConfig: IFrontendFunctionCard;
      enrollmentFields: {};
      cards: IFrontendFunctionCard;
    }
  >;
  layout?: ILayoutState;
};

export default function processAuthObject(
  data: PanelResponse
): ProviderDetailType {
  const { functions, provider, logos } = data;

  let panelLayout;
  try {
    panelLayout = parsePanelLayout(provider, functions);
  } catch (error) {
    clientLogger.error("Error parsing panel layout", { err: error });
    // deliberately construct a half-baked layout
    // code such as admin can operate with this because it doesn't rely on functions / layout
    // but the provider dashboard will enter an error state, which is desirable
    panelLayout = {
      provider,
    };
  }

  return {
    logos,
    ...(panelLayout as any),
  };
}
