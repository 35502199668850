import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  itemText: {
    display: "block",
    whiteSpace: "normal",
    wordBreak: "break-word",
  },
  heading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
  },
  secondary: {
    color: theme.palette.memoraGrey[55],
  },
  list: {
    paddingLeft: 20,
  },
  listItem: {
    marginBottom: 20,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  viewLinkContainer: {
    textAlign: "right",
  },
  viewLink: {
    color: theme.palette.blue[50],
    textDecoration: "underline",
    cursor: "pointer",
    verticalAlign: "middle",
  },
}));
