import type { ActivityBeacon } from "apis/logApi";
import { activityBeaconAPI } from "apis/logApi";
import { useAuthorizedUser } from "containers/auth/hooks/useAuthorizedUser";
import { useLatest } from "hooks/useLatest";
import * as React from "react";

import { useCurrentActivity } from "./state/useActivity";
import { useActiveStatus } from "./useActiveStatus";

/**
 * Time that beacons are valid for (if there is no beacon after this time, the user is assumed
 * to have quit the app).
 */
const BEACON_VALIDITY_MS = 30000;

const activeStatusProps = {
  // time during which the user may make no mouse / keyboard / touch movements and still be "active"
  // this is not necessarily equivalent to `BEACON_VALIDITY_MS`
  activityMs: 30000,
};

/** Component that sends client beacons.
 *  exported for testing
 */
export const ActivityTracker: React.FC = () => {
  const active = useActiveStatus(activeStatusProps);
  const activeRef = React.useRef(active);

  const beacon = useCurrentActivity();
  const beaconRef = React.useRef(beacon);

  const timeoutRenewRef = React.useRef<number>();
  const { hasAccess } = useAuthorizedUser(false);
  const hasAccessRef = useLatest(hasAccess);

  // Only send the beacon if we're authenticated
  const sendBeaconIfAuthed = React.useCallback(
    (beaconToCreate: CreateBeaconReq) => {
      if (!hasAccessRef.current) {
        return;
      }
      sendBeacon(beaconToCreate);
    },
    [hasAccessRef]
  );

  /**
   * Cached callback to tell the server we're still on the current beacon and active;
   * cached since this is always constant.
   */
  const renewCb = React.useCallback(() => {
    if (activeRef.current && beaconRef.current) {
      sendBeaconIfAuthed(beaconRef.current);
    }
  }, [sendBeaconIfAuthed]);

  // whenever the user becomes inactive, send inactive
  // whenever the user becomes active, send a new beacon
  React.useEffect(() => {
    if (beaconRef.current) {
      sendBeaconIfAuthed({
        ...beaconRef.current,
        ...(!active && { path: "INACTIVE", patientId: undefined }),
      });
    }
    activeRef.current = active;

    // we just logged a beacon - no need to log one for a while now
    window.clearTimeout(timeoutRenewRef.current);
    timeoutRenewRef.current = window.setTimeout(renewCb, BEACON_VALIDITY_MS);
  }, [active, renewCb, sendBeaconIfAuthed]);

  // whenever the beacon changes, send it to the server
  // because it changed, we know the user is active and we don't need to depend on `active`
  React.useEffect(() => {
    if (
      beacon?.path === beaconRef.current?.path &&
      beacon?.patientId === beaconRef.current?.patientId
    ) {
      // skip this beacon if it's a duplicate of the last one
      return;
    }

    if (beacon) {
      sendBeaconIfAuthed(beacon);
    }
    beaconRef.current = beacon;

    // we just logged a beacon - no need to log one for a while now
    window.clearTimeout(timeoutRenewRef.current);
    timeoutRenewRef.current = window.setTimeout(renewCb, BEACON_VALIDITY_MS);
  }, [beacon, renewCb, sendBeaconIfAuthed]);

  return null;
};

type CreateBeaconReq = Pick<ActivityBeacon, "path" | "patientId">;
const sendBeacon = (beacon: CreateBeaconReq) => {
  activityBeaconAPI({
    ...beacon,
    validMs: BEACON_VALIDITY_MS,
    date: new Date(),
  }).catch(() => {
    // we don't care to handle these errors
  });
};
