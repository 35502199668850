/* eslint-disable react/jsx-key */ // the props spread have keys
import { TableContainer } from "@material-ui/core";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import type { PluginHook } from "react-table";
import { useFilters, usePagination, useSortBy, useTable } from "react-table";

import { BodyCell } from "./components/BodyCell";
import { HeaderCell } from "./components/HeaderCell";
import { LoadIndicatorRow } from "./components/LoadIndicatorRow";
import { SimpleEmptyStateRow } from "./components/SimpleEmptyStateRow";
import { useStyles } from "./hooks/useStyles";
import type { ISimpleTableProps } from "./types";

const Table = <T extends object>(props: ISimpleTableProps<T>) => {
  const {
    columns,
    data,
    isLoading,
    localization,
    getColumnCellProps,
    getHeaderProps,
    customWidths,
    disablePagination = false,
    disableSortBy = false,
  } = props;

  const plugins: PluginHook<T>[] = [useFilters, useSortBy];
  if (!disablePagination) {
    plugins.push(usePagination);
  }

  const table = useTable(
    {
      columns,
      data,
      disableSortBy,
    },
    ...plugins
  );
  const { getTableProps, headerGroups, prepareRow, page, rows } = table;

  const visibleRows = disablePagination ? rows : page;

  const styles = useStyles();

  return (
    <div>
      <TableContainer>
        <MaUTable
          {...getTableProps()}
          classes={{ root: styles.tableContainer }}
        >
          <TableHead
            classes={{
              root: styles.tableHead,
            }}
          >
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <HeaderCell
                    column={column}
                    numSortsApplied={0}
                    {...column.getHeaderProps(
                      customWidths
                        ? {
                            style: {
                              minWidth: column.minWidth,
                              width: column.width,
                            },
                          }
                        : {}
                    )}
                    {...getHeaderProps?.(column)}
                    table={table}
                  />
                ))}
              </TableRow>
            ))}
            {isLoading && (
              <TableRow>
                <LoadIndicatorRow isLoading={isLoading} />
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {visibleRows.map((row) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <BodyCell
                      cell={cell}
                      columnCellProps={getColumnCellProps?.(cell.column)}
                      {...cell.getCellProps()}
                    />
                  ))}
                </TableRow>
              );
            })}

            {visibleRows.length === 0 && (
              <SimpleEmptyStateRow
                colSpan={columns.length}
                emptyMessage={localization.emptyMessage}
                isLoading={isLoading}
              />
            )}
          </TableBody>
        </MaUTable>
      </TableContainer>
    </div>
  );
};

export const SimpleTable = Table;
