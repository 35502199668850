import MoreVertIcon from "@material-ui/icons/MoreVert";

import type { DropdownMenuProps } from "./DropdownMenu";
import { DropdownMenu } from "./DropdownMenu";
import { TitleIconButton } from "./TitleIconButton";
import { TooltipIconButton } from "./TooltipIconButton";
import type { BaseMenuImplProps, MenuItemProps } from "./types";

type ToolbarItemsProps = {
  limit?: number;
  variant?: "default" | "icon";
  DropdownProps?: Partial<DropdownMenuProps>;
} & BaseMenuImplProps;

export function ToolbarItems({
  limit,
  items,
  variant,
  DropdownProps,
}: ToolbarItemsProps) {
  const baseItems = items;

  let itemsToShow = [...baseItems];
  let overflowItems: MenuItemProps[] = [];

  const shouldOverflow = limit && baseItems.length > limit;
  if (shouldOverflow) {
    itemsToShow = baseItems.slice(0, shouldOverflow ? limit - 1 : limit);
    overflowItems = baseItems.slice(limit - 1);
  }

  const ItemComponent =
    variant === "icon" ? TooltipIconButton : TitleIconButton;

  return (
    <>
      {itemsToShow.map((item) => (
        <ItemComponent key={item.id || item.title} {...item} />
      ))}
      {shouldOverflow && (
        <DropdownMenu
          {...DropdownProps}
          title="More"
          icon={<MoreVertIcon />}
          items={overflowItems}
        />
      )}
    </>
  );
}
