import { Button } from "../Button";
import type { MenuItemProps } from "./types";

export function TitleIconButton({ onClick, icon, title, id }: MenuItemProps) {
  return (
    <Button
      startIcon={icon}
      variant="tertiary"
      onClick={onClick}
      id={id}
      style={{
        flex: "0 0 auto", // don't allow shrinking of buttons in flex containers
      }}
    >
      {title}
    </Button>
  );
}
