import { useEmbedModeSettings } from "containers/app/embedMode/useEmbedModeSettings";
import { useTimeoutStore } from "containers/auth/hooks/useAuthorizedUser";
import {
  addOnAuthMissingHandler,
  removeOnAuthMissingHandler,
} from "helpers/axiosClient";
import { getLoginRoute } from "helpers/environment";
import { useCallback, useEffect } from "react";

export function useAuthMissingErrorHandling() {
  const { showTimeoutScreen } = useEmbedModeSettings();
  const setTimeoutScreen = useTimeoutStore((state) => state.setTimeout);

  const onAuthMissingError = useCallback(
    (status: number) => {
      if (showTimeoutScreen) {
        setTimeoutScreen();
        return;
      }

      let url = `${window.location.protocol}//${window.location.host}`;
      if (status === 401) {
        const curr = window.location.href;
        let redirect = curr.slice(
          Math.max(
            0,
            curr.indexOf(window.location.host) + window.location.host.length
          )
        );
        if (redirect.includes("#"))
          redirect = redirect.slice(0, Math.max(0, redirect.indexOf("#")));
        if (redirect.length > 0) {
          url = `${window.location.protocol}//${
            window.location.host
          }/${getLoginRoute()}?redirect=${encodeURIComponent(redirect)}`;
        }
      }
      if (!window.location.href.includes("?redirect=")) {
        window.location.assign(url);
      }
    },
    [setTimeoutScreen, showTimeoutScreen]
  );

  useEffect(() => {
    addOnAuthMissingHandler(onAuthMissingError);
    return () => {
      removeOnAuthMissingHandler(onAuthMissingError);
    };
  }, [onAuthMissingError]);
}
