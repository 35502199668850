import type { RoleName } from "@pillpal/api-types";
import { useMemo } from "react";
import type { RouteProps } from "react-router-dom";
import { Route } from "react-router-dom";

import { withAuthRequired } from "./withAuthRequired";

type RestrictedRouteProps = Partial<{ requiredRole: RoleName }> &
  RouteProps &
  Pick<Required<RouteProps>, "component">;

// loads user & provider
export function RestrictedRouteWithProvider({
  component,
  requiredRole,
  ...rest
}: RestrictedRouteProps) {
  const WrappedComponent = useMemo(
    () => withAuthRequired(component, requiredRole, true),
    [requiredRole, component]
  );
  return <Route {...rest} component={WrappedComponent} />;
}

// loads user only
export function RestrictedRoute({
  component,
  requiredRole,
  ...rest
}: RestrictedRouteProps) {
  const WrappedComponent = useMemo(
    () => withAuthRequired(component, requiredRole),
    [requiredRole, component]
  );
  return <Route {...rest} component={WrappedComponent} />;
}
