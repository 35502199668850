import type { ActivityInfo } from "infra/activityTracking/state/useActivity";
import { usePublishActivity } from "infra/activityTracking/state/useActivity";
import type { PropsWithChildren } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { v4 } from "uuid";

import { withNewTheme } from "../theme/providers";
import type {
  DialogConsumerContextData,
  DialogDefinition,
  LaunchDialogAction,
  UseDialogOpts,
} from "./useDialogs";
import { DialogConsumerContext } from "./useDialogs";

/**
 * Helper for dialogs which passes consistent disclosure interface and
 * into dialogs launched through context. Enforces only
 * one dialog is launched at a time
 */
export function DialogHost(props: PropsWithChildren<{}>) {
  const { children } = props;

  const activeDialogMap = useRef<ActiveDialogMap>(new Map());
  const [activeDialogIdStack, setActiveDialogIdStack] = useState<string[]>([]);

  const onOpen = useCallback((id: string) => {
    setActiveDialogIdStack((prev) => [...prev, id]);
  }, []);

  const onClose = useCallback((id: string) => {
    setActiveDialogIdStack((prev) => prev.filter((val) => val !== id));
  }, []);

  const destroyDialog = useCallback(
    (id: string) => {
      onClose(id);
      activeDialogMap.current.delete(id);
    },
    [onClose]
  );

  const anyIsOpen = useMemo(
    () => activeDialogIdStack.length > 0,
    [activeDialogIdStack]
  );

  const launchDialog = useCallback<LaunchDialogAction<any, any>>(
    (dialog, dialogProps, options) => {
      // rudimentary check for duplicates. Same dialog with same props and options by
      // reference. we assume this isn't desired
      const hasDuplicate = [...activeDialogMap.current.entries()].some(
        ([_id, state]) =>
          state.dialog === dialog &&
          state.props === dialogProps &&
          state.options === options
      );
      if (hasDuplicate) {
        throw new Error("Duplicate launch detected");
      }

      const id = v4();
      const activeDialog: ActiveDialogState = {
        id,
        dialog,
        props: dialogProps,
        options,
      };

      const promise = new Promise<any>((resolve) => {
        activeDialog.resolve = resolve;
      });

      activeDialogMap.current.set(id, activeDialog);

      onOpen(id);

      return promise;
    },
    [onOpen]
  );

  const context = useMemo<DialogConsumerContextData<any, any>>(
    () => ({
      launchDialog,
      isOpen: anyIsOpen,
    }),
    [launchDialog, anyIsOpen]
  );

  return (
    <DialogConsumerContext.Provider value={context}>
      {activeDialogIdStack.map((id) => {
        const state = activeDialogMap.current.get(id);
        if (!state) {
          return null;
        }

        return (
          <WrappedDialog
            key={id}
            state={state}
            isOpen
            onOpen={onOpen}
            onClose={onClose}
            destroyDialog={destroyDialog}
          />
        );
      })}
      {children}
    </DialogConsumerContext.Provider>
  );
}

type ActiveDialogState = {
  id: string;
  dialog: DialogDefinition<any, any>;
  resolve?(data: any): void;
  options?: UseDialogOpts;
  props?: any;
};
type ActiveDialogMap = Map<string, ActiveDialogState>;

type WrappedDialogProps = {
  state: ActiveDialogState;
  isOpen: boolean;
  onOpen(id: string): void;
  onClose(id: string): void;
  destroyDialog(id: string): void;
};

function WrappedDialog({
  state,
  onClose: internalOnClose,
  onOpen,
  isOpen,
  destroyDialog,
}: WrappedDialogProps) {
  const DialogComponent = useMemo(() => {
    const useNewTheme = state.options?.useNewTheme || false;
    return useNewTheme
      ? withNewTheme(state.dialog, { noBackground: true })
      : state.dialog;
  }, [state.dialog, state.options]);

  const publishActivityCore = usePublishActivity();

  const response = useRef<any | undefined>(undefined);
  const unpublishActivityRef = useRef<(() => void) | undefined>(undefined);

  const unpublish = useCallback(() => {
    if (unpublishActivityRef.current) {
      unpublishActivityRef.current();
      unpublishActivityRef.current = undefined;
    }
  }, [unpublishActivityRef]);

  useEffect(
    () => () => {
      unpublish();
    },
    [unpublish]
  );

  const onClose = useCallback(() => {
    internalOnClose(state.id);
    unpublish();
    const { resolve } = state;
    if (resolve) {
      resolve(response.current);
    }
    destroyDialog(state.id);
  }, [internalOnClose, state, destroyDialog, unpublish]);

  const onCloseWithCleanup = useCallback(
    (afterClose: () => void) => {
      onClose();
      afterClose();
    },
    [onClose]
  );

  const onProvideCloseData = useCallback((data: any) => {
    response.current = data;
  }, []);

  /**
   * Allows providing activity information to the activity stack. Will automatically
   * unpublish upon closure of the dialog. See usePublishActivity for thorough details.
   */
  const publishActivity = useCallback(
    (info: ActivityInfo) => {
      unpublish();
      unpublishActivityRef.current = publishActivityCore(info);
    },
    [publishActivityCore, unpublish]
  );

  return (
    <DialogComponent
      {...{
        isOpen,
        onOpen,
        onClose,
        onCloseWithCleanup,
        onProvideCloseData,
        publishActivity,
        // this trivial alias is fine as DialogComponent isn't really expected to
        // be rendered when isOpen = false. We could probably clean up the interface
        // but ill opt to just keep it mirroring Disclosure for now for standardization
        onToggle: onClose,
      }}
      {...state.props}
    />
  );
}
