import { MenuItem as MUIMenuItem, withStyles } from "@material-ui/core";

/**
 * Low level wrapper around MUI Menu Items with injected styles
 */

export const CoreMenuItem = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.memoraGrey["0"],
    color: theme.palette.memoraGrey["100"],
    display: "flex",
    gap: "16px",
    "&:hover": {
      backgroundColor: theme.palette.blue["10"],
      color: theme.palette.memoraGrey["100"],
    },
    "&$selected": {
      backgroundColor: theme.palette.memoraGrey["0"],
      color: theme.palette.memoraGrey["100"],
    },
    "&$selected:hover": {
      backgroundColor: theme.palette.blue["10"],
      color: theme.palette.memoraGrey["100"],
    },
  },
  selected: {
    backgroundColor: theme.palette.blue["10"],
    color: theme.palette.memoraGrey["100"],
  },
}))(MUIMenuItem);
