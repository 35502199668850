import type { DrawerProps } from "@material-ui/core";
import { Drawer as MUIDrawer } from "@material-ui/core";
import { AriaPopover } from "components/AriaDatePicker/AriaPopover";

import { useEnsureNewTheme } from "../theme";

export enum DrawerVariantType {
  PERMANENT = "permanent",
  PERSISTENT = "persistent",
  TEMPORARY = "temporary",
}

export enum AnchorVariantType {
  BOTTOM = "bottom",
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
}

/**
 * @deprecated Use DrawerWithSemanticMessage instead
 */
export const Drawer = ({ ...props }: DrawerProps) => {
  useEnsureNewTheme();
  return <MUIDrawer {...props} />;
};

/**
 * Supports the drawer layout / styles and semnantic messages in case of notification / blocking messages displayed within the drawer.
 *
 * We are trying to deprecate the old drawer to enforce consistency and make dev's life easier when implementing a drawer following our guidelines.
 *
 * In order to use this drawer please see:
 *
 * 1. BasicDrawerWithSemanticMessage.stories.tsx
 * 2. BasicDrawerWithNotificationSemanticMessage.stories.tsx
 *
 * We need to set the id to "portal-container" in order to make the drawer work with the AriaPopover component.
 * {@link AriaPopover}
 */
export const DrawerWithSemanticMessage = ({ ...props }: DrawerProps) => {
  useEnsureNewTheme();
  return (
    <MUIDrawer
      {...props}
      PaperProps={{
        ...props.PaperProps,
        style: {
          ...props.PaperProps?.style,
          width: props.PaperProps?.style?.width || "50%",
        },
      }}
    />
  );
};
