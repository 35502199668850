import { useOptionalCurrentUser } from "hooks/useCurrentUser";

// This method integrates with Heap Analytics to identify the user to heap (using the user._id),
// and push additional user information to the HEAP api allowing that user's sessions to be
// tracked as they use the site - works across browsers as long as the identify($token) matches.
export default function useHeapIntegration() {
  const user = useOptionalCurrentUser();

  // return early if there if the Heap integration is not enabled on this environment
  if (!process.env.REACT_APP_HEAP_ANALYTICS_KEY) {
    return;
  }

  // Identify the user to Heap, which attaches our unique user._id to
  // the session once the user has successfully logged in
  if (user) {
    // @ts-expect-error we have not appropriately fixed the global type to have heap
    window?.heap?.identify(user._id);
    // @ts-expect-error we have not appropriately fixed the global type to have heap
    window?.heap?.addUserProperties({
      name: user.fullName,
      email: user.email,
    });
  }

  // Store the activeProvider as an event to ensure that switching provider
  // through the UI is properly tracked in Heap.
  if (user?.activeProvider) {
    // @ts-expect-error we have not appropriately fixed the global type to have heap
    window?.heap.clearEventProperties();
    // @ts-expect-error we have not appropriately fixed the global type to have heap
    window?.heap.addEventProperties({
      activeProvider: user?.activeProvider,
    });
  }
}
