import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";

import type { BaseMenuImplProps } from "./types";

type NavMenuProps = BaseMenuImplProps & {};

export function NavMenu({ items }: NavMenuProps) {
  const classes = useStyles();
  return (
    <List>
      {items.map(({ selected, onClick, icon, title }, index) => (
        <ListItem
          selected={selected}
          button
          onClick={onClick}
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          classes={{
            root: classes.item,
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>
      ))}
    </List>
  );
}

const useStyles = makeStyles((theme) => ({
  item: {
    // TODO: move this into theme?
    "&.Mui-selected, &.Mui-selected:hover": {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.action.hover,
      borderLeft: `4px solid ${theme.palette.primary.main}`,
      paddingLeft: "12px", // magic number :(
    },
  },
}));
