import { InputLabel, makeStyles } from "@material-ui/core";
import type { SelectProps } from "@material-ui/core/Select";
import { Body } from "components/system/Body";

import { CoreMenuItem } from "../menus/MenuItem";
import { useEnsureNewTheme } from "../theme/providers";
import {
  CustomSvgIconArrow,
  StyledSelect,
  StyledSelectFormControl,
  StyledSelectInputBase,
} from "./sharedStyles";

export type Option = { label: string; value: string; icon?: React.ReactNode };

export type SingleSelectProps = Omit<SelectProps, "fullWidth" | "size"> & {
  fullWidth?: boolean;
  size?: "normal" | "large";
  noOptionsText?: string;
  emptyOptionText?: string;
  placeholder?: string;
  options: Option[];
  flex?: boolean;
};

const useStyles = makeStyles((theme) => ({
  menuItemSelected: {
    backgroundColor: `${theme.palette.blue[10]} !important`,
  },
  iconOpen: {
    color: theme.palette.memoraGrey[0],
  },
  noOptionDisabled: {
    background: "none !important",
    color: `${theme.palette.memoraGrey[100]} !important`,
  },
  formControlRoot: {
    "& .customIconBorder": {
      top: "1px",
      height: "94.5%",
    },
  },
  muiInputNormal: {
    padding: theme.spacing(1, 2),
  },
  muiInputLarge: {
    padding: theme.spacing(2, 2.5),
  },
  muiInputLabelNormal: {
    ...theme.typography.labelMedium,
    transform: "none !important",
    width: "auto",
    maxWidth: "calc(100% - 37px)",
    padding: "6px 0 0px 8px",
    color: theme.palette.memoraGrey[55],
    whiteSpace: "nowrap",
    overflow: "clip",
    textOverflow: "ellipsis",
  },
  muiInputLabelLarge: {
    ...theme.typography.labelLarge,
    transform: "none !important",
    maxWidth: "calc(100% - 37px)",
    margin: "10px",
    color: theme.palette.memoraGrey[55],
  },
  disabled: {
    border: "none !important",
    outline: "none !important",
    color: `${theme.palette.memoraGrey[28]} !important`,
    "&~ .customIconBorder": {
      border: "none !important",
      background: "none",
    },
  },
  inputLabelFocusedOpen: {
    color: `${theme.palette.memoraGrey[0]} !important`,
  },
}));

export const SingleSelect = ({
  options,
  noOptionsText = "No options",
  value,
  placeholder,
  error,
  disabled,
  fullWidth,
  emptyOptionText,
  size = "normal",
  flex = false,
  ...rest
}: SingleSelectProps) => {
  useEnsureNewTheme();

  const classes = useStyles();

  return (
    <StyledSelectFormControl
      size={size === "normal" ? "small" : "medium"}
      variant="outlined"
      fullWidth={fullWidth}
      disabled={disabled}
      classes={{
        root: classes.formControlRoot,
      }}
      error={error}
      className={flex ? "flex" : ""}
    >
      <InputLabel
        shrink={false}
        classes={{
          root:
            size === "normal"
              ? classes.muiInputLabelNormal
              : classes.muiInputLabelLarge,
          focused: classes.inputLabelFocusedOpen,
          disabled: classes.disabled,
        }}
      >
        <Body size={size === "normal" ? "medium" : "large"}>
          {!value && placeholder ? placeholder : ""}
        </Body>
      </InputLabel>
      <StyledSelect
        value={value}
        IconComponent={CustomSvgIconArrow}
        classes={{
          iconOpen: classes.iconOpen,
          disabled: classes.disabled,
        }}
        variant="outlined"
        input={
          <StyledSelectInputBase
            classes={{
              input:
                size === "normal"
                  ? classes.muiInputNormal
                  : classes.muiInputLarge,
            }}
          />
        }
        MenuProps={{
          style: {
            maxHeight: "40vh",
            minHeight: "12.5rem",
            width: 0,
          },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: -5,
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        {...rest}
      >
        {options.length === 0 && (
          <CoreMenuItem value="" disabled className={classes.noOptionDisabled}>
            <Body size={size === "normal" ? "medium" : "large"}>
              {noOptionsText || "None"}
            </Body>
          </CoreMenuItem>
        )}
        {options.length > 0 && emptyOptionText && emptyOptionText !== "" && (
          <CoreMenuItem value="">
            <Body size={size === "normal" ? "medium" : "large"}>
              {emptyOptionText}
            </Body>
          </CoreMenuItem>
        )}
        {options.map((option) => (
          <CoreMenuItem
            key={option.value}
            value={option.value}
            classes={{ selected: classes.menuItemSelected }}
          >
            {option.icon}
            <Body size={size === "normal" ? "medium" : "large"}>
              {option.label}
            </Body>
          </CoreMenuItem>
        ))}
      </StyledSelect>
    </StyledSelectFormControl>
  );
};

SingleSelect.displayName = "SingleSelect";
