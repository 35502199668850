import type { AlertProps as MuiAlertProps } from "@material-ui/lab";
import { Alert as MuiAlert } from "@material-ui/lab";
import { withStyles } from "@material-ui/styles";

import { useEnsureNewTheme } from "./theme";

const AlertStyled = withStyles({})(MuiAlert); // If needed to provide a certain base styling for Alerts in the future

export function Alert({ children, ...rest }: MuiAlertProps) {
  useEnsureNewTheme();
  const composedProps = {
    ...rest,
  };
  return <AlertStyled {...composedProps}>{children}</AlertStyled>;
}

Alert.displayName = "Alert";
