import { Box, TableCell, TableRow, useTheme } from "@material-ui/core";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import { Button } from "components/system/Button";
import { H4 } from "components/system/Headings";
import type { TableInstance } from "react-table";

import { useReset } from "./ResetButtonCell";

interface IEmptyStateRowProps<T extends object> {
  colSpan: number;
  isLoading?: boolean;
  emptyMessage: React.ReactNode;
  table: TableInstance<T>;
}

export function EmptyStateRow<T extends object>(props: IEmptyStateRowProps<T>) {
  const { colSpan, isLoading, emptyMessage, table } = props;
  const theme = useTheme();
  const { canReset, onReset } = useReset(table);

  return (
    <TableRow>
      <TableCell colSpan={colSpan} height="250px" align="center">
        {!isLoading && (
          <>
            <SearchOutlinedIcon
              style={{
                fontSize: "48px",
                color: theme.palette.memoraGrey[28],
                marginBottom: theme.spacing(0.5),
              }}
            />
            <H4>{emptyMessage}</H4>
            {canReset && (
              <Box mt={3}>
                <Button variant="secondary" onClick={onReset}>
                  Clear Search
                </Button>
              </Box>
            )}
          </>
        )}
      </TableCell>
    </TableRow>
  );
}
