import { makeHOCDisplayName } from "helpers/hoc";
import { useMemo } from "react";

import type { BaseMenuImplProps, MenuItemProps } from "../menus";
import type { CoreMenuProviderProps, MenuItemProvider } from "./types";

type MenuItemsProps<TRequiredData = {}> = {
  data: TRequiredData;
  items: MenuItemProvider<TRequiredData>[];
};

/**
 * Transforms the items prop on the wrapped component to accept MenuItemProvider[] instead of
 * MenuItemProps[], dynamically creates a wrapper HOC component which renders all of the providers,
 * gets the MenuItemProps they omit, and passes along to the inner implementation
 */
export function withMenuItemsProvider<TComponentProps, TRequiredData = {}>(
  ComponentToWrap: React.ComponentType<TComponentProps & BaseMenuImplProps>
): React.FC<MenuItemsProps<TRequiredData> & TComponentProps> {
  type WrapperProps = TComponentProps & {
    data: TRequiredData;
    items: MenuItemProvider<TRequiredData>[];
  };

  const WrappedComponent = ({ data, items, ...rest }: WrapperProps) => {
    const CombinedProvider = useMemo(
      () => createCombinedProvider(items),
      [items]
    );

    return (
      <CombinedProvider data={data}>
        {(menuItems) => (
          <ComponentToWrap
            items={menuItems}
            {...(rest as unknown as TComponentProps)}
          />
        )}
      </CombinedProvider>
    );
  };

  WrappedComponent.displayName = makeHOCDisplayName(
    "withMenuItemsProvider",
    ComponentToWrap
  );

  return WrappedComponent;
}

// Props for inner providers that pass along arrays instead of item | undefined
type MenuItemsProviderProps<TRequiredData> = CoreMenuProviderProps<
  TRequiredData,
  MenuItemProps[]
>;
export type MenuItemsProvider<TRequiredData> = React.FC<
  MenuItemsProviderProps<TRequiredData>
>;

// Takes a list of providers which pass a single menu item to their child function
// and makes a single component which passes an array of items to the child function
export function createCombinedProvider<TRequiredData>(
  providers: MenuItemProvider<TRequiredData>[]
): MenuItemsProvider<TRequiredData> {
  return providers.reduce(
    (AccumulatedProvider, CurrentProvider) =>
      withCombinedItems(AccumulatedProvider, CurrentProvider),
    EmptyProvider
  );
}

// Base case provider for when there are no menu items passes
const EmptyProvider: MenuItemsProvider<any> = ({ children }) => (
  <>{children([])}</>
);

// HOC to transform a SingleItemProvider into one that passes an array into its children
// function. Used with reduce to successively build this up in a chain. WrapperItemsProvider
// is passed to pull in the already "reduced" list of items
function withCombinedItems<TRequiredData>(
  WrapperItemsProvider: MenuItemsProvider<TRequiredData>,
  SingleItemProvider: MenuItemProvider<TRequiredData>
) {
  // expects EmptyProvider to potentially be passed as the first provider
  // since we want to avoid unnecessary components, we'll omit that one
  // if passed
  const ItemArrayProvider: MenuItemsProvider<TRequiredData> = ({
    data,
    children,
  }) =>
    WrapperItemsProvider !== EmptyProvider ? (
      <WrapperItemsProvider data={data}>
        {(prevItems) => (
          <SingleItemProvider data={data}>
            {(item) => renderChildren(children, prevItems, item)}
          </SingleItemProvider>
        )}
      </WrapperItemsProvider>
    ) : (
      <SingleItemProvider data={data}>
        {(item) => renderChildren(children, [], item)}
      </SingleItemProvider>
    );
  ItemArrayProvider.displayName = makeHOCDisplayName(
    "withCombinedItems",
    SingleItemProvider
  );
  return ItemArrayProvider;
}

// omits currentItem from being passed to the children function if its not defined
function renderChildren(
  children: MenuItemsProviderProps<any>["children"],
  previousItems: MenuItemProps[],
  currentItem: MenuItemProps | undefined
) {
  if (currentItem) {
    return children([...previousItems, currentItem]);
  }
  return children(previousItems);
}
