import type { Theme } from "@material-ui/core";
import { makeStyles, OutlinedInput, withStyles } from "@material-ui/core";

export const sharedInputRootStyles = (theme: Theme) => ({
  backgroundColor: theme.palette.memoraGrey[10],

  "&:hover": {
    backgroundColor: theme.palette.memoraGrey[12],
  },
  "&:hover $notchedOutline": {
    borderColor: theme.palette.memoraGrey[28],
    borderWidth: 2,
  },
  "&$focused": {
    backgroundColor: theme.palette.memoraGrey[0],
  },
  "&$focused $notchedOutline": {
    borderColor: theme.palette.blue[41],
    borderWidth: 2,
  },
  "&$error": {
    backgroundColor: theme.palette.memoraGrey[0],
  },
  "&$error $notchedOutline": {
    borderColor: theme.palette.red[50],
    borderWidth: 2,
  },
  "&$disabled $notchedOutline": {
    borderWidth: 0,
  },
});

export const sharedInputInputStyles = (theme: Theme) => ({
  height: "auto",

  "&:hover::placeholder": {
    color: theme.palette.memoraGrey[75],
  },
  "&:not(disabled)::placeholder": {
    color: theme.palette.memoraGrey[55],
    opacity: 1,
  },
  "&:disabled::placeholder": {
    color: theme.palette.memoraGrey[28],
  },
  "&:placeholder-shown": {
    textOverflow: "ellipsis",
  },
});

const OutlinedInputStyled = withStyles((theme) => ({
  root: sharedInputRootStyles(theme),
  input: sharedInputInputStyles(theme),
  adornedStart: {
    paddingLeft: theme.spacing(0.5),
  },
  adornedEnd: {
    paddingRight: theme.spacing(0.5),
  },
  disabled: {},
  error: {},
  focused: {},
  multiline: {
    padding: 0,
  },
  notchedOutline: {
    borderColor: theme.palette.memoraGrey[18],
  },
}))(OutlinedInput);

const useStyles = makeStyles((theme) => ({
  textArea: {
    padding: "10px 12px",
  },
  expandable: {
    resize: "both",
    "&:disabled": {
      resize: "none",
    },
  },
  muiInputNormal: {
    ...theme.typography.labelMedium,
  },
  muiInputLarge: {
    ...theme.typography.labelLarge,
  },
}));

export { OutlinedInputStyled, useStyles };
