import { useChatNavigationStore } from "containers/chat/hooks/useChatNavigationState";
import { useLocation } from "react-router";

import { normalizedPathSelector } from "./routerSelectors";

/** Select the patientId path param if current path is the patient dashboard. */
export const patientDashboardIdSelector = (path: string) => {
  if (path.toLowerCase().startsWith("/dashboard/patient/")) {
    const patientId = path.split("/").filter((p) => !!p)[2];
    // For /dashboard/patient/add, explicitly set patientId to undefined
    return patientId?.toLowerCase() === "add" ? undefined : patientId;
  }
  return undefined;
};
/** Return an object appropriate for logging an activity beacon. */
export const useBeaconSelector = () => {
  const chatPage = useChatNavigationStore((chatStore) => chatStore);
  const location = useLocation();

  const normalizedPath = normalizedPathSelector(location.pathname);
  const patientDashboardId = patientDashboardIdSelector(location.pathname);

  if (omittedPaths.find((p) => normalizedPath.startsWith(p))) {
    // Do not send beacons for specific paths, like internal tools
    return undefined;
  }

  if (chatPage.visible) {
    return {
      path: "LiveChat",
      patientId: chatPage.patientId || undefined,
    };
  }

  return {
    path: normalizedPath,
    patientId: patientDashboardId,
  };
};

const omittedPaths = ["/tools"] as const;
