import type { TabProps as MUITabProps } from "@material-ui/core";
import { makeStyles, Tab as MUITab, withStyles } from "@material-ui/core";

import { useEnsureNewTheme } from "./theme";
import { Typography } from "./Typography";

export type TabSize = "large" | "medium" | "small";
export type TabProps = { size?: TabSize } & MUITabProps;

const getEmphasisVariant = (size: TabSize) => {
  switch (size) {
    case "medium":
      return "emphasisMedium";
    case "small":
      return "emphasisSmall";
    default:
      return "emphasisLarge";
  }
};

const UnstyledTab = ({ size = "large", label, ...rest }: TabProps) => {
  useEnsureNewTheme();

  const styles = useStyles();

  return (
    <MUITab
      label={
        <Typography variant={getEmphasisVariant(size)}>{label}</Typography>
      }
      className={styles[size]}
      disableRipple
      {...rest}
    />
  );
};

export const Tab = withStyles((theme) => ({
  root: {
    color: theme.palette.memoraGrey[55],
    opacity: "1",
    minWidth: "0 !important", // need important here to override viewport specific things
    "&:hover": {
      color: theme.palette.memoraGrey[100],
    },
    "&.Mui-focusVisible": {
      color: theme.palette.memoraGrey[100],
    },
    "&:disabled": {
      color: theme.palette.memoraGrey[28],
    },
    padding: "0",
  },
  selected: {
    color: theme.palette.memoraGrey[100],
  },
}))(UnstyledTab);

const useStyles = makeStyles(() => ({
  large: {
    margin: "0 10px",
    minHeight: 48,
  },
  medium: {
    margin: "0 8px",
    minHeight: 36,
  },
  small: {
    margin: "0 6px",
    minHeight: 24,
  },
}));
