/* eslint-disable react/no-array-index-key */
import type { SizedTypographyProps } from "components/system/Body";
import { Body } from "components/system/Body";
import type { LinkProps } from "components/system/MuiTypography";
import { Link } from "components/system/MuiTypography";
import { useEmbedModeSettings } from "containers/app/embedMode/useEmbedModeSettings";
import { findPhoneNumbersInText } from "libphonenumber-js";
import linkify from "linkify-it";
import { memo } from "react";

type LinkifyProps = {
  children?: string;
  className?: string;
  findPhones?: boolean;
  linkProps?: LinkProps;
  nonLinkProps?: SizedTypographyProps;
};
export const Linkify = memo(
  ({
    children,
    className,
    linkProps,
    nonLinkProps,
    findPhones = false,
  }: LinkifyProps) => {
    const { singlePatientOnly } = useEmbedModeSettings();
    if (!children) {
      return null;
    }

    const chunks = tokenize(children, findPhones).map((token, index) => {
      if (token.type === "link" && !singlePatientOnly) {
        return (
          <Link
            key={index}
            href={token.url}
            target="_blank"
            underline="always"
            className={className}
            variant="bodyLarge"
            {...linkProps}
          >
            {token.value}
          </Link>
        );
      }
      return (
        <Body key={index} className={className} {...nonLinkProps}>
          {token.value}
        </Body>
      );
    });

    return <span>{chunks}</span>;
  }
);
Linkify.displayName = "Linkify";

type Token =
  | {
      type: "string";
      value: string;
    }
  | {
      type: "link";
      value: string;
      url: string;
    };

const linkifyInstance = linkify();

// exported for testing
export function tokenize(text: string, findPhones: boolean = true): Token[] {
  const tokens: Token[] = [];
  getLinkMatches(text, findPhones).forEach((match, index, matches) => {
    if (index === 0) {
      const preamble = text.slice(0, match.startsAt);
      if (preamble.length > 0) {
        tokens.push({
          type: "string",
          value: preamble,
        });
      }
    }
    tokens.push({
      type: "link",
      value: match.value,
      url: match.url,
    });
    const postamble = text.slice(
      match.endsAt,
      index === matches.length - 1 ? undefined : matches[index + 1]?.startsAt
    );
    if (postamble.length > 0) {
      tokens.push({
        type: "string",
        value: postamble,
      });
    }
  });
  if (tokens.length === 0) {
    return [
      {
        type: "string",
        value: text,
      },
    ];
  }
  return tokens;
}

type LinkToken = {
  startsAt: number;
  endsAt: number;
  value: string;
  url: string;
};

function getLinkMatches(text: string, findPhones: boolean): LinkToken[] {
  // get matches from each library and normalize them
  // when doing phone matches, insert into other array, making sure we don't overlap something that already exists

  // API docs suggest doing test first
  // http://markdown-it.github.io/linkify-it/doc/#LinkifyIt.prototype.match
  const linkifyMatches = !linkifyInstance.test(text)
    ? []
    : linkifyInstance.match(text) || [];
  const normalizedTokens = linkifyMatches.map<LinkToken>((match) => ({
    startsAt: match.index,
    endsAt: match.lastIndex,
    value: match.text,
    url: match.url,
  }));

  if (findPhones) {
    // insert phones at the end
    const phoneMatches = findPhoneNumbersInText(text, { defaultCountry: "US" });
    phoneMatches.forEach((match) => {
      const phoneToken = {
        startsAt: match.startsAt,
        endsAt: match.endsAt,
        value: text.slice(match.startsAt, match.endsAt),
        url: match.number.getURI(),
      };
      if (phoneCanBeInserted(normalizedTokens, phoneToken)) {
        normalizedTokens.push(phoneToken);
      }
    });

    normalizedTokens.sort((a, b) => a.startsAt - b.startsAt);
  }

  return normalizedTokens;
}

function phoneCanBeInserted(
  tokens: LinkToken[],
  tokenToInsert: LinkToken
): boolean {
  // there isn't some token that overlaps in any way
  // i hate this algorithm and it confuses me all the time
  // https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap/325964#325964
  return !tokens.some(
    (searchToken) =>
      Math.max(tokenToInsert.startsAt, searchToken.startsAt) <
      Math.min(tokenToInsert.endsAt - 1, searchToken.endsAt - 1) // end of range is not inclusive
  );
}
