import * as Sentry from "@sentry/react";
import {
  SCENARIO_FAIL,
  SCENARIO_START,
  SCENARIO_SUCCESS,
} from "logging/constants";
import { clientLogger } from "logging/logger";
import { v4 } from "uuid";

/** Map of scenarioId to their metadata. */
const scenarios: Record<
  string,
  {
    eventName: string;
    start: Date;
    tx: ReturnType<typeof Sentry.startTransaction>;
  }
> = {};

/** Starts a scenario, returning the ID for future use on completion. */
export const startScenario = (eventName: string) => {
  const scenarioId = v4();
  const commonInfo = { eventName, scenarioId };

  clientLogger.info(SCENARIO_START, commonInfo);
  const scenario = {
    ...commonInfo,
    start: new Date(),
    tx: Sentry.startTransaction({ name: eventName }),
  };
  Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(scenario.tx));
  scenarios[scenarioId] = scenario;
  return scenarioId;
};

/** Given a scenario ID, tracks scenario completion. */
export const completeScenario = (scenarioId: string) => {
  const scenario = scenarios[scenarioId];
  if (scenario) {
    const { start, tx, ...commonInfo } = scenario;
    clientLogger.info(SCENARIO_SUCCESS, {
      ...commonInfo,
      duration: getElapsedMs(start),
    });
    tx?.setStatus("ok");
    tx?.finish();
    delete scenarios[scenarioId];
  }
};

/** Given a scenario ID, tracks scenario failure. */
export const failScenario = (scenarioId: string) => {
  const scenario = scenarios[scenarioId];
  if (scenario) {
    const { start, tx, ...commonInfo } = scenario;
    clientLogger.info(SCENARIO_FAIL, {
      ...commonInfo,
      duration: getElapsedMs(start),
    });
    tx.setStatus("unknown_error");
    tx.finish();
    delete scenarios[scenarioId];
  }
};

/** Given a promise, log start and success / fail events for it. */
export async function trackScenario<T>(
  promise: Promise<T>,
  eventName: string
): Promise<T | undefined> {
  const scenarioId = startScenario(eventName);

  let result: T | undefined;
  try {
    result = await promise;
    completeScenario(scenarioId);
  } catch (error) {
    failScenario(scenarioId);
    Sentry.captureException(error);
  }
  return result;
}

function getElapsedMs(startDate: Date) {
  const now = new Date();
  return now.valueOf() - startDate.valueOf();
}
