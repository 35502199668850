import { Grid } from "@material-ui/core";
import classNames from "classnames";
import { type FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { Body } from "../Body";
import { CallToAction } from "../CallToAction";
import { H4, H5, H6 } from "../Headings";
import { RenderShowMoreLink } from "./RenderShowMoreLink";
import { useStyles } from "./sharedStyles";

type ReferenceInput = {
  displayName: string;
  _id: string;
};

export type InstanceListProps = {
  references: ReferenceInput[];
  title: string;
  itemLimit?: number;
  getRedirectUrl?: (id: string) => string;
  showMore?: boolean;
  headlineVariant?: "normal" | "small" | "normal-secondary" | "small-secondary";
  hideContent?: boolean;
  testIds?: {
    count?: string;
  };
  className?: string;
};

export const InstanceList: FC<InstanceListProps> = ({
  references = [],
  title,
  itemLimit = 3,
  getRedirectUrl,
  showMore = false,
  headlineVariant = "normal",
  hideContent = false,
  testIds,
  className,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [displayMoreItems, setDisplayMoreItems] = useState<number>(itemLimit);
  const [itemsToDisplayed, setItemsToDisplayed] = useState<ReferenceInput[]>(
    references.slice(0, itemLimit)
  );

  useEffect(() => {
    if (references.length > 0 && displayMoreItems !== itemsToDisplayed.length) {
      setItemsToDisplayed(references.slice(0, displayMoreItems));
    }
  }, [displayMoreItems, itemsToDisplayed.length, references]);

  const returnTitle = () => {
    const TitleComponent =
      headlineVariant === "small" || headlineVariant === "small-secondary"
        ? H5
        : H4;
    const TotalCompoennt =
      headlineVariant === "small" || headlineVariant === "small-secondary"
        ? H6
        : H5;

    return (
      <>
        <TitleComponent>{title}</TitleComponent>
        <TotalCompoennt data-testid={testIds?.count ?? "instance-count"}>
          {references.length} total
        </TotalCompoennt>
      </>
    );
  };

  return (
    <div className={className}>
      <div
        className={classNames(classes.heading, {
          [classes.secondary]:
            headlineVariant === "normal-secondary" ||
            headlineVariant === "small-secondary",
        })}
      >
        {returnTitle()}
      </div>
      {!hideContent && (
        <>
          <ul className={classes.list}>
            {itemsToDisplayed.map((item) => (
              <li className={classes.listItem} key={item._id}>
                <Grid container>
                  <Grid item xs={11}>
                    <Body size="large" className={classes.itemText}>
                      {item.displayName}
                    </Body>
                  </Grid>
                  <Grid xs={1} className={classes.viewLinkContainer}>
                    <CallToAction
                      size="medium"
                      className={classes.viewLink}
                      onClick={() => {
                        const path = getRedirectUrl?.(item._id);
                        if (path) {
                          history.push(path);
                        }
                      }}
                    >
                      View
                    </CallToAction>
                  </Grid>
                </Grid>
              </li>
            ))}
          </ul>
          {showMore && (
            <RenderShowMoreLink
              totalItems={references.length}
              displayedItems={displayMoreItems}
              itemLimit={itemLimit}
              setDisplayedItems={setDisplayMoreItems}
            />
          )}
        </>
      )}
    </div>
  );
};

export default InstanceList;
