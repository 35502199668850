import { Fade, LinearProgress } from "@material-ui/core";
import { useDebounceValue } from "hooks/useDebounce";

import { useStyles } from "../hooks/useStyles";

interface ILoadIndicatorRowProps {
  isLoading?: boolean;
}

export function LoadIndicatorRow(props: ILoadIndicatorRowProps) {
  const { isLoading } = props;

  const styles = useStyles();
  const isLoadingDebounced = useDebounceValue(isLoading, 300, false);

  return (
    <Fade in={isLoadingDebounced}>
      <div className={styles.loaderCell} data-testid="tableLoader">
        <LinearProgress />
      </div>
    </Fade>
  );
}
