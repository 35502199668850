import type { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { ICON_DEF } from "components/system/tokens/icons";

const strings = {
  errorText: "ERROR",
};

interface ColumnErrorProps {
  error?: Error;
}

/** Fallback component to be rendered in place of a widget body, when an error is caught. */
// eslint-disable-next-line handle-callback-err
export const ColumnError = ({ error }: ColumnErrorProps) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <span>{error?.message || strings.errorText}</span>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
  },
  icon: {
    fontSize: ICON_DEF.medium.fontSize,
    marginBottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}));
