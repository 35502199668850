import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useOptionalCurrentProvider } from "hooks/useCurrentProvider";
import * as React from "react";

import { changeLanguage, useCurrentLocale } from "./localization";

interface ILocalizationProvider {
  children: React.ReactNode;
}

export default function LocalizationProvider(props: ILocalizationProvider) {
  const { children } = props;
  const locale = useCurrentLocale();

  const provider = useOptionalCurrentProvider();
  const providerLocale = provider?.locale;

  React.useEffect(() => {
    changeLanguage(providerLocale);
  }, [providerLocale]);
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
      {children}
    </MuiPickersUtilsProvider>
  );
}
