import type { AccordionSummaryProps as MuiAccordionSummaryProps } from "@material-ui/core";
import {
  AccordionSummary as MuiAccordionSummary,
  withStyles,
} from "@material-ui/core";

import { useEnsureNewTheme } from "./theme/providers";

const AccordionSummaryStyled = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1.5, 3),
    "&$expanded": {
      minHeight: 0,
    },
  },
  content: {
    margin: 0,
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {},
}))(MuiAccordionSummary);

export function AccordionSummary({
  children,
  ...rest
}: MuiAccordionSummaryProps) {
  useEnsureNewTheme();
  const composedProps = {
    ...rest,
  };
  return (
    <AccordionSummaryStyled {...composedProps}>
      {children}
    </AccordionSummaryStyled>
  );
}

AccordionSummary.displayName = "AccordianSummary";
