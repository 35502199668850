// Constants used for telemetry events

export const BUTTON_PRESS = "BUTTON_PRESS";
export const CUSTOM_EVENT = "CUSTOM_EVENT";
export const SCENARIO_START = "SCENARIO_START";
export const SCENARIO_SUCCESS = "SCENARIO_SUCCESS";
export const SCENARIO_FAIL = "SCENARIO_FAIL";
export const APP_CRASH = "APP_CRASH";
export const WIDGET_CRASH = "WIDGET_CRASH";
export const TABLE_COLUMN_CRASH = "TABLE_COLUMN_CRASH";
