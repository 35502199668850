import type { SvgIconProps } from "@material-ui/core";
import { Badge, Tooltip } from "@material-ui/core";
import ArrowDownwardOutlinedIcon from "@material-ui/icons/ArrowDownwardOutlined";
import ArrowUpwardOutlinedIcon from "@material-ui/icons/ArrowUpwardOutlined";
import ImportExportOutlinedIcon from "@material-ui/icons/ImportExportOutlined";
import { IconButton } from "components/system/IconButton";
import { forwardRef, useCallback } from "react";
import type { ColumnInstance } from "react-table";

export interface SortOptionsProps<T extends object> {
  column: ColumnInstance<T>;
  numSortsApplied: number;
}

export function SortOptions<T extends object>({
  column,
  numSortsApplied,
}: SortOptionsProps<T>) {
  const sortState = getColumnSortState(column);

  const onClick = useCallback(() => {
    const nextState = nextSortState[sortState];
    applySortStateToColumn(column, nextState);
  }, [sortState, column]);

  return (
    <IconButton
      size="small"
      variant="tertiary"
      style={{ marginLeft: 3 }}
      onClick={onClick}
    >
      <Badge
        invisible={sortState === "unsorted" || numSortsApplied <= 1}
        badgeContent={column.sortedIndex + 1}
      >
        <SortIconWithTooltip fontSize="small" sort={sortState} />
      </Badge>
    </IconButton>
  );
}

type SortState = "unsorted" | "asc" | "desc";

function getColumnSortState(column: ColumnInstance<any>): SortState {
  if (!column.isSorted) {
    return "unsorted";
  }
  if (column.isSortedDesc) {
    return "desc";
  }
  return "asc";
}

function applySortStateToColumn(
  column: ColumnInstance<any>,
  nextState: SortState
) {
  if (nextState === "unsorted") {
    column.clearSortBy();
    return;
  }
  if (nextState === "asc") {
    column.toggleSortBy(false, true);
    return;
  }
  if (nextState === "desc") {
    column.toggleSortBy(true, true);
  }
}

const nextSortState: Record<SortState, SortState> = {
  unsorted: "asc",
  asc: "desc",
  desc: "unsorted",
};

type SortIconProps = SvgIconProps & { sort: SortState };

function SortIconWithTooltip(props: SortIconProps) {
  const { sort } = props;
  let title = "Default";
  if (sort === "asc") {
    title = "Ascending";
  } else if (sort === "desc") {
    title = "Descending";
  }

  return (
    <Tooltip title={title}>
      <SortIcon {...props} />
    </Tooltip>
  );
}

const SortIcon = forwardRef(({ sort, ...rest }: SortIconProps, ref) => {
  if (sort === "unsorted") {
    return <ImportExportOutlinedIcon innerRef={ref} {...rest} />;
  }
  if (sort === "asc") {
    return <ArrowUpwardOutlinedIcon innerRef={ref} {...rest} />;
  }
  return <ArrowDownwardOutlinedIcon innerRef={ref} {...rest} />;
});
SortIcon.displayName = "SortIcon";
