import * as Sentry from "@sentry/react";
import { BUTTON_PRESS, CUSTOM_EVENT } from "logging/constants";
import { clientLogger } from "logging/logger";

/** Log an event for a button being clicked. */
export const trackButton = (
  eventName: string,
  payload: object | undefined = undefined
) => {
  clientLogger.info(BUTTON_PRESS, { eventName, payload });
  Sentry.addBreadcrumb({
    category: "ui.click",
    message: eventName,
    level: "info",
    data: payload,
  });
};

/** Log a custom event. Payload is an object that should not contain PHI. */
export const trackEvent = (
  eventName: string,
  payload: object | undefined = undefined
) => {
  clientLogger.info(CUSTOM_EVENT, { eventName, payload });
  Sentry.addBreadcrumb({
    category: "event",
    message: eventName,
    level: "info",
    data: payload,
  });
};
