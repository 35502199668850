import type {
  CheckboxProps as MuiCheckboxProps,
  FormControlLabelProps as MuiFormControlLabelProps,
  SvgIconProps,
} from "@material-ui/core";
import {
  Checkbox as MuiCheckbox,
  createStyles,
  FormControlLabel as MuiFormControlLabel,
  makeStyles,
  SvgIcon,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { Body } from "components/system/Body";
import { forwardRef } from "react";

import { useEnsureNewTheme } from "./theme/providers";

const CheckboxStyled = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(MuiCheckbox);

const FormControlLabelStyled = withStyles((theme) => ({
  root: {
    margin: 0,
  },
  label: {
    paddingLeft: 8,
    "&$disabled": {
      color: theme.palette.memoraGrey[40],
    },
    lineHeight: "20px",
  },
  disabled: {},
}))(MuiFormControlLabel);

const SvgRect = () => (
  <rect
    x="0.5"
    y="0.5"
    width="17"
    height="17"
    rx="3.5"
    fill="transparent"
    stroke="currentColor"
  />
);

const useStyles = makeStyles((theme) =>
  createStyles({
    icon: {
      width: 18,
      height: 18,
      "input:checked ~ &": {
        color: theme.palette.blue[41],
      },
      "input:disabled ~ &": {
        color: theme.palette.memoraGrey[28],
      },
    },
  })
);

const CustomUncheckedIcon = (props: SvgIconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon {...props} className={classes.icon} viewBox="0 0 18 18">
      <SvgRect />
    </SvgIcon>
  );
};

const CustomCheckedIcon = (props: SvgIconProps) => {
  const classes = useStyles();
  return (
    <SvgIcon {...props} className={classes.icon} viewBox="0 0 18 18">
      <SvgRect />
      <path
        d="M 6.81353 12.10067 L 3.968732 9.30201 L 3 10.24832 L 6.81353 14 L 15 5.946309 L 14.0381 5 L 6.81353 12.10067 Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export type CheckboxProps = Omit<
  MuiCheckboxProps,
  "checkedIcon" | "color" | "icon"
> & {
  label?: string;
  labelProps?: MuiFormControlLabelProps;
};

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ label, labelProps, ...rest }, ref) => {
    useEnsureNewTheme();
    const checkbox = (
      <CheckboxStyled
        {...rest}
        ref={ref}
        color="default"
        icon={<CustomUncheckedIcon />}
        checkedIcon={<CustomCheckedIcon />}
      />
    );
    if (label) {
      return (
        <FormControlLabelStyled
          control={checkbox}
          label={<Body size="medium">{label}</Body>}
          {...labelProps}
        />
      );
    }

    return checkbox;
  }
);
Checkbox.displayName = "Checkbox";
