import type { AccordionProps as MuiAccordionProps } from "@material-ui/core";
import { Accordion as MuiAccordion } from "@material-ui/core";
import withStyles from "@material-ui/styles/withStyles";

import { useEnsureNewTheme } from "./theme/providers";

const AccordionStyled = withStyles({})(MuiAccordion);

export function Accordion({ children, ...rest }: MuiAccordionProps) {
  useEnsureNewTheme();
  const composedProps = {
    ...rest,
  };
  return (
    <AccordionStyled {...composedProps} square>
      {children}
    </AccordionStyled>
  );
}

Accordion.displayName = "Accordion";
