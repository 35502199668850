import type { PaperProps } from "@material-ui/core";
import { Box, createStyles, makeStyles, Paper } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { Body } from "../Body";

const useStyles = makeStyles((theme) =>
  createStyles({
    infoOption: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1, 2),
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.memoraGrey[100],
    },
    successOption: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1, 2),
      color: theme.palette.green[55],
    },
    successRoot: {
      marginTop: theme.spacing(1),
    },
    infoRoot: {},
  })
);

type MultiAutoCompletePaperProps = PaperProps & {
  infoLabel: string;
  showFixedLabel: boolean;
  labelType?: string;
};

export const MultiAutoCompletePaper = ({
  infoLabel,
  showFixedLabel,
  children,
  labelType = "info",
  ...rest
}: MultiAutoCompletePaperProps) => {
  const classes = useStyles();
  return (
    <Paper
      {...rest}
      classes={{
        root: labelType === "info" ? classes.infoRoot : classes.successRoot,
      }}
    >
      {children}
      {showFixedLabel && labelType === "info" && (
        <div className={classes.infoOption}>
          <Box mr={1} mt={1}>
            <InfoOutlinedIcon color="primary" />
          </Box>
          <Body size="medium">{infoLabel}</Body>
        </div>
      )}
      {showFixedLabel && labelType === "success" && (
        <div className={classes.successOption}>
          <Box mr={1} mt={1}>
            <CheckIcon />
          </Box>
          <Body size="medium">{infoLabel}</Body>
        </div>
      )}
    </Paper>
  );
};
