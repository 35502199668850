import { useMemo } from "react";

import type { EmbedModeSettings } from "./types";
import { getEmbedKey } from "./useStoreEmbedKey";

export const useEmbedModeSettings = () => {
  const embedKey = getEmbedKey();

  return useMemo(() => {
    if (!embedKey || ALLOWED_EMBED_KEYS.indexOf(embedKey) === -1) {
      return EMBED_MODE_OFF;
    }

    return EMBED_MODES[embedKey]!;
  }, [embedKey]);
};

type EmbedOptions = {
  [key: string]: EmbedModeSettings;
};

const EMBED_MODES: EmbedOptions = {
  epic: {
    hideNavbar: true,
    showTimeoutScreen: true,
    showSupportButton: true,
    singlePatientOnly: true,
  },
  xealth: {
    hideNavbar: true,
    showTimeoutScreen: true,
    showSupportButton: true,
    singlePatientOnly: true,
  },
  cerner: {
    hideNavbar: true,
    showTimeoutScreen: true,
    showSupportButton: true,
    singlePatientOnly: true,
  },
};
const ALLOWED_EMBED_KEYS: (keyof EmbedOptions)[] = Object.keys(EMBED_MODES);

const EMBED_MODE_OFF: EmbedModeSettings = {
  hideNavbar: false,
  showTimeoutScreen: false,
  showSupportButton: false,
  singlePatientOnly: false,
};
