import { Tooltip } from "@material-ui/core";

import type { IconButtonProps } from "../IconButton";
import { IconButton } from "../IconButton";
import type { MenuItemProps } from "./types";

type TooltipIconButtonProps = Omit<MenuItemProps, "title"> & IconButtonProps;

// TODO: need to pass through all bind triggers? Seems to work as is
export function TooltipIconButton({
  icon,
  title,
  ...rest
}: TooltipIconButtonProps) {
  const inner = (
    <IconButton variant="tertiary" {...rest}>
      {icon}
    </IconButton>
  );
  if (!title) {
    return inner;
  }

  return (
    <Tooltip title={title} aria-label={title} arrow>
      {inner}
    </Tooltip>
  );
}
