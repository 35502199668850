export const ICON_DEF = {
  small: {
    fontSize: 12,
    height: 16,
    width: 16,
  },
  medium: {
    fontSize: 16,
    height: 20,
    width: 20,
  },
  large: {
    fontSize: 18,
    height: 24,
    width: 24,
  },
} as const;
