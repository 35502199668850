import {
  IconButton,
  InputAdornment,
  makeStyles,
  OutlinedInput,
} from "@material-ui/core";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CloseButton from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useDebounceSet } from "hooks/useDebounce";
import { useCallback } from "react";
import type { UseFiltersColumnOptions } from "react-table";
import type { IPatient } from "types/patient.types";

/**
 * Component rendered for columns that can be filtered
 */
export const DefaultFilter: UseFiltersColumnOptions<IPatient>["Filter"] = (
  props
) => {
  const { column } = props;
  const { sortType, setFilter, filterValue } = column;

  const [internalFilterValue, setInternalFilter] = useDebounceSet(
    [filterValue, setFilter],
    300
  );

  const onResetClicked = useCallback(() => {
    setFilter(undefined);
  }, [setFilter]);

  const styles = useStyles();

  const resetButtonEndAdornment = (
    <InputAdornment position="end">
      {!!internalFilterValue && (
        <IconButton
          size="small"
          onClick={onResetClicked}
          style={{
            visibility: filterValue !== undefined ? "visible" : "hidden",
          }}
        >
          <CloseButton fontSize="small" />
        </IconButton>
      )}
      {!internalFilterValue && sortType === "string" && (
        <SearchIcon fontSize="small" />
      )}
    </InputAdornment>
  );

  if (sortType === "string") {
    const headerUnknown = column.render("Header");
    const headerString =
      typeof headerUnknown === "string" ? `Search ${headerUnknown}` : undefined;
    return (
      <OutlinedInput
        placeholder={headerString}
        endAdornment={resetButtonEndAdornment}
        onChange={(event) => setInternalFilter(event.target.value)}
        value={internalFilterValue || ""}
        style={{
          minWidth: "150px",
        }}
        classes={{
          input: styles.input,
        }}
      />
    );
  }

  if (sortType === "date") {
    return (
      <KeyboardDatePicker
        placeholder="Date"
        autoOk
        variant="inline"
        inputVariant="outlined"
        format="MM/dd/yyyy"
        value={
          internalFilterValue
            ? new Date(Number.parseInt(internalFilterValue, 10))
            : null
        }
        InputAdornmentProps={{ position: "start" }}
        keyboardIcon={<CalendarTodayIcon fontSize="small" />}
        KeyboardButtonProps={{
          size: "small",
        }}
        onChange={(date) =>
          setInternalFilter(date?.valueOf().toString() || undefined)
        }
        InputProps={{
          endAdornment: resetButtonEndAdornment,
          classes: {
            input: styles.input,
          },
        }}
      />
    );
  }

  return null;
};

// To make inputs slimmer for table
const useStyles = makeStyles((theme) => ({
  input: {
    padding: theme.spacing(2, 1),
    ...theme.typography.labelMedium,
  },
}));
