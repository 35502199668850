import { alpha, makeStyles, withStyles } from "@material-ui/core";
import classNames from "classnames";
import type { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { Box } from "../Box";
import { BaseActionButtons } from "../dialogs";
import type {
  SemanticMessageButtonProps,
  SemanticMessageProps,
} from "../SemanticMessage";
import { SemanticMessage } from "../SemanticMessage";
import { theme, withEnsureNewTheme } from "../theme";

/**
 * @deprecated Use DrawerFooterWithSemanticMessage instead
 */
export const DrawerFooter: React.FC = ({ children }) => (
  <FooterContainer>{children}</FooterContainer>
);

export const FooterContainer = withStyles(() => ({
  root: {
    borderTop: `1px solid ${theme.palette.memoraGrey[28]}`,
    display: "flex",
    alignItems: "center",
    alignSelf: "flex-end",
    width: "100%",
    padding: 24,
  },
}))(Box);

type DrawerActionButtonProps = SemanticMessageButtonProps & {
  disabled?: boolean;
};

export type DrawerFooterWithSemanticMessageProps = {
  isLoading?: boolean;
  actionButtons: {
    submitButton: DrawerActionButtonProps;
    cancelButton: DrawerActionButtonProps;
  };
  confirmMessage?: {
    severity?: SemanticMessageProps["severity"];
    message?: string;
    actionTitle?: string;
    cancelTitle?: string;
    nonBlockingMessage?: boolean;
    onActionClick?: () => void;
    onCancelClick?: () => void;
  };
};

const DrawerFooterWithSemanticMessageCore = ({
  isLoading,
  actionButtons,
  confirmMessage,
}: DrawerFooterWithSemanticMessageProps) => {
  const { t } = useTranslation("drawerActions");
  const styles = useStyles();
  let semanticMessage: ReactNode;
  const severityMessage = confirmMessage?.severity || "error";
  const showBlockingConfirmMessage =
    confirmMessage && !confirmMessage.nonBlockingMessage;

  if (confirmMessage) {
    const actionText = confirmMessage.actionTitle || t("buttons.discard");
    const cancelText = confirmMessage.cancelTitle || t("buttons.cancel");
    const message = confirmMessage.message || t("textBody");

    const actionButton = confirmMessage.onActionClick
      ? {
          text: actionText,
          onClick: confirmMessage.onActionClick || (() => {}),
        }
      : undefined;

    const cancelButton = confirmMessage.onCancelClick
      ? {
          text: cancelText,
          onClick: confirmMessage.onCancelClick || (() => {}),
        }
      : undefined;

    semanticMessage = (
      <SemanticMessage
        isLoading={isLoading}
        isDialogFooter
        severity={severityMessage}
        actionButton={actionButton}
        cancelButton={cancelButton}
        buttonSize="large"
        fitToContent={!showBlockingConfirmMessage}
      >
        {message}
      </SemanticMessage>
    );
  }

  const actionText = actionButtons.submitButton.text || t("buttons.save");
  const cancelText = actionButtons.cancelButton.text || t("buttons.cancel");

  return (
    <div>
      {!showBlockingConfirmMessage && semanticMessage}
      <div
        className={classNames(styles.drawerActionsContainerWrapper, {
          [styles.drawerActionsContainerWrapperWhenNoMessage]:
            !showBlockingConfirmMessage,
          [styles.blockdDrawerFooterWithSemanticMessageWrapper]:
            showBlockingConfirmMessage,
        })}
      >
        {showBlockingConfirmMessage && <Box className={styles.blockingBox} />}
        {showBlockingConfirmMessage && semanticMessage}
        {!showBlockingConfirmMessage && (
          <div className={classNames(styles.drawerActionsContainer, {})}>
            <BaseActionButtons
              submitDisabled={actionButtons.submitButton.disabled}
              submitTitle={actionText}
              onSubmit={actionButtons.submitButton.onClick}
              cancelTitle={cancelText}
              onCancel={actionButtons.cancelButton.onClick}
              submitButtonProps={{ style: { marginLeft: 16 } }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * Contains the possible actions a drawer can have. Already supports blocking the drawer when needed
 * when smenatic message is shown.
 *
 * Supports the drawer layout / styles and semnantic messages in case of notification / blocking messages
 * displayed within the drawer.
 *
 * We are trying to deprecate the old drawer to enforce consistency and make dev's life easier when implementing
 * a drawer following our guidelines.
 *
 * In order to use this drawer actions please see:
 *
 * 1. BasicDrawerWithSemanticMessage.stories.tsx
 * 2. BasicDrawerWithNotificationSemanticMessage.stories.tsx
 */
export const DrawerFooterWithSemanticMessage = withEnsureNewTheme(
  DrawerFooterWithSemanticMessageCore
);

const useStyles = makeStyles({
  blockdDrawerFooterWithSemanticMessageWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  blockingBox: {
    backgroundColor: alpha(theme.palette.grey[100], 0.5),
    position: "absolute",
    width: "100%",
    height: "calc(100% - 72px)",
    top: 0,
    left: 0,
    zIndex: 1,
  },
  drawerActionsContainerWrapper: {
    minHeight: 72,
  },
  drawerActionsContainerWrapperWhenNoMessage: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  drawerActionsContainer: {
    padding: "0 24px",
  },
});
