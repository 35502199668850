import type { OutlinedInputProps as MuiOutlinedInputProps } from "@material-ui/core";
import {
  IconButton,
  InputAdornment,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import SearchIcon from "@material-ui/icons/Search";
import { forwardRef } from "react";

import { useEnsureNewTheme } from "../theme/providers";
import { OutlinedInputStyled } from "./sharedStyles";

const IconButtonStyled = withStyles((theme) => ({
  root: {
    padding: 0,
    "&$disabled": {
      color: theme.palette.memoraGrey[28],
    },
  },
  disabled: {},
}))(IconButton);

export type TextInputSingleLineProps = Omit<
  MuiOutlinedInputProps,
  "multiline"
> & {
  onClearInput?: React.MouseEventHandler<HTMLButtonElement>;
  showFindIcon?: boolean;
  size?: "normal" | "large";
};

export const TextInputSingleLine = forwardRef(
  (
    {
      onClearInput,
      showFindIcon,
      size = "normal",
      ...passedMuiInputProps
    }: TextInputSingleLineProps,
    ref
  ) => {
    useEnsureNewTheme();

    const classes = useStyles();

    const muiInputProps: MuiOutlinedInputProps = {
      ...passedMuiInputProps,
      classes: {
        root:
          size === "normal" ? classes.muiInputNormal : classes.muiInputLarge,
      },
      inputProps: {
        ...passedMuiInputProps.inputProps,
        className: classes[size],
      },
    };

    if (onClearInput && !passedMuiInputProps.disabled) {
      muiInputProps.endAdornment = (
        <IconButtonStyled
          disabled={!passedMuiInputProps.value}
          onMouseDown={onClearInput}
        >
          <CloseOutlined fontSize={size === "normal" ? "small" : "medium"} />
        </IconButtonStyled>
      );
    }

    if (showFindIcon) {
      muiInputProps.startAdornment = (
        <InputAdornment position="start" classes={{ root: classes.findIcon }}>
          <SearchIcon fontSize={size === "normal" ? "small" : "medium"} />
        </InputAdornment>
      );
    }

    return <OutlinedInputStyled ref={ref} {...muiInputProps} />;
  }
);
TextInputSingleLine.displayName = "TextInputSingleLine";

const useStyles = makeStyles((theme) => ({
  normal: {
    padding: theme.spacing(1.25, 2),
    ...theme.typography.labelMedium,
  },
  large: {
    padding: theme.spacing(2, 2.5),
    ...theme.typography.labelLarge,
  },
  findIcon: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  muiInputNormal: {
    height: 32,
  },
  muiInputLarge: {
    height: 40,
  },
}));
