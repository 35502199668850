import type { BoxProps, MenuProps as MUIMenuProps } from "@material-ui/core";
import { Box, ClickAwayListener } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { bindMenu, bindTrigger } from "material-ui-popup-state";
import { usePopupState } from "material-ui-popup-state/hooks";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import type { ComponentType, EventHandler } from "react";

import { useEnsureNewTheme } from "../theme";
import {
  NestedMenuContextProvider,
  useNestedMenuContext,
} from "./Flyout/context";
import { MenuItem } from "./Flyout/FlyoutMenuItem";
import type { AnyMenuItemProps, MenuSize } from "./Flyout/types";

/**
 * Styling to inject on the MUI Menu
 */
export const CoreMenu = withStyles((theme) => ({
  paper: {
    border: "1px solid",
    borderColor: theme.palette.memoraGrey["18"],
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.12)",
  },
}))(HoverMenu);

export type MenuProps = Omit<MUIMenuProps, "onClose"> & {
  size?: MenuSize;
  maxItemsBeforeScroll?: number;
  onClose?: EventHandler<any>;
};

const SMALL_ITEM_HEIGHT = 40; // magic number based on testing
const LARGE_ITEM_HEIGHT = 42; // magic number based on testing

/**
 * Core Menu with injected styling
 * @param size can be large or small - sets the menu item sizes
 * @param Paperprops custom props for the menu popper
 * @param children can be menuItems or our custom MenuItems that nest into flyouts
 * @param maxItemsBeforeScroll is the maximum number of items to display before scrolling
 * @returns a Styled menu displaying the menu items
 */
export function Menu({
  size: sizeProp,
  PaperProps,
  children,
  maxItemsBeforeScroll = 5,
  ...props
}: MenuProps) {
  useEnsureNewTheme();

  const { size } = useNestedMenuContext(sizeProp);
  const maxHeight =
    size === "large"
      ? LARGE_ITEM_HEIGHT * maxItemsBeforeScroll + 15 // magic number based on testing
      : SMALL_ITEM_HEIGHT * maxItemsBeforeScroll + 10; // magic number based on testing
  return (
    <NestedMenuContextProvider size={size}>
      <CoreMenu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        elevation={0}
        getContentAnchorEl={null}
        PaperProps={{
          ...PaperProps,
          style: {
            maxHeight,
            ...PaperProps?.style,
          },
        }}
        {...props}
      >
        {children}
      </CoreMenu>
    </NestedMenuContextProvider>
  );
}

/**
 * Wrapper around our core menu that takes a list of items and an onItemClicked callback
 */
export type MenuWithItemsProps = Omit<MenuProps, "children"> & {
  items: AnyMenuItemProps[];
  onItemClicked?: React.MouseEventHandler;
};

export function MenuWithItems({
  items,
  onItemClicked,
  ...rest
}: MenuWithItemsProps) {
  useEnsureNewTheme();
  return (
    <Menu {...rest}>
      {items.map((item, idx) => (
        <MenuItem
          // eslint-disable-next-line react/no-array-index-key
          key={idx}
          item={item}
          tabIndex={idx}
          onItemClicked={onItemClicked}
        />
      ))}
    </Menu>
  );
}

export interface MenuWithButtonProps<T>
  extends Omit<MenuWithItemsProps, "onItemClicked" | "open"> {
  ButtonComponent: ComponentType<T>;
  buttonProps?: T;
  buttonContainerProps?: BoxProps;
}

/**
 * @param items list of items to display - can be turned into a flyout
 * @param size size of the menu can be large or small
 * @param ButtonComponent to pass in
 * @param buttonProps to pass to the ButtonComponent
 * @param onClose callback to pass through to all children
 * @returns A Menu that takes a button component and button props to open
 */
export function MenuWithButton<T>({
  items,
  size,
  ButtonComponent,
  buttonProps,
  buttonContainerProps,
  ...props
}: MenuWithButtonProps<T>) {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "menuPopup",
  });
  useEnsureNewTheme();

  return (
    <ClickAwayListener onClickAway={popupState.close}>
      <Box width="fit-content" {...buttonContainerProps}>
        <ButtonComponent
          {...buttonProps}
          {...(bindTrigger(popupState) as any)} // hacky typecasting since we are ignore the type here anyways
          className={popupState.popupId}
        />
        <MenuWithItems
          {...props}
          size={size}
          onItemClicked={popupState.close}
          items={items}
          {...bindMenu(popupState)}
        />
      </Box>
    </ClickAwayListener>
  );
}
