import type { TypographyVariant } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";

type LabelSizeType = "small" | "medium" | "large";

interface FormLayoutProps {
  children: React.ReactNode;
  layout?: "horizontal" | "stacked";
  labelWidth?: string;
  labelSize?: LabelSizeType;
  gapSize?: number;
}

const SizeVariant: {
  [key in LabelSizeType]: TypographyVariant;
} = {
  large: "h4",
  medium: "h5",
  small: "h6",
};

const FormLayout = ({
  children,
  layout = "horizontal",
  labelWidth,
  labelSize = "large",
  gapSize = 16,
}: FormLayoutProps) => {
  const size = SizeVariant[labelSize];
  const useStyles = makeStyles((theme) => ({
    layout: {
      display: "flex",
      gap: gapSize,
      flexDirection: "column",
    },
    horizontal: {
      "& .formLayout-item, & .formLayout-row": {
        display: "flex",
        flexDirection: "row",
        gap: gapSize,

        "& .formLayout-label": {
          width: labelWidth,
          flexShrink: 0,

          "& > :first-child": {
            ...theme.typography[size],
          },
        },
      },
      "& .formLayout-item": {
        "& > *": {
          alignSelf: "center",
        },
      },
    },
    stacked: {
      "& .formLayout-item, & .formLayout-row": {
        display: "flex",
        flexDirection: "column",

        "& .formLayout-label": {
          maxWidth: labelWidth,
        },

        "& > :first-child": {
          ...theme.typography[size],
        },
      },
      "& .formLayout-row": {
        gap: gapSize,
      },
      "& .formLayout-item": {
        "& > *": {
          alignSelf: "baseline",
        },
      },
    },
  }));

  const style = useStyles();
  return (
    <div
      className={classNames(
        style.layout,
        layout === "horizontal" ? style.horizontal : style.stacked
      )}
    >
      {children}
    </div>
  );
};

const FormLayoutRow: React.FC = ({ children }) => (
  <div className="formLayout-row">{children}</div>
);

export { FormLayout, FormLayoutRow };
